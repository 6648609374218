import React, {FC} from 'react';
import { Link } from 'react-router-dom';
import userIcon from "../../../assets/dashboard/male-user.svg";
import { BsEnvelope, BsTelephone } from 'react-icons/bs';
import {GrLocation} from "react-icons/gr";
import UserProp from '../../../models/User.model';

 

const CustomerDetails:FC<UserProp> = ({customer, deliveryAddress, orderData}) => {
    const email = customer && customer.email;
    const full_name = customer && customer.full_name;
    const phone = customer && customer.phone;
    const id = customer && customer. _id;
    const note = orderData?.note
   
  return (
    <section className='findchow__overflow-y'>
        <div className='flex items-center justify-center gap-[5px] p-4 leading-normal font-Poppins font-normal sticky top-0 z-10 bg-white'>
            <img src={userIcon} alt='' className='w-[65px] h-[65px] rounded-full'/>
            <div className='flex flex-col gap-2'>
                <h2 className='w-[150px] truncate text-[#282828] text-base leading-[22px] font-medium'>{full_name}</h2>
                <div className='flex items-center gap-[9px]'><span className='max-w-[75px] flex justify-center items-center text-[10px] text-findchow-success-500 bg-[#005B611C] rounded-[300px] p-[3px_9px_3px_10px]'>Consumer</span><Link to={`/consumers/${id}`} className='w-[70px] text-[11px] text-findchow-success-500 underline font-medium whitespace-nowrap'>View Profile</Link></div>
            </div>
        </div>
        <div className='border-t-[1.5px] border-[#F5F5F5] '>
             <div className='px-4 py-3 text-[#282828] flex items-center'>
                <a href={`tel:${phone}`} className='flex items-center gap-3' title={`${phone}`}><BsTelephone size={16}/><span className='text-[11px]'>{phone}</span></a>
             </div>
        </div>
        <div className='border-t-[1.5px] border-[#F5F5F5] '>
             <div className='px-4 py-3 text-[#282828] flex items-center'>
                <a href={`mailto:${email}`} className='flex items-center gap-3' title={email}><BsEnvelope size={16}/><span className='text-[11px] max-w-[170px] truncate'>{email}</span></a>
             </div>
        </div>
        <div className='border-t-[1.5px] border-[#F5F5F5] '>
             <div className='px-4 py-3 text-[#282828] flex items-start gap-3 leading-normal'>
                <GrLocation size={20}/>
                <span className='text-[11px]'>
                    {deliveryAddress}
                </span>
             </div>
        </div>
        <div className='border-t-[1.5px] border-[#F5F5F5] '>
             <div className='px-4 pb-3 pt-4 text-[#282828] flex flex-col gap-3'>
                 <h2 className='text-[11px] leading-[0.25px] font-medium'>Note Order</h2>
                <p className='text-[11px] text-[#575757] leading-normal'>
                    {note || "-"}
                </p>
             </div>
        </div>
    </section>
  )
}

export default CustomerDetails