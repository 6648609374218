
export const getNameFromEmail = (email : string) => {
    let domainArray = email.split('@')
    let domain = domainArray[domainArray.length-1]
    let reverseEmail = email.split( '' ).reverse( ).join( '' );
    let reverseDomain = domain.split( '' ).reverse( ).join( '' );
    let backwardUsername = reverseEmail.replace(reverseDomain+'@','')
    let username = backwardUsername.split( '' ).reverse( ).join( '' );
    return username;
}
