import React, {useRef, useState, useEffect} from 'react'
import RoleHeader from '../../components/Role/RoleHeader'
import RoleNavigationTabs from '../../components/Role/RoleNavigationTabs'
import { Outlet } from 'react-router-dom'
import NewRole from '../../components/Role/NewRole'
import AssignRole from '../../components/Role/AssignRole'

const Roles = () => {
     const [assignRoleModal, setAssignRoleModal] = useState(false);
     const [newRoleModal, setNewRoleModal] = useState(false);
     const assignRoleRef = useRef<HTMLDialogElement | null>(null);
     const newRoleRef = useRef<HTMLDialogElement | null>(null);
     useEffect(() => {
          if(assignRoleModal){
                assignRoleRef?.current?.showModal();
                document.body.style.overflowY = "hidden"
          }else {
               assignRoleRef?.current?.close();
               setAssignRoleModal(false);
               document.body.style.overflowY = "auto"
          }
     }, [assignRoleModal]);
     useEffect(() => {
          if(newRoleModal){
                newRoleRef?.current?.showModal();
                document.body.style.overflowY = "hidden";
                
          }else {
               newRoleRef?.current?.close();
               setNewRoleModal(false);
               document.body.style.overflowY = "auto"
          }
     }, [newRoleModal]);


     useEffect(() => {
          newRoleRef?.current?.removeAttribute('open');
          assignRoleRef?.current?.removeAttribute('open')
        }, [])

        useEffect(() => {
          const close = (e: KeyboardEvent):void => {
            if(e.key === "Escape"){
               newRoleRef?.current?.close();
               setNewRoleModal(false);
               document.body.style.overflowY = "auto"
            }
          }
          window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
      },[]);
      useEffect(() => {
          const close = (e: KeyboardEvent):void => {
            if(e.key === "Escape"){
               assignRoleRef?.current?.close();
               setAssignRoleModal(false);
               document.body.style.overflowY = "auto"
      
            }
          }
          window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
      },[])
  return (
    <div className='w-[100%] max-w-[1142px] mx-auto flex flex-col gap-4
     bg-white font-Poppins rounded-[5px] max-h-[772px] p-4'>
          <RoleHeader />
          <RoleNavigationTabs setNewRoleModal={setNewRoleModal} setAssignRoleModal={setAssignRoleModal}/>
          <div>
               <Outlet/>
          </div>
          <NewRole newRoleRef={newRoleRef} setNewRoleModal={setNewRoleModal}/>
          <AssignRole setAssignRoleModal={setAssignRoleModal} assignRoleRef={assignRoleRef}/>
     </div>
  )
}

export default Roles