import React, {useState} from 'react';
import * as FaIcons from "react-icons/fa";


type Prop = {
     h: string,
     w: string,
     b: string,
     t: string,
     s: string,
     gap: string,
     iconSize:number,
     data: string[],
     sortShared: boolean,
     setSortShared: React.Dispatch<React.SetStateAction<boolean>>,
     selectData: string,
     setSelectData: React.Dispatch<React.SetStateAction<string>>,
     itemIndex: number,
     setItemIndex: React.Dispatch<React.SetStateAction<number>>,
     setOpenDate: React.Dispatch<React.SetStateAction<boolean>>
}

const SharedFilter = ({h,w,b,t,s,  gap, iconSize, data,sortShared,setSortShared,selectData,setSelectData,itemIndex,setItemIndex,setOpenDate} : Prop) => {


    const handleSelected = (data: string, index: number) => {
        setItemIndex(index)
        setSortShared(false);
        if(data === "This month" || data === "Last month"){
            setSelectData(data)
        }else {
           setOpenDate(prev => !prev)
        }
        
    }
    const handleSort = () => {
        setSortShared(prev => !prev)
    }
  return (
    <div className='flex justify-end relative'>
        <div className={`flex items-center justify-center gap-${gap} cursor-pointer w-${w} h-${h} min-w-[70px] border border-${b} rounded-[18px] font-Poppins font-normal leading-normal not-italic text-${s} text-${t} py-1 px-2`} onClick={handleSort}>
            <span>{selectData}</span>{sortShared ? <FaIcons.FaCaretUp size={iconSize}/> : <FaIcons.FaCaretDown size={iconSize}/>}
        </div>
        {
            sortShared && <div className={`fixed top-[150px] min-h-[50px] max-h-[400px] findchow__overflow-y p-2 w-[167px] bg-white shadow-lg rounded-lg flex flex-col gap-1 divide-y divide-solid z-20`}>
            {
                data && data.map((data, index) => (
                    <span className={`cursor-pointer py-1 ${itemIndex === index  ? "text-[#0038FD]" : ""}`} key={index} onClick={() => handleSelected(data, index)}>
                       {data}
                    </span>
                ))
            }
            
           </div>
        }
    
    </div>
  )
}

export default SharedFilter