import React from 'react'
import * as FaIcons from "react-icons/fa"
type Placeholder = {
    placeholder: string
    search?: any
    setSearch?: any
}
const SearchInput = ({placeholder, search, setSearch}: Placeholder) => {
  const search_term = search && search.get("search_term");
  return (
    <form className='relative flex items-center font-Poppins font-normal leading-normal text-[11px]'>
        <input type='text' value={search_term !== null ? search_term : ""}  placeholder={placeholder} className='w-auto xl:w-[260px] placeholder-[#C5C5C5] border-t-0 border-r-0 border-l-0 outline-none py-1  border-b-[#C5C5C5] border-[1.5px] ps-1 pe-7 border-spacing-y-2 bg-transparent' onChange={(e) => setSearch({search_term : e.target.value})}/>
        <FaIcons.FaSearch className='absolute right-[1px]' color='#C5C5C5' size={16}/>
    </form>
  )
}

export default SearchInput