import React from 'react';
import DashboardPendingOrder from './DashboardPendingOrder';
import DashboardRecentOrder from './DashboardRecentOrder';
const OrderOverview = () => {
  return (
    <div className='grid grid-cols-2 gap-4 font-Poppins not-italic leading-normal font-medium'>
          <DashboardPendingOrder />
          <DashboardRecentOrder />
    </div>
  )
}

export default OrderOverview