import {useState} from 'react';
import { Outlet, useParams } from 'react-router-dom';
import MerchantInfo from '../../components/Merchant/SingleMerchant/MerchantInfo'
import MerchantTransactionsDetails from '../../components/Merchant/SingleMerchant/MerchantTransactionsDetails'
import { useMerchantDetailsQuery } from '../../services/MerchanTypeApi'
import { Hourglass } from 'react-loader-spinner';
import { handlNewError } from '../../utils/handleNewError';

const MerchantDetails = () => {
  const {id} = useParams();
  const {isLoading, data, isError, error} = useMerchantDetailsQuery(id || "");
  const isErr = handlNewError(isError, error, "data");

  return (
    <div className='w-[100%] max-w-[1142px] mx-auto flex flex-col gap-4'>
      {
            isLoading ?  <div className="hour__glass-wrapper"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#FFFFFF', '#000000']}/></div> : isError ? <p className="text-[#D90000] py-4 text-center text-base">{error?.hasOwnProperty("data") ? JSON.parse(isErr)["message"] : JSON.parse(isErr)}</p> : <>
                 <MerchantInfo data={data}/>
                 {/* <MerchantTransactionsDetails data={data}/> */}

                 <Outlet context={{data}}/>
                 
            </>
      }
        
    </div>
  )
}

export default MerchantDetails