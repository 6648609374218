import React, {FC, useState} from 'react'
import SharedFilter from '../../Shared/SharedFilter';
import moment from 'moment';
interface Prop {
  totalTransactions: number
}

const TotalEarning:FC<Prop> = ({totalTransactions}) => {
  const [sortShared, setSortShared] = useState(false);
  const [selectData,setSelectData] = useState("This Month");
  const [itemIndex, setItemIndex] = useState(0);
  const [openDate, setOpenDate] = useState(false)
  const date = moment().format("YYYY/MM/DD");
  const [newDate, setNewDate] = useState(date)
  const data = ["This month", "Last month", newDate];

  return (
    <div className='flex flex-col gap-1 justify-between h-[100%] font-Poppins'>
    <div className='text-[#888888] text-sm font-normal'>Total Earnings</div>
    <div className='text-[#282828] text-4xl font-medium'>£{totalTransactions || 0}</div>
    <div className='flex items-center justify-between'>
        <div className='font-normal text-[#888888] text-[11px]'><span className='text-findchow-success-300'>+1.2%</span> from last month</div>
        <div className='relative'>
        <SharedFilter h="[30px]" w="[100%]" b="[#27AE60]"  s="[8px]" t="[1px]" gap="1" iconSize={9} data={data} sortShared={sortShared} setSortShared={setSortShared} selectData={selectData} setSelectData={setSelectData} itemIndex={itemIndex} setItemIndex={setItemIndex} setOpenDate={setOpenDate}/>
        </div>
    </div>
 </div>
  )
}

export default TotalEarning