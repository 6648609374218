import addPhoto from "../assets/dashboard/material-symbols_add-a-photo.svg";
import promoImg from "../assets/dashboard/promo-image.svg";
const appIcons = {
     addPhoto: addPhoto
}


const promoTestImage = [
     {img: promoImg, id: 1},
     {img: promoImg, id: 2},
     {img: promoImg, id: 3},
     {img: promoImg, id: 4},
     {img: promoImg, id: 5},
     {img: promoImg, id: 6},
     {img: promoImg, id: 7},
     {img: promoImg, id: 8},
     {img: promoImg, id: 9},
     {img: promoImg, id: 10},
     {img: promoImg, id: 11},
     {img: promoImg, id: 12},
     {img: promoImg, id: 13},
     {img: promoImg, id: 14},
     {img: promoImg, id: 15},
     {img: promoImg, id: 16},
     {img: promoImg, id: 17},
     {img: promoImg, id: 18},
     {img: promoImg, id: 19},
     {img: promoImg, id: 20},
     {img: promoImg, id: 21},
     {img: promoImg, id: 22},
     {img: promoImg, id: 23},
     {img: promoImg, id: 24}
]

export {appIcons, promoTestImage}