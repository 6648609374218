import React from 'react';
import deliveryIcon from "../../../assets/account/carbon_delivery-parcel.svg";
import statusIcon from "../../../assets/account/status.svg";
import calenderIcon from "../../../assets/account/ion_calendar-outline.svg";
import deliveryPartnerIcon from "../../../assets/account/Stuart-1.svg"
import { Link } from 'react-router-dom';

const DeliveryDetails = () => {
  return (
    <>
          <div className='px-4 text-base text-[#282828] font-Poppins font-medium'>
              Delivery Details
         </div>   
         <div className="flex flex-col gap-2">
            <div className='flex flex-col gap-2 divide-y-[2px] divide-[#F4F4F4]'>
                  <div className='px-4 pt-3 pb-1 flex items-center justify-between'>
                      <div className="flex gap-2 items-center">
                          <img loading="lazy" src={statusIcon} alt="money" width={16} height={16}/>
                          <span className="text-[11px] text-[#282828] font-semibold">Status</span>
                      </div>
                      <div className="text-[11px] text-[#575757] font-normal">
                         Order Received
                      </div>
                  </div>
                  <div className='px-4 pt-3 pb-1 flex items-center justify-between'>
                      <div className="flex gap-2 items-center">
                          <img loading="lazy" src={calenderIcon} alt="calender" width={16} height={16}/>
                          <span className="text-[11px] text-[#282828] font-semibold">ETA</span>
                      </div>
                      <div className="text-[11px] text-[#575757] font-normal">
                          July 24, 2023
                      </div>
                  </div>
                  <div className='px-4 pt-3 pb-1 flex items-center justify-between'>
                      <div className="flex gap-2 items-center">
                          <div className="flex items-center justify-center h-[16px] w-[16px]">
                              <img loading="lazy" src={deliveryIcon} alt="payment" height={16} width={16}/>
                          </div>
                        
                          <span className="text-[11px] text-[#282828] font-semibold">Carrier</span>
                      </div>
                      <Link to="/stuart">
                         <img src={deliveryPartnerIcon} alt="stuart"/>
                      </Link>
                  </div>
            </div>
         </div>
    </>
  )
}

export default DeliveryDetails
