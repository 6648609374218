import deliveryParcelIcon from "../../assets/dashboard/carbon_delivery-parcel.svg"
import deliveryTransactionIcon from "../../assets/dashboard/icon-park-outline_transaction-order.svg"
import moneyIcon from "../../assets/dashboard/ph_money-fill.svg"
import userIcon from "../../assets/dashboard/ant-design_user-outlined.svg"

const DashboardData = () => {
  return (
    <div className='grid grid-cols-4 gap-4'>
    <div className="h-findchow-card-height shadow-findchow-card-shadow py-5 px-2 xl:px-5 bg-findchow-success-500 rounded-[5px] flex gap-2 xl:gap-3 findchow__overflow-y">
        <div><img src={deliveryParcelIcon} alt="/"/></div>
        <div className="flex flex-col gap-0">
            <div className="text-white font-Poppins not-italic font-medium text-sm xl:text-base leading-normal">Orders</div>
            <h2 className="text-white text-left font-Poppins not-italic text-2xl xl:text-3xl font-medium leading-normal">2,089</h2>
            <div className="text-left not-italic font-Poppins font-medium text-[10px] xl:text-[11px] leading-normal"><span className="text-findchow-success-300">+1.2% </span><span className="text-[#F0F0F0]">from yesterday</span></div>
         </div>
     </div>
     <div className="h-findchow-card-height findchow__overflow-y shadow-findchow-card-shadow py-5 px-2 xl:px-5 bg-findchow-success-500 rounded-[5px] flex gap-2 xl:gap-3">
        <div><img src={deliveryTransactionIcon} alt="/"/></div>
        <div className="flex flex-col gap-0">
            <div className="text-white font-Poppins not-italic font-medium text-sm xl:text-base leading-normal">Items Sold</div>
            <h2 className="text-white text-left font-Poppins not-italic text-2xl xl:text-3xl font-medium leading-normal">2,089</h2>
            <div className="text-left not-italic font-Poppins font-medium text-[10px] xl:text-[11px]  leading-normal"><span className="text-findchow-success-300">+1.2% </span><span className="text-[#F0F0F0]">from yesterday</span></div>
         </div>
     </div>
     <div className="findchow__overflow-y h-findchow-card-height shadow-findchow-card-shadow py-5 px-2 xl:px-5 bg-findchow-success-500 rounded-[5px] flex gap-2 xl:gap-3">
        <div><img src={moneyIcon} alt="/"/></div>
        <div className="flex flex-col gap-0">
            <div className="text-white font-Poppins not-italic font-medium text-sm xl:text-base leading-normal">Revenue</div>
            <h2 className="text-white text-left font-Poppins not-italic text-2xl xl:text-3xl font-medium leading-normal">£2,089</h2>
            <div className="text-left not-italic font-Poppins font-medium text-[10px] xl:text-[11px] leading-normal"><span className="text-[#FF5E5E]">-1.2% </span><span className="text-[#F0F0F0]">from yesterday</span></div>
         </div>
     </div>
     <div className="findchow__overflow-y h-findchow-card-height shadow-findchow-card-shadow py-5 px-2 xl:px-5 bg-findchow-success-500 rounded-[5px] flex gap-3">
        <div><img src={userIcon} alt="/"/></div>
        <div className="flex flex-col gap-0">
            <div className="text-white font-Poppins not-italic font-medium text-sm xl:text-base leading-normal">Active Users</div>
            <h2 className="text-white text-left font-Poppins not-italic text-2xl xl:text-3xl font-medium leading-normal">22,089</h2>
            <div className="text-left not-italic font-Poppins font-medium text-[10px] xl:text-[11px] leading-normal"><span className="text-findchow-success-300">+1.2% </span><span className="text-[#F0F0F0]">from yesterday</span></div>
         </div>
     </div>
</div>
  )
}

export default DashboardData