import React from 'react'
import TransactionCards from '../../components/Account/TransactionCards'
import NewTransaction from '../../components/Account/NewTransaction'
import MerchantRemittance from '../../components/Account/MerchantRemittance'

const Account = () => {
  return (
    <div className='w-[100%]  flex flex-col gap-4 max-w-[1142px] mx-auto'>
         <div className='grid grid-cols-8 gap-3'>
             <TransactionCards />
         </div>
         <div className='grid grid-cols-8 gap-3'>
              <NewTransaction />
              <MerchantRemittance />
         </div>
    </div>
  )
}

export default Account