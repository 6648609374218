import React, {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Hourglass } from  'react-loader-spinner'
import * as VscIcons from "react-icons/vsc"
import findchowLogo from "../../../assets/authpage/authpage-logo.svg";
import { useAdminLoginMutation } from '../../../services/AuthService';
import { findchowUser } from '../../../services/authReducer';
import { useAppDispatch} from '../../../hooks';
import { handlNewError } from '../../../utils/handleNewError';
const AdminLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const [viewPassword, setViewPassword] = useState(false);
  const [err, setErr] = useState("");
  const [adminLogin, {isSuccess : isLoginSuccess, isLoading, data: loginData, isError, error }] = useAdminLoginMutation();
  const errorMessage = handlNewError(isError,error, "data");
  const token = loginData && loginData.results && loginData.results.token;
  const full_name = loginData && loginData.results && loginData?.results?.user?.full_name;
  const userEmail = loginData && loginData.results && loginData?.results?.user?.email;
  const userId = loginData && loginData.results  && loginData?.results?.user?._id;
  const role = loginData && loginData?.results && loginData?.results?.user?.role?.name;


 
  
  const [data, setData] = useState({
    email: "",
    password: "",
  });

 const {email, password} = data
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const {value,name} = e.target;
      setData({
        ...data, [name] : value
      });
      setErr("")

  }
  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(email && password){
         await adminLogin({email, password})
    }else {
       setErr("Please Provide all details")
    }
}
useEffect(() => {
   if(isLoginSuccess && token && full_name && userEmail && userId && role){
          dispatch(findchowUser({full_name, email: userEmail, token, _id: userId, role}));
          navigate("/")
          
   }
}, [isLoginSuccess,dispatch,full_name,token,userEmail,userId, role, navigate]);

  return (
    <div className='flex flex-col items-center gap-2 h-[100%] w-[100%] min-w-[405px] max-w-[405px] m-auto min-h-[540px] max-h-[604px] border-[2px] border-solid border-white bg-white bg-opacity-40 rounded-[15px] shadow-auth-shadow p-4 font-Poppins'>
        <div className='w-[136.663px] h-[43px] my-3'><img src={findchowLogo} alt="logo" height={42} width={136.663}/></div>
        <p className='text-center text-lg text-[#FFE55F] my-1'>Order management portal</p>
        <form className='flex flex-col items-center gap-6 mt-8' onSubmit={handleLogin}>
           <div className='font-medium text-white text-2xl'>Sign In</div>
           <div className='flex flex-col gap-2'>
              <input type="email"  placeholder='Email address' className='w-[292px] h-[48px] py-2 px-3 outline-none border-[1px] border-white text-white text-sm font-normal placeholder-white bg-white/[0.44] rounded-[5px]' name="email" value={email} onChange={handleChange}/>
              <div className='relative'>
                <input type={viewPassword ? "text" : "password"}  placeholder='Password' className='w-[292px] h-[48px] py-2 px-3 outline-none border-[1px] border-white text-white text-sm font-normal placeholder-white bg-white/[0.44] rounded-[5px]' name='password' value={password} onChange={handleChange}/>
               
                <div className='absolute right-2 top-[50%] -translate-y-[50%] cursor-pointer text-[#00555A]' onClick={() => setViewPassword(prev => !prev)}>
                  {
                    viewPassword ? (<VscIcons.VscEye />) : (<VscIcons.VscEyeClosed />)
                  }
                </div>
              </div>
              {
                  isError && <p className='text-center text-sm text-red-600'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>
              }
              {err && <p className='text-center text-red-600'>{err}</p>}
              <button type='submit' className='w-[292px] h-[48px] border-[1px] border-[#00555A] bg-[#00555A] rounded-[5px] text-white text-sm font-normal transition ease-in-out delay-150 hover:scale-[1.02] hover:bg-white hover:border-white hover:text-[#00555A] duration-300 ' disabled={isLoading}>
                   {
                       isLoading ? <div className="hour__glass-wrapper"><Hourglass visible={true} height="25" width="25" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#FFFFFF', '#72a1ed']}/></div> : "Sign In"
                   } 
              </button>
           </div>
        </form>
        <Link to="/auth/forgot_password" className='font-normal text-sm text-white my-2 transition ease-in-out delay-150 hover:scale-[1.02] hover:text-[#00555A] duration-300'>Forgot password?</Link>
    </div>
  )
  }
export default AdminLogin