import React from 'react'
import ConsumerInfo from '../../components/Consumer/SingleConsumer.tsx/ConsumerInfo'
import ConsumerTransactionsDetails from '../../components/Consumer/SingleConsumer.tsx/ConsumerTransactionDetail'

const ConsumerDetails = () => {
  return (
    <div className='w-[100%] max-w-[1142px] mx-auto flex flex-col gap-4'>
         <ConsumerInfo />
         <ConsumerTransactionsDetails />
    </div>
  )
}

export default ConsumerDetails