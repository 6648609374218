import React, {useState, FC, useEffect, useCallback} from 'react';
import moment from 'moment';
import * as FaIcons from "react-icons/fa"
import { Line } from 'rc-progress';
import {Outlet, Link, useLocation, useSearchParams} from "react-router-dom"
import CustomSelect from '../../components/CustomSelect';
import SearchInput from '../../components/Shared/SearchInput';
import DatePicker from "react-datepicker";
import { useGetOrdersByStatusQuery, useOrderAnalyticsQuery, useOrderListQuery } from '../../services/OrderService';
import { handlNewError } from '../../utils/handleNewError';
import { Hourglass } from 'react-loader-spinner';

import "react-datepicker/dist/react-datepicker.css";
import "./order.css";

interface Props {
    dateTime?: string[],
    status?: string[]
}

const Orders:FC<Props> = () => {
  const [dateTime] = useState({dateTime: ["Today",  "Last 7d", "Last 30d", "Calendar"]});
  const [selectData,setSelectData] = useState("today");
  const [status, setStatus] = useState({status: ["All","Delivered", "Pending", "Cancelled"]});
  const [openDate, setOpenDate] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const {data: analyticsData, isLoading, isError, error} = useOrderAnalyticsQuery({date_filter: selectData});
  const {data : deliveredData} = useGetOrdersByStatusQuery({page : 1, limit : 10, status: "delivered"});
  const {data : pendingData} = useGetOrdersByStatusQuery({page : 1, limit : 10, status: "pending"});
  const {data : cancelledData} = useGetOrdersByStatusQuery({page : 1, limit : 10, status: "cancelled"});
  const {data} = useOrderListQuery({page : 1, limit : 10});
  const totalDocs = data && data.results && data.results.totalDocs;
  const deliveredCount = deliveredData && deliveredData.results && deliveredData.results.totalDocs;
  const pendingCount = pendingData && pendingData.results && pendingData.results.totalDocs;
  const cancelledCount = cancelledData && cancelledData.results && cancelledData.results.totalDocs;
  const errorMessage = handlNewError(isError,error, "data");
  const analytics = analyticsData && analyticsData.results;
  const allOrders = analytics &&  analytics.all_orders;
  const completedOrders = analytics &&  analytics.completed_orders;
  const pendingOrders = analytics && analytics.pending_orders
  const rejectedOrders = analytics && analytics.rejected_orders;
  const allOrderPercentage = analytics  && analytics.all_orders_percentage;

  const cancelledOrderPercentage = analytics && analytics.cancelled_orders_percentage;
  const pendingOrdersPercentage = analytics && analytics.pending_orders_percentage  
  const completedOrderPercentage = analytics && analytics.completed_orders_percentage;
  const allOrdersFromYesterday = analytics && analytics.all_orders_from_yesterday;
  const completedOrdersFromYesterday = analytics && analytics.completed_orders_from_yesterday;
  const rejectedOrdersFromYesterday = analytics && analytics.rejected_orders_from_yesterday;
  const pendingOrdersFromYesterday = analytics && analytics.pending_orders_from_yesterday

  const removePercent = (str: string | any) => {
    let result = str && str.split("");
    let newResult  = result && result.slice(0,result.length -1).join("");
    const newNumber = Math.ceil(Number(newResult))
    return newNumber
  } 
  const allOrderPercent = allOrderPercentage !== undefined ? removePercent(allOrderPercentage && allOrderPercentage) : 0;
  const completeOrderPercent = removePercent(completedOrderPercentage && completedOrderPercentage);
  const cancelledOrderPercent =  removePercent(cancelledOrderPercentage && cancelledOrderPercentage);
  const pendingOrderPercent =  removePercent(pendingOrdersPercentage && pendingOrdersPercentage);
  function onChange(value: Date){
    setStartDate(value)
  }
const resetValue = () => {
    setOpenDate(false);
    setStartDate(new Date());

}
const applyDate = () => {
  setSelectData(moment(startDate).format("yyyy/MM/DD"))
  setOpenDate(false)
}
  const [search, setSearch] = useSearchParams();
  const [activeDate, setActiveDate] = useState(0);
  const [activeStatus, setActiveStatus] = useState(0);
  const search_term = search && search.get("search_term");

  const location = useLocation();
  const handleActiveDate = (index: number) => {
       setActiveDate(index)
  }
  const handleActiveStatus = (idx: number) => {
       setActiveStatus(idx);
  }
  const selectedCategory = useCallback((data: string) => {
    setSearch(data)
  }, [])
 

useEffect(() => {
    if(location.pathname.includes("delivered")){
        setActiveStatus(1);
    } else if(location.pathname.includes("pending")){
        setActiveStatus(2)
    }else if(location.pathname.includes("cancelled")){
        setActiveStatus(3)
    }else {
        setActiveStatus(0)
    }
}, [location])
useEffect(() => {
    if(activeDate === 0){
        setActiveDate(0)
    }else if(activeDate === 1){
        setActiveDate(1)
    }else if(activeDate === 2){
         setActiveDate(2)
    }else if(activeDate === 3){
         setActiveDate(3)
    }else {
        setActiveDate(0)
    }
}, [activeDate, setActiveDate]);

useEffect(() => {
    if(search_term === null || search_term === ""){
        search.delete("search_term")
        setSearch({})
    }
 }, [search_term, search, setSearch]);

  return (
    <div className='bg-white w-[100%] max-w-[1142px] mx-auto flex flex-col min-h-[650px] shadow-findchow-card-shadow border-[1px] border-[#F5F5F5]'>
        <div className='min-h-[216px] bg-[#005B61] rounded-[5px_5px_0px_0px] py-4 px-3 xl:px-4 flex flex-col font-Poppins leading-normal not-italic gap-6'>
            <div className='grid grid-cols-8'>
                 <h2 className='col-span-3 xl:col-span-4 text-white text-base xl:text-[18px] font-semibold'>Orders Overview</h2>
                 <div className='col-span-5 xl:col-span-4 flex justify-between items-center gap-4'>
                     <div className='flex items-center justify-center gap-1 cursor-pointer border-[1px] rounded-[18px] text-white text-xs xl:text-sm w-[167px] h-[36px]'><span>All Categories</span><span><FaIcons.FaCaretDown /></span></div>
                     <div className='h-[36px] border-[1px] rounded-[18px] text-white text-xs xl:text-sm w-[70%] flex justify-center items-center gap-4 relative'>
                          {
                              dateTime.dateTime.map((dateTime, index) => (
                                  <div key={index}>{dateTime === "Calendar" ? <div className={`flex items-center gap-1 cursor-pointer ${activeDate === index ? "font-semibold text-[#FFE55F] underline" : ""}`} onClick={() => {handleActiveDate(index); setOpenDate(prev => !prev);}}><span>{dateTime}</span><span></span><FaIcons.FaCaretDown /></div> : <div className={`cursor-pointer  ${activeDate === index ? "font-semibold text-[#FFE55F] underline" : ""}`} onClick={() => {handleActiveDate(index); setSelectData(dateTime.toLowerCase())}}>{dateTime}</div>}</div>
                              ))
                          }
                          <>
                            {
                                openDate && <div className='absolute w-[250px] min-h-[00px] shadow-md rounded-lg p-2 right-0 top-[40px] z-50 bg-white date-inp flex flex-col justify-center gap-2  mx-auto'>
                                    <DatePicker selected={startDate} onChange={onChange} dateFormat="yyyy/MM/dd" />
                                    <div className='flex justify-center items-center gap-2 font-Poppins text-sm'>
                                    <div className='h-[30px] w-[70px] bg-findchow-success-500/[30%] text-findchow-success-500 flex items-center justify-center rounded-md cursor-pointer' onClick={applyDate}>Apply</div>
                                    <div className='h-[30px] w-[70px] text-black bg-grey cursor-pointer  flex items-center justify-center rounded-md' onClick={resetValue}>Reset</div>
                                    </div>
                                </div>
                            }
                        </>
                     </div>
                   
                    
                 </div>
            </div>
            {
             isLoading ?  <div className="hour__glass-wrapper"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#FFFFFF', '#000000']}/></div> : isError ? <p className='text-center login__error-msg flex items-center justify-center text-lg font-semibold'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p> :  <div className='grid grid-cols-8 gap-4 xl:gap-8'>
             <div className='col-span-3 grid grid-cols-7  items-center leading-normal font-Inter font-normal'>
                 <>
                     <div className='col-span-2 text-[#FFE55F] text-[10px] xl:text-[11px]'>All Orders</div>
                     <div className='col-span-4 grid grid-cols-10 gap-2 items-center  ms-2 xl:ms-4'>
                         <div className='col-span-9 progress-path'>
                            <Line percent={allOrderPercent} strokeWidth={4} strokeColor="#FFF" />
                          </div>
                          <div className='col-span-1 text-[10px] text-[#FFE55F]'>{allOrderPercentage }</div>
                     </div>
                </>
                <>
                     <div className='col-span-2 text-white text-[10px] xl:text-[11px]'>Completed Orders</div>
                     <div className='col-span-4 grid grid-cols-10 gap-2 items-center ms-2 xl:ms-4'>
                         <div className='col-span-9 progress-path'>
                             <Line percent={completeOrderPercent} strokeWidth={4} strokeColor="#FFF" />
                         </div>
                         <div className='col-span-1 text-[10px] text-white'>{completedOrderPercentage}</div>
                     </div>
                </>
                <>
                     <div className='col-span-2 text-white text-[10px] xl:text-[11px]'>Pending Orders</div>
                     <div className='col-span-4 grid grid-cols-10 gap-2 items-center ms-2  xl:ms-4'>
                         <div className='col-span-9 progress-path'>
                             <Line percent={pendingOrderPercent} strokeWidth={4} strokeColor="#FFF" />
                         </div>
                         <div className='col-span-1 text-[10px] text-white'>{`${pendingOrdersPercentage}%`}</div>
                     </div>
                </>
                <>
                     <div className='col-span-2 text-white text-[10px] xl:text-[11px] '>Cancelled Orders</div>
                     <div className='col-span-4 grid grid-cols-10 gap-2 items-center ms-2 xl:ms-4'>
                         <div className='col-span-9 progress-path'>
                             <Line percent={cancelledOrderPercent} strokeWidth={4} strokeColor="#FFF" />
                         </div>
                         <div className='col-span-1 text-[10px] text-white'>{cancelledOrderPercentage}</div>
                     </div>
                </>
             </div>

             <div className='col-span-5 grid grid-cols-4 gap-6 font-Poppins not-italic text-[#F0F0F0] leading-normal'>

                 <div className='col-span-1'>
                     <div className='flex flex-col gap-1'>
                         <p className='text-xs xl:text-sm '>All Orders</p>
                         <h1 className="text-[36px] xl:text-[45px] font-medium">{allOrders}</h1>
                         <div className='text-[9px] xl:text-[10px] font-medium flex gap-[0.5rem]'><span className={allOrdersFromYesterday && allOrdersFromYesterday.startsWith("-") ? 'text-[#FF5E5E]' : 'text-[#8ED100]'}>{allOrdersFromYesterday && allOrdersFromYesterday.startsWith("-") ? allOrdersFromYesterday : `+${allOrdersFromYesterday}`}</span> <span>from yesterday</span></div>
                     </div>
                 </div>
                 <div className='col-span-1'>
                     <div className='flex flex-col gap-1'>
                         <p className='text-xs xl:text-sm'>Completed Orders</p>
                         <h1 className="text-[36px] xl:text-[45px] font-medium">{completedOrders}</h1>
                         <div className='text-[9px] xl:text-[10px] font-medium flex gap-[0.5rem]'><span className={completedOrdersFromYesterday && completedOrdersFromYesterday.startsWith("-") ? 'text-[#FF5E5E]' : 'text-[#8ED100]'}>{completedOrdersFromYesterday && completedOrdersFromYesterday.startsWith("-") ? completedOrdersFromYesterday : `+${completedOrdersFromYesterday}`}</span> <span>from yesterday</span></div>
                     </div>
                 </div>
                 <div className='col-span-1'>
                     <div className='flex flex-col gap-1'>
                         <p className='text-xs xl:text-sm'>Pending Orders</p>
                         <h1 className="text-[36px] xl:text-[45px] font-medium">{pendingOrders}</h1>
                         <div className='text-[9px] xl:text-[10px] font-medium flex gap-[0.5rem]'><span className={pendingOrdersFromYesterday && pendingOrdersFromYesterday.startsWith("-") ? 'text-[#FF5E5E]' : 'text-[#8ED100]'}>
                         {pendingOrdersFromYesterday && pendingOrdersFromYesterday.startsWith("-") ? pendingOrdersFromYesterday : `+${pendingOrdersFromYesterday}`}</span> <span>from yesterday</span></div>
                         </div>
                 </div>
                 <div className='col-span-1'>
                     <div className='flex flex-col gap-1'>
                         <p className='text-xs xl:text-sm'>Rejected Orders</p>
                         <h1 className="text-[36px] xl:text-[45px] font-medium">{rejectedOrders}</h1>
                         <div className='text-[9px] xl:text-[10px] font-medium flex gap-[0.5rem]'><span className={rejectedOrdersFromYesterday && rejectedOrdersFromYesterday.startsWith("-") ? 'text-[#FF5E5E]' : 'text-[#8ED100]'}>{rejectedOrdersFromYesterday && rejectedOrdersFromYesterday.startsWith("-") ? rejectedOrdersFromYesterday : `+${rejectedOrdersFromYesterday}`}</span> <span>from yesterday</span></div>
                     </div>
                 </div>
             </div>
             
         </div>
        }
           
           
        </div>
        <div className='p-4 flex flex-col gap-4'>
            <div className='flex justify-between items-center'>
                <div className='flex gap-6 items-center'>
                    {
                        status && status.status.map((status,idx) => (
                             <Link to={`${status === "All" ? "" : `${status.toLowerCase()}`}`} key={idx} className={`flex gap-1 items-center text-center cursor-pointer font-Poppins h-[24px] not-italic leading-normal font-normal ${idx === activeStatus ? "bg-[#005B611C]  px-2 rounded-[12px]": ""}`} onClick={() => {handleActiveStatus(idx)}}>
                                 <div className={`text-[12px] ${idx === activeStatus ? "text-[#005B61] font-semibold": "text-[#575757]"}`}>{status}</div><div className={`text-[8px] mt-[2px] ${idx === 0 ? "text-[#005B61]" : idx === 1 ? "text-[#27AE60]" : idx === 2 ? "text-[#C7A700]" : idx === 3 ? "text-[#C70C00]" : ""}`}>{idx === 0 ? totalDocs || 0 : idx === 1 ? deliveredCount || 0 : idx === 2 ? pendingCount || 0 : idx === 3 ? cancelledCount || 0 : null}</div>
                             </Link>
                        ))
                    }
                </div>
                <div className='flex gap-6'>
                    {/* <CustomSelect selectedCategory={selectedCategory}/> */}
                     <SearchInput placeholder="Search orders, city, merchant, etc" search={search} setSearch={setSearch}/>
                </div>
            </div>
            <div>
               <Outlet />
            </div>
        </div>
    </div>
  )
}

export default Orders