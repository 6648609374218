import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { AdminList, AdminLogin, AdminRegister, GetSingleAdmin } from "../models/Admin.model";
import { Page } from "../models/Pages.model";
import { getValue } from "./DecryptData";
import { RootState } from "../store";
import { getUrl } from "../utils/getUrl";
const values =  getValue("findchowApp");

export const authService = createApi({
    reducerPath: "authService",
    baseQuery: fetchBaseQuery({baseUrl: getUrl(process.env.REACT_APP_ENV), prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState;
        const token = state?.auth?.token || values.token
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }
}),
    tagTypes: ["AuthService"],
    endpoints: (builder) => ({
        adminLogin: builder.mutation({
            query: (body : AdminLogin) => ({
                url: "/api/admin/login",
                method: "post",
                body
            }),
            invalidatesTags: ["AuthService"]
        }),
        adminCreate: builder.mutation({
            query: (body: AdminRegister) => ({
                url: "/api/admin/create",
                method: "post",
                body
            }),
            invalidatesTags: ["AuthService"]
        }),
        adminList: builder.query<AdminList, Page>({
             query: ({page, limit}) => ({
                url: `/api/admin/list?page=${page}&limit=${limit}`,
                method: "get",
             }),
             providesTags: ["AuthService"]
        }),
        getAdmin: builder.query<GetSingleAdmin, string>({
              query: (id) => ({
                 url: `/api/admin/details?id=${id}`,
                 method: "get"
              }),
              providesTags: ["AuthService"]
        }),
        updateAdmin: builder.mutation({
             query: (body: AdminRegister) => ({
                 url: "/api/admin/update",
                 method: "put",
                 body
             }),
             invalidatesTags: ["AuthService"]
        }),
        deleteAdmin: builder.mutation({
             query: (body) => ({
                 url: `/api/admin/delete`,
                 method: "delete",
                 body
             }),
             invalidatesTags: ["AuthService"]
        }),
        forgotPassword: builder.mutation({
            query: (body: {user_type: string, email: string}) => ({
                url: "/api/auth/forgot_password",
                method: "post",
                body
            }),
            invalidatesTags: ["AuthService"]
       }),
       resetPassword: builder.mutation({
        query: (body: {user_type: string, password: string, otp: string}) => ({
            url: "/api/auth/reset_password",
            method: "post",
            body
        }),
        invalidatesTags: ["AuthService"]
      }),
      resendCode: builder.mutation({
        query: (body: {user_type: string, email: string}) => ({
            url: "/api/auth/resend_code",
            method: "post",
            body
        }),
        invalidatesTags: ["AuthService"]
      })

    })
});

export const {useAdminLoginMutation, useAdminCreateMutation, useAdminListQuery, useDeleteAdminMutation, useGetAdminQuery, useForgotPasswordMutation,useResendCodeMutation, useResetPasswordMutation, useUpdateAdminMutation} = authService