import {FC, Fragment} from 'react';
import { Prop } from '../../utils/interface';

import { promoTestImage } from '../../utils/Icons';
import deleteIcon from "../../assets/dashboard/delete-icon.svg";

const AppCarousel:FC<Prop> = ({setPromoDelete}) => {
  return (
    <Fragment>  {
      promoTestImage?.map(({img, id}) => (
         <div key={id} className='relative mt-3'>
              <div  className='w-[90px] h-[90px]'>
                 <img src={img} alt="promo-i" className='h-[100%] w-[100%]' />
              </div>
              <div className='w-[28px] h-[28px] absolute -top-3 -right-3 z-50 cursor-pointer' onClick={() => setPromoDelete(true)}>
                <img src={deleteIcon} alt="delete" className='w-[100%] h-[100%]'/>
              </div>
         </div>
      ))
   }</Fragment>
  )
}

export default AppCarousel