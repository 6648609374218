import React from 'react';
import { Link} from 'react-router-dom'

const AllConsumers = () => {
  return (
    <div>  
        <section className="max-w-[95%] mx-auto border-0 font-Poppins leading-normal not-italic">
            <div className="flex flex-col border-0 ">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 border-0">
                    <div className="inline-block min-w-full py-2 align-middle ">
                        <div className="overflow-hidden border-0 ">
                            <table className="min-w-full  divide-y divide-gray-200 ">
                                <thead>
                                    <tr>
                                        <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                        User ID  
                                        </th>
                                        <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                            Customer name
                                        </th>

                                        <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                            Email address
                                        </th>
                                        <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                            City
                                        </th>
                                        <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                            Orders
                                        </th>
                                        <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                            Status
                                        </th>
                                        <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                            Date Joined
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className=" divide-y divide-gray-200 ">
                                    <tr>
                                        <td className="px-4 py-4 whitespace-nowrap">
                                            <Link to={`/consumers/1`} className="underline text-[#0038FD] text-xs font-normal cursor-pointer">
                                                12839283
                                            </Link>
                                        </td>
                                        <td className="px-4 py-4 text-xs text-[#282828]  font-normal truncate min-w-[100px] max-w-[140px]" title='Adeagbo Titilayo Smith'><span className="text-[#282828]">Adeagbo Titilayo Smith</span></td>
                                        <td className="px-4 py-4 text-xs font-normal text-[#002FD7] whitespace-nowrap truncate min-w-[100px] max-w-[140px] underline" title='adeagbo_ttlove@gmail.com'>
                                            <a href="mailto:adeagbo_ttlove@gmail.com">adeagbo_ttlove@gmail.com</a>
                                        </td>
                            
                                        <td className="px-4 py-4 text-xs font-normal  text-[#282828] whitespace-nowrap">Plymouth</td>
                                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                            110,112
                                        </td>
                                        <td className="uppercase px-4 py-4 text-[10px] whitespace-nowrap font-normal text-findchow-success-200">
                                            Active
                                        </td>
                                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                            03 Mar, 2023
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-4 whitespace-nowrap">
                                        <Link to={`/consumers/1`} className="underline text-[#0038FD] text-xs font-normal cursor-pointer">
                                                12839283
                                            </Link>
                                        </td>
                                        <td className="px-4 py-4 text-xs text-[#282828]  font-normal truncate min-w-[100px] max-w-[140px]" title='Adeagbo Titilayo Smith'><span className="text-[#282828]">Adeagbo Titilayo Smith</span></td>
                                        <td className="px-4 py-4 text-xs font-normal text-[#002FD7] whitespace-nowrap truncate min-w-[100px] max-w-[140px] underline" title='adeagbo_ttlove@gmail.com'>
                                            <a href="mailto:adeagbo_ttlove@gmail.com">adeagbo_ttlove@gmail.com</a>
                                        </td>
                            
                                        <td className="px-4 py-4 text-xs font-normal  text-[#282828] whitespace-nowrap">Plymouth</td>
                                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                            110,112
                                        </td>
                                        <td className="uppercase px-4 py-4 text-[10px] whitespace-nowrap font-normal text-findchow-success-200">
                                            Active
                                        </td>
                                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                            03 Mar, 2023
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-4 whitespace-nowrap">
                                        <Link to={`/consumers/1`} className="underline text-[#0038FD] text-xs font-normal cursor-pointer">
                                                12839283
                                            </Link>
                                        </td>
                                        <td className="px-4 py-4 text-xs text-[#282828]  font-normal truncate min-w-[100px] max-w-[140px]" title='Adeagbo Titilayo Smith'><span className="text-[#282828]">Adeagbo Titilayo Smith</span></td>
                                        <td className="px-4 py-4 text-xs font-normal text-[#002FD7] whitespace-nowrap truncate min-w-[100px] max-w-[140px] underline" title='adeagbo_ttlove@gmail.com'>
                                            <a href="mailto:adeagbo_ttlove@gmail.com">adeagbo_ttlove@gmail.com</a>
                                        </td>
                            
                                        <td className="px-4 py-4 text-xs font-normal  text-[#282828] whitespace-nowrap">Plymouth</td>
                                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                            110,112
                                        </td>
                                        <td className="uppercase px-4 py-4 text-[10px] whitespace-nowrap font-normal text-findchow-success-200">
                                            Active
                                        </td>
                                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                            03 Mar, 2023
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-4 whitespace-nowrap">
                                        <Link to={`/consumers/1`} className="underline text-[#0038FD] text-xs font-normal cursor-pointer">
                                                12839283
                                            </Link>
                                        </td>
                                        <td className="px-4 py-4 text-xs text-[#282828]  font-normal truncate min-w-[100px] max-w-[140px]" title='Adeagbo Titilayo Smith'><span className="text-[#282828]">Adeagbo Titilayo Smith</span></td>
                                        <td className="px-4 py-4 text-xs font-normal text-[#002FD7] whitespace-nowrap truncate min-w-[100px] max-w-[140px] underline" title='adeagbo_ttlove@gmail.com'>
                                            <a href="mailto:adeagbo_ttlove@gmail.com">adeagbo_ttlove@gmail.com</a>
                                        </td>
                            
                                        <td className="px-4 py-4 text-xs font-normal  text-[#282828] whitespace-nowrap">Plymouth</td>
                                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                            110,112
                                        </td>
                                        <td className="uppercase px-4 py-4 text-[10px] whitespace-nowrap font-normal text-findchow-success-200">
                                            Active
                                        </td>
                                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                            03 Mar, 2023
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-4 py-4 whitespace-nowrap">
                                        <Link to={`/consumers/1`} className="underline text-[#0038FD] text-xs font-normal cursor-pointer">
                                                12839283
                                            </Link>
                                        </td>
                                        <td className="px-4 py-4 text-xs text-[#282828]  font-normal truncate min-w-[100px] max-w-[140px]" title='Adeagbo Titilayo Smith'><span className="text-[#282828]">Adeagbo Titilayo Smith</span></td>
                                        <td className="px-4 py-4 text-xs font-normal text-[#002FD7] whitespace-nowrap truncate min-w-[100px] max-w-[140px] underline" title='adeagbo_ttlove@gmail.com'>
                                            <a href="mailto:adeagbo_ttlove@gmail.com">adeagbo_ttlove@gmail.com</a>
                                        </td>
                            
                                        <td className="px-4 py-4 text-xs font-normal  text-[#282828] whitespace-nowrap">Plymouth</td>
                                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                            110,112
                                        </td>
                                        <td className="uppercase px-4 py-4 text-[10px] whitespace-nowrap font-normal text-findchow-success-200">
                                            Active
                                        </td>
                                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                            03 Mar, 2023
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>
  )
}

export default AllConsumers