import React from 'react';
import {Link, useNavigate, useParams} from "react-router-dom"
import { MdArrowBack } from 'react-icons/md'
import Stepper from '../../components/Stepper';
import deliveryBike from "../../assets/dashboard/delivery.svg";
import stuartImg from "../../assets/dashboard/Stuart.svg"
import { BsTelephone } from 'react-icons/bs';
import ItemDetails from '../../components/ManageOrder/OrderDetails/ItemDetails';
import CustomerDetails from '../../components/ManageOrder/OrderDetails/CustomerDetails';
import { useGetOrderQuery } from '../../services/OrderService';
import useTrackOrders from '../../components/useTrackOrders';

const OrderDetails = () => {
  const navigate = useNavigate();
  const {id} = useParams()
  const {isError, error, data, isFetching} = useGetOrderQuery(id || "");

  const message = useTrackOrders();

  console.log("data from", message, data)

  const orderData = data && data.results
  const customer = data && data.results && data.results.userId;
  const addressOne = customer && customer
  const deliveryAddress = data && data.results && data.results.deliveryAddress;

  const refCode = orderData?.reference_code

  
  return (
    <div className='w-[100%] mx-auto flex flex-col min-h-[650px] font-Poppins leading-normal not-italic max-w-[1142px]'>
         <div className='flex items-center gap-1 cursor-pointer text-[#005B61]  text-xs font-medium' onClick={() => navigate(-1)}><MdArrowBack size={18}/> Back</div>
         <div className='mb-2 mt-3 text-lg text-[#005B61] font-semibold'>Order ID #{refCode}</div>
         <div className='text-xs text-[#282828] font-normal'>Orders / <span className='text-[#C0C0C0]'>Order Details</span></div>
         <div className='grid grid-cols-8 gap-4 my-2'>
                <div className='bg-white col-span-6  shadow-findchow-card-shadow h-[154px] border-[1px] border-[#F5F5F5] rounded-[5px] p-4'>
                    <div>
                        <Stepper />
                    </div>
                </div>
                <div className='bg-white col-span-2 shadow-findchow-card-shadow h-[154px] border-[1px] border-[#F5F5F5] rounded-[5px]  p-4 flex gap-2  font-Poppins'>
                      <div className='min-w-[58px] w-[58px] h-[58px] bg-[#74A1AB] rounded-full flex justify-center items-center p-[4px]  text-center'>
                           <img src={deliveryBike} alt='bike' className='w-[100%] h-[100%] ml-1'/>
                      </div>
                      <div className='flex flex-col gap-1'>
             
                          <img src={stuartImg} alt="stuart" className='h-[16px] w-[55px]'/>
                          <div className='flex flex-col not-italic leading-normal'>
                              <span className='text-xs text-[#575757] font-normal'>Delivery Rider</span>
                              <span className='w-[150px] text-sm text-black font-semibold leading-[22px] cursor-pointer truncate ' title='James Aburi accord'>James Aburi accord test</span>
                          </div>
                        
                          <a href='tel:08031154652' className='w-100 text-[#282828] text-[11px] font-normal flex items-center gap-2'><BsTelephone size={11}/>+44 ---</a>
                            <Link to="/" className='text-[11px] text-[#002FD7] underline w-100'>View stuart dashboard</Link>    
                       
                      </div>
                </div>
         </div>
         <div className='grid grid-cols-8 gap-4 my-2'>
                <ItemDetails customer={customer} orderData={orderData}/>
                <div className='bg-white col-span-2 shadow-findchow-card-shadow h-[448px] border-[1px] border-[#F5F5F5] rounded-[5px] flex gap-2  font-Poppins '>
                        <CustomerDetails customer={customer} deliveryAddress={deliveryAddress} orderData={orderData}/>
                </div>
         </div>
    </div>
  )
}

export default OrderDetails