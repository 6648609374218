import React, { FC } from 'react'
import { statusCheck } from '../../../utils/statusCheck'
import moment from "moment";
import { Link } from 'react-router-dom'
import { DOC } from '../../../models/Order.model';
type DOCS = {
    docs: DOC[]
}

const DetailsTable = ({docs} : DOCS) => {
  return (
    <div className="inline-block min-w-full py-2 align-middle ">
       <div className="overflow-hidden border-0 ">
        <table className="min-w-full  divide-y divide-gray-200 ">
            <thead>
                <tr>
                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                        Order #   
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                        Date 
                    </th>

                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                        Status
                    </th>

                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                        Items
                    </th>

                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                        Category
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                            Customers
                    </th>
                    {/* <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                        City
                    </th> */}
                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                        Amt Paid
                    </th>
                </tr>
            </thead>
            <tbody className=" divide-y divide-gray-200">
                {
                    docs && docs?.map(({_id,grand_total,createdAt,merchant_category, status,userId,items}) => (
                        <tr key={_id}>
                        <td className="px-4 py-4 whitespace-nowrap">
                            <Link to={`/manage-orders/${_id}`} className="underline text-[#0038FD] text-xs font-normal cursor-pointer">
                                {_id}
                            </Link>
                        </td>
                        <td className="px-4 py-4 text-xs text-[#C5C5C5] whitespace-nowrap font-normal">{moment(createdAt).format("DD MMM, HH:mm")}</td>
                        <td className="px-4 py-4 text-[10px] font-semibold text-gray-700 whitespace-nowrap">
                            <div className={`text-center rounded-full ${statusCheck(status)} uppercase`}>
                                <h2 className='p-[3px_14px_3px_15px]'>{status}</h2>
                            </div>
                        </td>
                        <>
                        {
                            items.map(({name, quantity,_id
                            }, index: number) => (
                            <td key={_id} className="px-4  py-4 text-xs whitespace-nowrap font-normal flex flex-col gap-0 justify-center">
                                
                                <div className={`${index === 0 ? "mt-1" : "-mt-6"}`}>
                                    <span className='text-[#282828]'>{name}</span><span className='text-[#9C9C9C]'>+{quantity}</span>
                                </div>
                                
                            </td>
                            ))
                        }
                        </>
                    
                        <td className="px-4 py-4 text-xs font-normal  text-[#282828] whitespace-nowrap">{merchant_category}</td>
                        {
                            items.map(({restaurantId, _id}, index: number) => (
                                <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828] flex flex-col gap-0 justify-center" key={_id}>
                                    <div className={`${index === 0 ? "mt-1" : "-mt-6"}`}>
                                    {restaurantId?.restuarant_name}
                                    </div>
                                
                            </td>
                            ))
                        }
                        
                      
                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                            £{grand_total}
                        </td>
                    </tr>
                    ))
                }
            </tbody>
        </table>
    </div>
</div>
  )
}

export default DetailsTable