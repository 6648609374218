import React from 'react';
import { Link } from 'react-router-dom';
import * as BsIcon from "react-icons/bs";
import menuImg from "../assets/mdi_food-turkey.svg";
import casesImg from "../assets/ic_outline-insert-drive-file.svg";
import transactionsImg from "../assets/Group.svg";
import cartImg from "../assets/ph_shopping-cart-simple.svg";
import cancelImg from "../assets/material-symbols_cancel.svg";
const ConsumerInfo = () => {
  return (
    <div className='w-[100%] h-[160px] border border-1 border-solid border-[#F5F5F5] bg-white shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] rounded-[5px_5px_0px_0px] font-Poppins not-italic'>
        <section className='h-[80px] bg-findchow-success-500 rounded-[5px_5px_0px_0px] grid grid-cols-12 divide divide-x-[1px]'>
            <div className='col-span-9 grid py-4 grid-cols-9 divide divide-x-[1px]'>
            <div className='col-span-4 px-4'>
                <div className='h-[100%] w-[100%] flex flex-col justify-center items-center gap-1'>
                     <div className='font-semibold self-start text-white text-[18px] max-w-[100%] truncate' title='Akinkugbe Oluwadamilola'>Okechukwu Julius Ayobami</div>
                     <div className='w-[100%] grid grid-cols-6 items-center self-start gap-4 font-normal text-[11px] text-[#DCDCDC]'><a href="mailto:okechukwuayobamij@gmail.com" title='okechukwuayobamij@gmail.com' className='col-span-4 flex items-center gap-1 text-[#DCDCDC] w-[100%]'><BsIcon.BsEnvelope size={11}/><span className=' truncate'>okechukwuayobamij@gmail.com</span></a><a href="tel:07893272327" title='07893272327' className='col-span-2 w-[100%] flex items-center gap-1 text-[#DCDCDC]'><BsIcon.BsTelephone size={11}/><span className="truncate">07893272327</span></a></div>
                </div>
            </div>
           
            <div className='w-[100%] col-span-5 flex place-items-center px-4'>
                <div className='flex flex-col  gap-2 '>
                <div className='text-sm font-medium text-white'>User ID</div>
                <div title='Food Bowls' className='max-w-[100%] truncate text-[#DCDCDC] text-[11px] font-normal'>0091282732</div>
                </div>
            </div>
            </div>
            <div className='col-span-3 flex h-[100%] divide-x-[1px] font-normal font-Poppins text-white'>
                   <div className='h-[100%] w-[100%] flex flex-col items-center justify-center gap-1'>
                     <div><img src={casesImg} alt="cases"/></div>
                       <div className='text-[8px]'>Cases</div>
                   </div>
                   <div className='h-[100%] w-[100%] flex flex-col items-center justify-center gap-1'>
                        <div><img src={transactionsImg} alt="transactions" /></div>
                       <div className='text-[8px] px-2'>Transactions</div>
                   </div>
                   <div className='h-[100%] w-[100%] flex flex-col items-center justify-center gap-1'>
                       <div><img src={cartImg} alt="cart"/></div>
                       <div className='text-[8px]'>Orders</div>
                   </div>
                   <div className='h-[100%] w-[100%] flex flex-col items-center justify-center gap-1'>
                       <button className='text-[8px]'><div><img src={cancelImg} alt="cancel"/></div></button>
                   </div>
            </div>
        </section>
        <section className='h-[80px] p-4 grid grid-cols-12 font-Poppins'>
             <div className='col-span-4 grid grid-cols-2 gap-2 '>
                 <div className='col-span-1 grid grid-rows-2 gap-2'>
                     <div className='text-sm text-[#282828] font-medium'>Password</div>
                     <Link to="/auth/reset" className='text-[11px] text-[#002FD7] font-normal underline underline-offset-1'>Reset Password</Link>
                 </div>
                 <div className='col-span-1 grid grid-rows-2 gap-2'>
                     <div className='text-sm text-[#282828] font-medium'>User Type</div>
                     <div className='text-[11px] text-[#575757] font-normal'>Customer</div>
                 </div>
             </div>
             <div className='col-span-5 grid grid-cols-2 px-4'>
                <div className='col-span-1 grid grid-rows-2 gap-2'>
                    <div className='text-sm text-[#282828] font-medium'>Date Registered</div>
                    <div className='text-[11px] text-[#575757] font-normal'>10/01/2023</div>
                </div>
                <div className='col-span-1 grid grid-rows-2 gap-2 max-w-[100%]'>
                    <div className='text-sm text-[#282828] font-medium'>Address</div>
                    <div className='text-[11px] text-[#575757] font-normal truncate'>141 Carfin Street, Motherwell ML1 4ET </div>
                </div>
             </div>
             <Link to={`/profile`} className='col-span-3 grid items-center place-self-end h-[100%] underline underline-offset-2 text-findchow-success-500 font-semibold'>View Profile</Link>
        </section>
    </div>
  )
}

export default ConsumerInfo;