import React from 'react';
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as Io5Icons from "react-icons/io5"
import { useRoleListQuery } from '../../services/roleService';
import { handlNewError } from '../../utils/handleNewError';
import { Hourglass } from 'react-loader-spinner';

type Props = {
    assignRoleRef:  React.MutableRefObject<HTMLDialogElement | null>;
    setAssignRoleModal: React.Dispatch<React.SetStateAction<boolean>>
}

const AssignRole = ({setAssignRoleModal, assignRoleRef}: Props) => {
   const {isLoading, isError, error,data} = useRoleListQuery({page: 1, limit: 50});
   const errorMessage = handlNewError(isError,error, "data");
  const rolesDoc = data && data?.results?.docs;
  
  return (
    <dialog ref={assignRoleRef}  className="left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[545px] h-[382px] rounded-[5px] bg-white">
          <div className='flex items-center justify-between h-[50px] bg-[#D9D9D9] border-[1px] border-[#D9D9D9] rounded-[5px_5px_0px_0px] p-4 font-medium text-base'>
               <div className='text-[#282828]'>Assign Role</div><div className='cursor-pointer text-[#282828]' onClick={() => {setAssignRoleModal(false); assignRoleRef?.current?.close()}}><MdIcons.MdCancel size={24}/></div>
          </div>
          <div className='h-[300px] p-4 mx-auto flex flex-col justify-evenly items-center gap-2 w-[545px]'>
                  <div className='relative flex items-center font-Poppins font-normal leading-normal text-[11px] px-2'>
                      <input type='text' required  placeholder="Enter full name" className='w-auto xl:w-[316px] placeholder-[#C5C5C5] border-t-0 border-r-0 border-l-0 outline-none py-2  border-b-[#C5C5C5] border-[1.5px] ps-6 pe-4 border-spacing-y-2 bg-transparent'/>
                      <AiIcons.AiOutlineUser  className='absolute left-[7px]' color='#C5C5C5' size={16}/>
                  </div>
                  <div className='relative flex items-center font-Poppins font-normal leading-normal text-[11px] px-2'>
                      <input type='email' required  placeholder="Enter email address" className='w-auto xl:w-[316px] placeholder-[#C5C5C5] border-t-0 border-r-0 border-l-0 outline-none py-2  border-b-[#C5C5C5] border-[1.5px] ps-6 pe-4 border-spacing-y-2 bg-transparent'/>
                      <BsIcons.BsEnvelope  className='absolute left-[7px]' color='#C5C5C5' size={16}/>
                  </div>
                  <div className='relative flex items-center font-Poppins font-normal leading-normal text-[11px] px-2'>
                      <input type='text' required  placeholder="Enter user’s position in the organization" className='w-auto xl:w-[316px] placeholder-[#C5C5C5] border-t-0 border-r-0 border-l-0 outline-none py-2  border-b-[#C5C5C5] border-[1.5px] ps-6 pe-4 border-spacing-y-2 bg-transparent'/>
                      <Io5Icons.IoBriefcaseOutline  className='absolute left-[7px]' color='#C5C5C5' size={16}/>
                  </div>
                  {isError && <p className='text-center text-sm login__error-msg w-[292px]'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>}
                  {
                       isLoading ? <div className="hour__glass-wrapper"><Hourglass visible={true} height="25" width="25" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#FFFFFF', '#72a1ed']}/></div> :
                      <div className='relative flex items-center font-Poppins font-normal leading-normal text-[11px] px-2'>
                
                      <select className='w-auto xl:w-[316px] placeholder-[#C5C5C5] border-t-0 border-r-0 border-l-0 outline-none py-2  border-b-[#C5C5C5] border-[1.5px] ps-6 pe-4 border-spacing-y-2 bg-transparent cursor-pointer'>
                         {
                             rolesDoc && rolesDoc.length > 0 ? 
                             
                             <>
                              <option value="">assign role</option>
                               {
                                   rolesDoc.map((data) => (  
                                    <option key={data._id}>{data.name}</option>
                                    ))
                               }
                              
                             </> : null
                         }
                        
                      </select>
                    
                      <MdIcons.MdMiscellaneousServices  className='absolute left-[7px]' color='#C5C5C5' size={16}/>
                     </div>
                    }
                  <button className='flex w-[152px] h-[40px] justify-center items-center p-[15.5px_36px] rounded-[5px] bg-findchow-success-500 border-none text-white'>Create</button>
          </div>
    </dialog>
  )
}

export default AssignRole