import {createSlice} from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
import { setValue } from "./DecryptData";
interface AuthState {
    full_name: string | null;
    email: string | null;
    token: string | null;
    _id: string | null;
    role: string | null
}
const initialState : AuthState = {
     full_name: "",
     email:  "",
     token:  "",
    _id:  "",
    role: ""
}

export const authReducer = createSlice({
    name: "auth",
    initialState,
    reducers: {
         findchowUser: (state, action) => {
              const {payload} = action
              let data = JSON.stringify({full_name: payload?.full_name, email: payload?.email, token: payload?.token, _id: payload?._id, role: payload?.role});
              state.full_name = payload?.full_name;
              state.email = payload?.email;
              state.token = payload?.token;
              state._id = payload?._id;
              state.role = payload?.role;

               let encryptedData = CryptoJS.AES.encrypt(data, process.env.REACT_APP_SECRETKEY).toString()

                setValue("findchowApp", encryptedData)
             
         },
         logout: (state) => {
             localStorage.removeItem("findchowApp");
             state.full_name = "";
             state.email = "";
             state.token = "";
             state._id = ""; 
             state.role = ""
         }

    }
});

export const {findchowUser, logout} = authReducer.actions;
export default authReducer.reducer