import React, {useState} from 'react'
import Chart from 'react-apexcharts';
const OrderCategoryChart = () => {
    const [options] = useState({
        chart: {
            id: "order-categories-chart"
         },
         maintainAspectRatio: false,
         dataLabels: {
            enabled: false
        },
        colors: ['#A10A00', '#0546C5', "#aAA100", "#5AA100", "#FEAA00"],
        legend: {
            show: true,
            position: 'bottom' as 'bottom',
            horizontalAlign: 'center' as const,
            fontSize: '9px',
            fontFamily: 'Poppins',
            markers: {
                width: 8,
                height: 8,
                radius: 2,
                fillColors: ['#A10A00', '#0546C5', "#aAA100", "#5AA100", "#FEAA00"]
            },
        },
         labels: ["Restaurants", "Grocery", "Cake", "Food Bools", "Finger Foods"],
         title: {
             text: "Category of orders",
             style: {
                color: "#282828",
                fontSize: '14px',
                fontFamily: 'Poppins',
                fontWeight: 500,
           },

         },
         plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                             show: true,
                             showAlways: true,
                             fontSize: "12px",
                             fontFamily: 'Poppins',
                             fontWeight: 600
                        }
                    }
                   
                }
            }
         }
    })
  return (
    <div className='relative h-[100%] w-[100%] order__cat-chart font-Poppins font-medium  '>
        <Chart type='donut' options={options} series={[100, 1000, 200, 300, 150]} height={220} width={220}/>
    </div>
  )
}

export default OrderCategoryChart