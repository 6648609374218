import React from 'react'
import { Outlet } from 'react-router-dom'

const RoleTable = () => {
  return (
    <div className='max-h-[614px] border-[1px] border-[#E2E2E2] rounded-[10px] py-2'>
        <Outlet />
    </div>
  )
}

export default RoleTable