import React from 'react';
import * as MdIcons from "react-icons/md";

type Props = {
    newRoleRef:  React.MutableRefObject<HTMLDialogElement | null>;
    setNewRoleModal: React.Dispatch<React.SetStateAction<boolean>>
}

const NewRole = ({newRoleRef,setNewRoleModal} : Props) => {

  return (
    <dialog ref={newRoleRef} className="left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[545px] h-[366px] rounded-[5px] bg-white">
          <div className='flex items-center justify-between h-[50px] bg-[#D9D9D9] border-[1px] border-[#D9D9D9] rounded-[5px_5px_0px_0px] p-4 font-medium text-base'>
               <div className='text-[#282828]'>New Role</div><div className='cursor-pointer text-[#282828]' onClick={() => {setNewRoleModal(false); newRoleRef?.current?.close()}}><MdIcons.MdCancel size={24}/></div>
          </div>
          <div className='h-[300px] p-4 mx-auto flex flex-col justify-evenly items-center w-[545px]'>
                  <div className='relative flex items-center font-Poppins font-normal leading-normal text-[11px] px-2'>
                      <input type='text'  placeholder="Enter the name of the new role" className='w-auto xl:w-[316px] placeholder-[#C5C5C5] border-t-0 border-r-0 border-l-0 outline-none py-1  border-b-[#C5C5C5] border-[1.5px] ps-6 pe-4 border-spacing-y-2 bg-transparent'/>
                      <MdIcons.MdMiscellaneousServices  className='absolute left-[7px]' color='#C5C5C5' size={16}/>
                  </div>
                  <button className='flex w-[152px] h-[40px] justify-center items-center p-[15.5px_36px] rounded-[5px] bg-findchow-success-500 border-none text-white'>Create</button>
          </div>
    </dialog>
  )
}

export default NewRole