import React, {FC, useState, useEffect} from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import * as BsIcon from "react-icons/bs";
import menuImg from "../assets/mdi_food-turkey.svg";
import menuImgActive from "../../../assets/dashboard/mdi_food-turkey-active.svg";
import profileImgActive from "../../../assets/account/ant-design_user-outlined.svg";
import profileImg from "../../../assets/dashboard/ant-design_user-outlined-white.svg";
import transactionsImg from "../assets/Group.svg";
import transactionsImgActive from "../../../assets/dashboard/icon-park-outline_transaction-order-active.svg";
import cartImg from "../assets/ph_shopping-cart-simple.svg";
import cartImgActive from "../../../assets/dashboard/ph_shopping-cart-simple-active.svg"
import cancelImg from "../assets/material-symbols_cancel.svg";
import { merchantDataInfo } from '../../../models/MerchantType.model';
import moment from 'moment';

const MerchantInfo:FC<merchantDataInfo> = ({data}) => {
    // const {id} = useParams()
    const [merchantIndex, setMerchantIndex] = useState<number>(0);
    const navigate = useNavigate();
    const merchants = data?.results?.merchant;
    const address = merchants?.address;
    const dateRegistered = merchants?.createdAt;
    const merchantName = merchants?.first_name + " " + merchants?.last_name;
    const merchantEmail = merchants?.email;
    const merchantPhoneNumber = merchants?.phone_number;
    const merchantId = merchants?.id;
    const merchantType = merchants?.merchant_type?.name;
    const storeName = merchants?.restuarant_name;   
    const {pathname} = useLocation()
    

    useEffect(() => {
        if(pathname?.includes("menu")){
             setMerchantIndex(1)
        }else if(pathname?.includes("transactions")){
             setMerchantIndex(2)
        }else if(pathname?.includes("orders")){
             setMerchantIndex(3)
        }else {
            setMerchantIndex(0)
        }
    }, [pathname, setMerchantIndex]);
    
    
  return (
    <div className={`w-[100%] ${merchantIndex === 0 ? "h-[160px]" : "h-auto"} border border-1 border-solid border-[#F5F5F5] bg-white shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] rounded-[5px_5px_0px_0px] font-Poppins not-italic`}>
        <section className={`h-[80px] bg-findchow-success-500 ${merchantIndex === 0 ? "rounded-[5px_5px_0px_0px]" : "rounded-[5px_5px_5px_5px]"} grid grid-cols-12 divide divide-x-[1px]`}>
            <div className='col-span-9 grid py-4 grid-cols-9 divide divide-x-[1px]'>
            <div className='col-span-4 px-4'>
                <div className='h-[100%] w-[100%] flex flex-col justify-center items-center gap-1'>
                     <div className='font-semibold self-start text-white text-[18px] max-w-[100%] truncate' title={merchantName}>{merchantName}</div>
                     <div className='w-[100%] grid grid-cols-7 items-center self-start gap-4 font-normal text-[11px] text-[#DCDCDC]'><a href={`mailto:${merchantEmail}`} title={merchantEmail} className='col-span-4 flex items-center gap-1 text-[#DCDCDC] w-[100%]'><BsIcon.BsEnvelope size={11}/><span className=' truncate'>{merchantEmail}</span></a><a href={`tel:${merchantPhoneNumber}`} title={merchantPhoneNumber} className='col-span-3 w-[100%] flex items-center gap-1 text-[#DCDCDC]'><BsIcon.BsTelephone size={11}/><span className="truncate">{merchantPhoneNumber}</span></a></div>
                </div>
            </div>
            <div className='col-span-2 flex items-center justify-center px-4 gap-4'>
                <div className='flex flex-col w-[100%] mx-auto  gap-2'>
                    <div className='text-sm font-medium text-white'>Store Name</div>
                    <div title={storeName} className='max-w-[100%] truncate text-[#DCDCDC] text-[11px] font-normal'>{storeName}</div>
                </div>
                
                {/* <div className='flex flex-col col-span-1 gap-2 w-[40%]'>
                    <div className='text-sm font-medium text-white'>Category</div>
                    <div title='Food Bowls' className='max-w-[100%] truncate text-[#DCDCDC] text-[11px] font-normal'>Food Bowls</div>
                </div> */}
            </div>
            <div className='w-[100%] col-span-3 flex place-items-center justify-center px-4'>
                <div className='flex flex-col  gap-2 '>
                <div className='text-sm font-medium text-white'>Merchant ID</div>
                <div title={merchantId} className='max-w-[100%] truncate text-[#DCDCDC] text-[11px] font-normal'>{merchantId}</div>
                </div>
            </div>
            </div>
            <div className='col-span-3 flex h-[100%] divide-x-[1px] font-normal font-Poppins text-white'>
                   <div className={`h-[100%] w-[100%] flex flex-col items-center justify-center gap-1 ${merchantIndex === 0 ? "text-findchow-success-500 bg-[#ffe55f] border-[#ffe55f]" : ""} cursor-pointer`} onClick={() => {navigate("")}}>
                     <div><img src={merchantIndex === 0 ? profileImgActive : profileImg} alt="profile"/></div>
                       <div className='text-[8px]'>Profile</div>
                   </div>
                   <div className={`h-[100%] w-[100%] flex flex-col items-center justify-center gap-1 cursor-pointer ${merchantIndex === 1 ? "text-findchow-success-500 bg-[#ffe55f] border-[#ffe55f]" : ""}`} onClick={() => {
                        navigate("menu")
                   }}>
                       <div><img src={merchantIndex === 1 ? menuImgActive : menuImg} alt="menu"/></div>
                       <div className='text-[8px]'>Menu</div>
                   </div>
                   <div className={`h-[100%] w-[100%] flex flex-col items-center justify-center gap-1 cursor-pointer ${merchantIndex === 2 ? "text-findchow-success-500 bg-[#ffe55f] border-[#ffe55f]" : ""}`} onClick={() => {
                        navigate("transactions")
                   }}>
                        <div><img src={merchantIndex === 2 ? transactionsImgActive : transactionsImg} alt="transactions" /></div>
                       <div className='text-[8px] px-2'>Transactions</div>
                   </div>
                   <div className={`h-[100%] w-[100%] flex flex-col items-center justify-center gap-1 cursor-pointer ${merchantIndex === 3 ? "text-findchow-success-500 bg-[#ffe55f] border-[#ffe55f]" : ""}`} onClick={() => {
                        navigate("orders")
                   }}>
                       <div><img src={merchantIndex === 3 ? cartImgActive : cartImg} alt="cart"/></div>
                       <div className='text-[8px]'>Orders</div>
                   </div>
                   <div className='h-[100%] w-[100%] flex flex-col items-center justify-center gap-1' onClick={() => {
                        navigate("")
                   }}>
                       <button className='text-[8px]'><div><img src={cancelImg} alt="cancel"/></div></button>
                   </div>
            </div>
        </section>
        {
            merchantIndex === 0 && <section className='h-[80px] p-4 grid grid-cols-12 font-Poppins'>
            <div className='col-span-4 grid grid-cols-2 gap-2 '>
                <div className='col-span-1 grid grid-rows-2 gap-2'>
                    <div className='text-sm text-[#282828] font-medium'>Password</div>
                    <Link to="/auth/new_password" className='text-[11px] text-[#002FD7] font-normal underline underline-offset-1'>Reset Password</Link>
                </div>
                <div className='col-span-1 grid grid-rows-2 gap-2'>
                    <div className='text-sm text-[#282828] font-medium'>Merchant Type</div>
                    <div className='text-[11px] text-[#575757] font-normal'>{merchantType}</div>
                </div>
            </div>
            <div className='col-span-3 grid grid-rows-2 place-content-center gap-2 max-w-[100%]'>
                 <div className='text-sm text-[#282828] font-medium'>Date Registered</div>
                 <div className='text-[11px] text-[#575757] font-normal'>{moment(dateRegistered).format("DD/MM/YYYY")}</div>
            </div>
            <div className='col-span-3 grid grid-rows-2 pl-4 pr-2 gap-2 max-w-[100%]'>
                 <div className='text-sm text-[#282828] font-medium'>Address</div>
                 <div className='text-[11px] text-[#575757] font-normal truncate'>{`${address?.line_1}, ${address?.city} ${address.post_code}`}</div>
            </div>
            <Link to={`/profile`} className='col-span-2 grid items-center place-self-end h-[100%] underline underline-offset-2 text-findchow-success-500 font-semibold'>View Profile</Link>
       </section>
        }
        
    </div>
  )
}

export default MerchantInfo