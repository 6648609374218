import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsCheck, BsThreeDotsVertical } from 'react-icons/bs'

const NewTransaction = () => {
    const navigate = useNavigate()
  return (
    <div className='col-span-4 min-h-[100px] max-h-[532px] rounded-[5px] bg-white findchow__overflow-y font-Poppins'>
          <div className='sticky top-0 bg-white flex justify-between items-center p-4 z-10'>
             <div className='font-Poppins text-[#282828] text-base font-medium'>New Transactions</div>
             <Link to="/" className='text-xs text-[#002FD7] font-normal underline'>View all</Link>
          </div>
        <div className="relative overflow-x-auto max-w-[100%]">
            <table className="w-full text-sm text-left">
                <thead className="text-xs text-[#9A9A9A] font-normal">
                    <tr>
                        <th scope="col" className="px-4 py-3 font-normal ">
                             Order # 
                        </th>
                        <th scope="col" className="px-4 py-3 font-normal">
                             Customer
                        </th>
                        <th scope="col" className="px-4 py-3 font-normal">
                           Total
                        </th>
                        <th scope="col" className="px-4 py-3 font-normal">
                             Paid
                        </th>
                        <th scope="col" className="px-4 py-3 font-normal">
                            Status
                        </th>
                        <th scope="col" className="px-4 py-3 font-normal">
                             
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white border-b border-[1px] border-[#F4F4F4]">
                        <th scope="row" className="p-4 font-normal text-[#002FD7] text-xs cursor-pointer  underline max-w-[100px]" title='12839283' onClick={() => navigate("/account/1")}>
                             12839283
                        </th>
                        <td className="p-4 max-w-[140px] truncate cursor-pointer font-normal text-xs text-[#282828]" title='Olamide Sparrow'>
                             Olamide Sparrow
                        </td>
                        <td className="p-4 underline max-w-[80px] text-[#002FD7] cursor-pointer font-normal text-xs">
                            £21.24
                        </td>
                        <td className="p-4 font-normal text-[#27AE60]">
                            <BsCheck size={16}/>
                        </td>
                        <td className="p-4 font-normal text-xs text-[#282828]">
                           Open
                        </td>
                        <td className="p-4 font-normal text-[#575757] cursor-pointer">
                           <BsThreeDotsVertical size={16}/>
                        </td>
                    </tr>
                    <tr className="bg-white border-b border-[1px] border-[#F4F4F4] ">
                        <th scope="row" className="p-4 font-normal text-[#002FD7] text-xs cursor-pointer underline max-w-[100px]" title='12839283' onClick={() => navigate("/account/1")}>
                             12839283
                        </th>
                        <td className="p-4 max-w-[140px] truncate cursor-pointer font-normal text-xs text-[#282828]" title='Olamide Sparrow'>
                             Olamide Sparrow bryan
                        </td>
                        <td className="p-4 underline max-w-[80px] text-[#002FD7] cursor-pointer font-normal text-xs">
                            £21.24
                        </td>
                        <td className="p-4 font-normal text-[#27AE60]">
                            <BsCheck size={16}/>
                        </td>
                        <td className="p-4 font-normal text-xs text-[#282828]">
                           Open
                        </td>
                        <td className="p-4 font-normal text-[#575757] cursor-pointer">
                           <BsThreeDotsVertical size={16}/>
                        </td>
                    </tr>
                    <tr className="bg-white border-b border-[1px] border-[#F4F4F4]">
                        <th scope="row" className="p-4 font-normal text-[#002FD7] text-xs cursor-pointer underline max-w-[100px]" title='12839283' onClick={() => navigate("/account/1")}>
                             12839283
                        </th>
                        <td className="p-4 max-w-[140px] truncate cursor-pointer font-normal text-xs text-[#282828]" title='Olamide Sparrow'>
                             Olamide Philemon
                        </td>
                        <td className="p-4 underline max-w-[80px] text-[#002FD7] cursor-pointer font-normal text-xs">
                            £21.24
                        </td>
                        <td className="p-4 font-normal text-[#27AE60]">
                            <BsCheck size={16}/>
                        </td>
                        <td className="p-4 font-normal text-xs text-[#282828]">
                           Closed
                        </td>
                        <td className="p-4 font-normal text-[#575757] cursor-pointer">
                           <BsThreeDotsVertical size={16}/>
                        </td>
                    </tr>
                    <tr className="bg-white border-b border-[1px] border-[#F4F4F4]">
                        <th scope="row" className="p-4 font-normal text-[#002FD7] text-xs cursor-pointer underline max-w-[100px]" title='12839283' onClick={() => navigate("/account/1")}>
                             12839283
                        </th>
                        <td className="p-4 max-w-[140px] truncate cursor-pointer font-normal text-xs text-[#282828]" title='Olamide Sparrow'>
                             Collins amaechi zik
                        </td>
                        <td className="p-4 underline max-w-[80px] text-[#002FD7] cursor-pointer font-normal text-xs">
                            £21.24
                        </td>
                        <td className="p-4 font-normal text-[#27AE60]">
                            <BsCheck size={16}/>
                        </td>
                        <td className="p-4 font-normal text-xs text-[#282828]">
                           Open
                        </td>
                        <td className="p-4 font-normal text-[#575757] cursor-pointer">
                           <BsThreeDotsVertical size={16}/>
                        </td>
                    </tr>
                    <tr className="bg-white border-b border-[1px] border-[#F4F4F4]">
                        <th scope="row" className="p-4 font-normal text-[#002FD7] text-xs cursor-pointer underline max-w-[100px]" title='12839283' onClick={() => navigate("/account/1")}>
                             12839283
                        </th>
                        <td className="p-4 max-w-[140px] truncate cursor-pointer font-normal text-xs text-[#282828]" title='Olamide Sparrow'>
                            Yusuf yobo
                        </td>
                        <td className="p-4 underline max-w-[80px] text-[#002FD7] cursor-pointer font-normal text-xs">
                            £21.24
                        </td>
                        <td className="p-4 font-normal text-[#27AE60]">
                            <BsCheck size={16}/>
                        </td>
                        <td className="p-4 font-normal text-xs text-[#282828]">
                           Closed
                        </td>
                        <td className="p-4 font-normal text-[#575757] cursor-pointer">
                           <BsThreeDotsVertical size={16}/>
                        </td>
                    </tr>
                    <tr className="bg-white border-b border-[1px] border-[#F4F4F4]">
                        <th scope="row" className="p-4 font-normal text-[#002FD7] text-xs cursor-pointer  underline max-w-[100px]" title='12839283' onClick={() => navigate("/account/1")}>
                             12839283
                        </th>
                        <td className="p-4 max-w-[140px] truncate cursor-pointer font-normal text-xs text-[#282828]" title='Olamide Sparrow'>
                             Olamide Sparrow
                        </td>
                        <td className="p-4 underline max-w-[80px] text-[#002FD7] cursor-pointer font-normal text-xs">
                            £21.24
                        </td>
                        <td className="p-4 font-normal text-[#27AE60]">
                            <BsCheck size={16}/>
                        </td>
                        <td className="p-4 font-normal text-xs text-[#282828]">
                           Closed
                        </td>
                        <td className="p-4 font-normal text-[#575757] cursor-pointer">
                           <BsThreeDotsVertical size={16}/>
                        </td>
                    </tr>
                   
                </tbody>
            </table>
        </div>

    </div>
  )
}

export default NewTransaction