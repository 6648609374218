import React from 'react';
import { Link } from 'react-router-dom';
import * as BsIcons from "react-icons/bs"
import Invoice from '../../components/Account/SingleAccount/Invoice';
import AccountDeliveryDetails from '../../components/Account/SingleAccount/AccountDeliveryDetails';
import RestaurantAccountDetails from '../../components/Account/SingleAccount/RestaurantAccounDetails';

const AccountDetails = () => {
  return (
    <div className='w-[100%] max-w-[1142px] mx-auto flex flex-col gap-3 font-Poppins'>
        <div className='flex flex-col gap-[3px]'>
            <Link to="/account" className='flex items-center gap-2 text-findchow-success-500'><BsIcons.BsArrowLeft width={24} height={24}/> <span className='text-xs font-medium'>Back</span></Link>
             <p className='text-lg text-findchow-success-500 font-semibold'>Order ID #12839283</p>
             <div className='font-normal text-[#282828] text-xs'><span>Transactions /</span><span className='text-[#C0C0C0]'>Transaction Details</span></div>
        </div>
       <div className='grid grid-cols-12 gap-2'>
            <Invoice />
            <RestaurantAccountDetails />
            <AccountDeliveryDetails />
       </div>
    </div>
  )
}

export default AccountDetails