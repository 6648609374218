import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useFindchowContext } from '../../Context/FindchowContext';
import { useGetOrdersByStatusQuery } from '../../services/OrderService';
import { handlNewError } from '../../utils/handleNewError';
import { Hourglass } from 'react-loader-spinner';
import moment from "moment";
import PaginationButton from '../Shared/PaginationButton';
import handlePagination from '../Shared/Pagination';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { FaChevronDown } from 'react-icons/fa';


const DeliveredOrders = () => {
    const {pathname} = useLocation();
    const {handleUrl} = useFindchowContext();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10)
    const path = handleUrl(pathname);
    const {isLoading, isFetching, isError, error, data} = useGetOrdersByStatusQuery({page, limit, status: path})
    const isErr = handlNewError(isError, error, "data");
    const docs  = data?.results?.docs;
    const hasNextPage = data?.results?.hasNextPage;
    const hasPrevPage = data?.results?.hasPrevPage;
    // const totalDocs = data?.results?.totalDocs;
    const totalPages = data?.results?.totalPages
 
  return (
    <div>
         <section className="max-w-[95%] mx-auto border-0 font-Poppins leading-normal not-italic">
    <div className="flex flex-col border-0 ">
        {
             isLoading ?  <div className="hour__glass-wrapper"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isError ? <p className="text-[#D90000] py-4 text-center text-base">{error?.hasOwnProperty("data") ? JSON.parse(isErr)["message"] : JSON.parse(isErr)}</p> : <>
                 {
                    docs && docs.length > 0 ? <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 border-0">
                    <div className="inline-block min-w-full py-2 align-middle ">
                        <div className="overflow-hidden border-0 ">
                            <table className="min-w-full  divide-y divide-gray-200 ">
                                <thead>
                                    <tr>
                                        <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                             Order #   
                                        </th>
                                        <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                             Date 
                                        </th>
        
                                        <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                            Status
                                        </th>
        
                                        <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                            Items
                                        </th>
        
                                        <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                            Category
                                        </th>
                                        <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                              Merchant
                                        </th>
                                        <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                            City
                                        </th>
                                        <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                            Amt Paid
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className=" divide-y divide-gray-200  ">

                                    {
                                         docs && docs.map(({_id,grand_total,createdAt,merchant_category, status,userId,items,reference_code}) => (
                                            <tr key={_id}>
                                            <td className="px-4 py-4 whitespace-nowrap">
                                                <Link to={`/manage-orders/${_id}`} className="underline text-[#0038FD] text-xs font-normal cursor-pointer">
                                                    {reference_code}
                                                </Link>
                                            </td>
                                            <td className="px-4 py-4 text-xs text-[#C5C5C5]  whitespace-nowrap font-normal">{moment(createdAt).format("DD MMM, HH:mm")}</td>
                                            <td className="px-4 py-4 text-[10px] font-semibold text-gray-700 whitespace-nowrap">
                                                <div className="text-center rounded-full text-[#27AE60] bg-[#D4FEE5] uppercase">
                                                    <h2 className='p-[3px_14px_3px_15px]'>{status}</h2>
                                                </div>
                                            </td>
                                            <>
                                                {
                                                    items.length === 1 ? items.map(({name, quantity,_id
                                                    }, index) => (
                                                    <td key={_id} className="px-4  py-4 text-xs  whitespace-nowrap font-normal flex items-center gap-0 ">
                                                        <span className={"mt-auto"}>
                                                            <span className='text-[#282828]'>{name}</span><span className='text-[#9C9C9C]'>+{quantity}</span>
                                                        </span>
                                                    </td>
                                                    )) :

                                                     (
                                                        <td>
                                                            <Accordion>
                                                            <AccordionItem header={
                                                                <td className="px-4  py-2 text-xs  whitespace-nowrap font-normal flex items-center space-x-2">
                                                                    <span className={"mt-auto"}>
                                                                        <span className='text-[#282828]'>{items[0]?.name}</span><span className='text-[#9C9C9C]'>+{items[0]?.quantity}</span>
                                                                    </span>
                                                                    <FaChevronDown />
                                                                  </td>
                                                            }>
                                                                {
                                                                    items.slice(1).map(({name, quantity,_id
                                                                    }, index) => {
                                                                 
                                                                    return (<td key={_id} className="px-4 py-2  text-xs  whitespace-nowrap font-normal flex flex-col gap-0 justify-center">
                                                                            <span className='-mt-2'>
                                                                                <span className='text-[#282828]'>{name}</span><span className='text-[#9C9C9C]'>+{quantity}</span>
                                                                            </span>
                                                                         </td>
                                                                     )
                                                                    })
                                                                }
                                                            </AccordionItem>
                                                            </Accordion>
                                                        </td>
                                                     )

                                                }
                                                
                                            </>
                                            <td className="px-4 text-xs font-normal  text-[#282828] whitespace-nowrap">{merchant_category}</td>
                                            {
                                                 items.length === 1 ? 
                                                (items.map(({restaurantId, _id}, index) => (
                                                    <td className="px-4 text-xs py-4 whitespace-nowrap font-normal text-[#282828] flex items-center gap-0 " key={_id}>
                                                        <span className={"mt-auto"}>
                                                           {restaurantId?.restuarant_name}
                                                        </span>
                                                       
                                                   </td>
                                                )))
                                                : (
                                                    <Accordion>
                                                        <AccordionItem header={
                                                            <td className="px-4  pt-5 text-xs  whitespace-nowrap font-normal flex items-center space-x-2">
                                                                <span className={ "mt-auto"}>
                                                                  {items[0]?.restaurantId?.restuarant_name}
                                                              </span>
                                                                <FaChevronDown/>
                                                            </td>
                                                        }>
                                                            {
                                                                items.slice(1).map(({restaurantId, _id}, index) => (
                                                                    <td className="px-4 py-2 text-xs whitespace-nowrap font-normal text-[#282828] flex flex-col gap-0 justify-center" key={_id}>
                                                                        <span className={"-mt-2"}>
                                                                           {restaurantId?.restuarant_name}
                                                                        </span>
                                                                       
                                                                   </td>
                                                                ))
                                                            }
                                                        </AccordionItem>
                                                    </Accordion>
                                                )
                                            }
                                            <td className="px-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                              {userId?.address?.city}
                                            </td>
                                            <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                               £{grand_total}
                                            </td>
                                        </tr>
                                         ))
                                    }
                             
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        totalPages && totalPages === 1 ? null :  <PaginationButton isFetching={isFetching} hasPrevPage={hasPrevPage} hasNextPage={hasNextPage} handlePrevPagination={() => handlePagination("prevPage",hasNextPage,hasPrevPage,setPage, page)} handleNextPagination={() => handlePagination("nextPage",hasNextPage,hasPrevPage,setPage, page)} page={page} totalPages={totalPages}/>
                   }
                </div> : <p className='text-center text-lg py-4'>No order yet</p>
                 }
             </>
        }
        
    </div>
     </section>
    </div>
  )
}

export default DeliveredOrders