import {FC} from "react";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Merchants } from "../../models/MerchantType.model";
import handlePagination from "../Shared/Pagination";
import PaginationButton from "../Shared/PaginationButton";

type itemsData = {
    id: string;
    email: string;
    restuarant_name: string;
    merchant_type: any;
    address: any;
    status: string
    createdAt: string
}

type Data = {
    setLimit: React.Dispatch<React.SetStateAction<number>>,
    setPage: React.Dispatch<React.SetStateAction<number>>,
    merchantData: any
    data: Merchants | any
    merchantSearchData: any
    search_term: string | any
    isFetching: boolean
}

const AllMerchants:FC<Data> = ({merchantData,setPage, data,merchantSearchData, search_term,isFetching}) => {
    const totalPages = data?.results?.totalPages
   const hasNextPage = data?.results?.hasNextPage
   const hasPrevPage = data?.results?.hasPrevPage
   const page = data?.results?.page;
   const searchedResult =  search_term !== null ? merchantSearchData : merchantData;
 
   
  return (
    <div>
        <section className="max-w-[95%] mx-auto border-0 font-Poppins leading-normal not-italic">
            <div className="flex flex-col border-0 ">
              
               
                <>   
                   {
                        searchedResult && searchedResult.length > 0 ?             
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 border-0 horizontal-container-x">
                            <div className="inline-block min-w-full py-2 align-middle ">
                                <div className="overflow-hidden border-0 ">
                                    <table className="min-w-full  divide-y divide-gray-200 ">
                                        <thead className='sticky top-0 bg-white z-10'>
                                            <tr>
                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                                Merchant ID  
                                                </th>
                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                                    Merchant name
                                                </th>

                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                                    Email address
                                                </th>

                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                                    Category
                                                </th>

                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                                    City
                                                </th>
                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                                    Status
                                                </th>
                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A] whitespace-nowrap">
                                                    Date Joined
                                                </th>
                                            </tr>
                                        </thead>
                                    
                                                <tbody className=" divide-y divide-gray-200">
                                                {
                                                    searchedResult && searchedResult.map(({id,email,restuarant_name, merchant_type,address, status,createdAt} : itemsData) => (
                                                        <tr key={id}>
                                                        <td className="px-4 py-4 whitespace-nowrap">
                                                            <Link to={`/merchants/${id}`} className="underline text-[#0038FD] text-xs font-normal cursor-pointer">
                                                                {id}
                                                            </Link>
                                                        </td>
                                                        <td className="px-4 py-4 text-xs text-[#282828]   font-normal truncate min-w-[100px] max-w-[140px]" title={restuarant_name}><span className="text-[#282828]">{restuarant_name}</span></td>
                                                        <td className="px-4 py-4 text-xs font-normal text-[#002FD7] whitespace-nowrap truncate min-w-[100px] max-w-[140px] underline" title={email}>
                                                            <a href={`mailto:${email}`}>{email}</a>
                                                        </td>
                                                        <td className="px-4 py-4 text-xs  whitespace-nowrap font-normal">
                                                            <span className='text-[#282828]'>{merchant_type?.name}</span>
                                                        </td>
                                                        <td className="px-4 py-4 text-xs font-normal  text-[#282828]  whitespace-nowrap">{address?.city}</td>
                                                       
                                                        <td className={`uppercase px-4 py-4 text-[10px] whitespace-nowrap font-normal ${status === "closed" ? "text-[#E32021]" : "text-findchow-success-200"}`}>
                                                            {status}
                                                        </td>
                                                        <td className="px-4 py-4 text-xs whitespace-nowrap font-normal text-[#282828]">
                                                            {moment(createdAt).format("DD MMM, YYYY")}
                                                        
                                                        </td>
                                                    </tr>
                                                    ))
                                                }
                                                    
                                                </tbody>
                                        
                                    
                                    
                                    </table>
                                </div>
                            </div>
                            {
                                totalPages && totalPages === 1 ? null :  <PaginationButton isFetching={isFetching} hasPrevPage={hasPrevPage} hasNextPage={hasNextPage} handlePrevPagination={() => handlePagination("prevPage",hasNextPage,hasPrevPage,setPage, page)} handleNextPagination={() => handlePagination("nextPage",hasNextPage,hasPrevPage,setPage, page)} page={page} totalPages={totalPages}/>
                        }
                        </div> : <p className='text-center text-lg py-4'>No merchant yet</p>
                   }
                </> 
              
            </div>

        </section>
    </div>
  )
}

export default AllMerchants