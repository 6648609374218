
import { Hourglass } from 'react-loader-spinner';

import {  useOutletContext } from 'react-router-dom';
import { DOC } from '../../../models/Order.model';
import DetailsTable from './DetailsTable';
import handlePagination from '../../Shared/Pagination';
import PaginationButton from '../../Shared/PaginationButton';

type Prop = {
    isLoading: boolean
    isFetching: boolean
    hasNextPage: boolean | any
    hasPrevPage: boolean | any
    docs: DOC[]
    isErr: any
    isError: boolean
    error: any
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>,
    totalPages: number

}
const MerchantOrderAll = () => {
    const {isLoading, hasNextPage, hasPrevPage, docs, isErr, setPage, isFetching,totalPages, isError, error, page} = useOutletContext<Prop>();
    // console.log(docs)
  return (
    <div className="flex flex-col border-0 ">
    {
        isLoading ?  <div className="hour__glass-wrapper"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isError ? <p className="text-[#D90000] py-4 text-center text-base">{error?.hasOwnProperty("data") ? JSON.parse(isErr)["message"] : JSON.parse(isErr)}</p> :
        <>
        {
            docs && docs?.length > 0 ? <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 border-0 horizontal-container">
               <DetailsTable docs={docs}/> 
            {
                totalPages && totalPages === 1 ? null : <PaginationButton isFetching={isFetching} hasPrevPage={hasPrevPage} hasNextPage={hasNextPage} handlePrevPagination={() => handlePagination("prevPage",hasNextPage,hasPrevPage,setPage, page)} handleNextPagination={() => handlePagination("nextPage",hasNextPage,hasPrevPage,setPage, page)} page={page} totalPages={totalPages}/>
        }
        </div> : <p className='text-center text-lg py-4'>No order yet</p>
        }
        </>


}
    </div>
  )
}

export default MerchantOrderAll