import NewPasswordSuccess from "../../components/Auth/Login/NewPasswordSuccess"

const NewPasswordSuccessPage = () => {
    return (
      <div className="w-screen h-screen overflow-hidden grid place-content-center auth-bg">
           <NewPasswordSuccess />
      </div>
    )
  }
  
  export default NewPasswordSuccessPage