import moneyFillIcons from "../../../assets/dashboard/money.svg";
import calenderIcon from "../../../assets/dashboard/ion_calendar-outline.svg";
import paymentIcon from "../../../assets/dashboard/payment-icon.svg";
import invoiceIcon from "../../../assets/dashboard/la_file-invoice-dollar.svg";
import downloadIcon from "../../../assets/account/material-symbols_download.svg";
import refundMoneyIcon from "../../../assets/account/ph_money-fill-green.svg";
import {GrLocation} from "react-icons/gr";
const Invoice = () => {
  return (
    <div className='col-span-3 h-auto max-h-[510px] bg-white rounded-[5px] findchow__overflow-y py-4 font-Poppins'>
         <div className='px-4 text-base text-[#282828] font-Poppins font-medium'>
             Invoice
         </div>   
         <div className="flex flex-col gap-2">
            <div className='flex flex-col gap-2 divide-y-[2px] divide-[#F4F4F4]'>
                  <div className='px-4 pt-3 pb-1 flex items-center justify-between'>
                      <div className="flex gap-2 items-center">
                          <img loading="lazy" src={moneyFillIcons} alt="money" width={16} height={16}/>
                          <span className="text-[11px] text-[#282828] font-semibold">Invoice amount</span>
                      </div>
                      <div className="text-[11px] text-[#575757] font-normal">
                          £21.24
                      </div>
                  </div>
                  <div className='px-4 pt-3 pb-1 flex items-center justify-between'>
                      <div className="flex gap-2 items-center">
                          <img loading="lazy" src={calenderIcon} alt="calender" width={16} height={16}/>
                          <span className="text-[11px] text-[#282828] font-semibold">Invoice date</span>
                      </div>
                      <div className="text-[11px] text-[#575757] font-normal">
                          July 24, 2023
                      </div>
                  </div>
                  <div className='px-4 pt-3 pb-1 flex items-center justify-between'>
                      <div className="flex gap-2 items-center">
                          <div className="flex items-center justify-center h-[16px] w-[16px]">
                              <img loading="lazy" src={paymentIcon} alt="payment" height={16} width={16}/>
                          </div>
                        
                          <span className="text-[11px] text-[#282828] font-semibold">Payment</span>
                      </div>
                      <div className="text-[11px] text-[#575757] font-normal">
                          Credit/Debit Card
                      </div>
                  </div>
                  <div className='px-4 pt-3 pb-1 flex items-center justify-between'>
                      <div className="flex gap-2 items-center">
                          <img loading="lazy" src={invoiceIcon} alt="file" width={16} height={16}/>
                          <span className="text-[11px] text-[#282828] font-semibold">Status</span>
                      </div>
                      <div className="text-[11px] text-[#575757] font-normal">
                          Open
                      </div>
                  </div>
                      <div className="flex items-start gap-2 px-4 pt-3 pb-1">
                          <GrLocation size={16}/>
                          <div className="flex flex-col gap-1">
                                <span className="text-[11px] text-[#282828] font-semibold ">Billing address</span>
                                <p className="font-normal text-[11px] text-[#282828]">
                                  141 Carfin Street, Motherwell, Manchester, United Kingdom. ML1 4ET
                                </p>
                          </div>
                        
                      </div>
            </div>
            <div className="p-4 flex flex-col gap-2">
                <button className="w-[100%] h-[40px] flex items-center justify-center gap-2 border border-[#005B61] text-white text-sm bg-[#005B61] rounded-[5px] p-[15.5px_26px]"><img src={downloadIcon} height={16} width={16} alt=""/><span>Download Invoice</span></button>
                <button className="w-[100%] h-[40px] flex items-center justify-center gap-2 border border-[#005B61] border-opacity-[11%] text-[#005B61] text-sm bg-[#005B61] bg-opacity-[11%] rounded-[5px] p-[15.5px_26px]"><img src={refundMoneyIcon} height={16} width={16} alt=""/><span>Initiate Refund</span></button>
            </div>
         </div>
        <div>
            
        </div>
    </div>
  )
}

export default Invoice