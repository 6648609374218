import {FC} from "react";
import { appIcons } from '../../utils/Icons';
import AppCarousel from './AppCarousel';
import { Prop } from '../../utils/interface';

const AllPromotions:FC<Prop> = ({setPromoDelete}) => {
  return (
    <div className='h-[211px] border-[1px] border-[#E2E2E2] rounded-[10px] p-4'>
        <div className='flex justify-between items-start'>
            <div className='flex flex-col gap-2'>
                <h4 className='text-base text-findchow-black-500 font-Poppins font-semibold leading-6'>App Carousel</h4>
                <p className='text-[#939393] text-xs font-Poppins font-normal leading-[18px]'>Promotional images for the consumer app </p>
            </div>
            <div>
                <input type="file" accept='image/*' id="file" className='hidden'/>
                <label htmlFor='file' className='cursor-pointer flex gap-2 justify-center items-center text-sm font-Poppins font-medium text-findchow-success-500 w-[188px] h-[40px] p-[15.5px_36px_15.5px_36px] bg-findchow-success-500/[11%] rounded-[5px]'>
                     <img src={appIcons.addPhoto} alt="upload" width={16} height={16}/> <span className='leading-[21px]'>Upload New</span>
                </label>
            </div>
          </div>
          <div className='flex gap-8 mt-5 w-[100%] lg:max-w-screen-md md:max-w-2xl overflow-x-auto no-scrollbar relative h-[100%]'>
                <AppCarousel setPromoDelete={setPromoDelete}/>
          </div>
    </div>
  )
}

export default AllPromotions