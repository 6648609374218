import * as IoIcons from "react-icons/io";


const ConsumersAnalytics = () => {
  return (
    <div className='grid grid-cols-12 gap-4'>
        
     <div className="h-[147px] findchow__overflow-y shadow-findchow-card-shadow py-5 px-2 xl:px-5 bg-findchow-success-500 rounded-[5px] flex gap-2 xl:gap-3 col-span-4">
         <div className="w-[24px] h-[24px] text-white"><IoIcons.IoIosPerson size={24}/></div>
        <div className="flex flex-col gap-1">
            <div className="text-white font-Poppins not-italic font-medium text-sm xl:text-base leading-normal">Total Customers</div>
            <h2 className="text-white text-left font-Poppins not-italic text-2xl xl:text-3xl font-medium leading-normal">2,111,029</h2>
            <div className="text-left not-italic font-Poppins font-medium text-[10px] xl:text-[11px]  leading-normal"><span className="text-findchow-success-300">+1.2% </span><span className="text-[#F0F0F0]">from yesterday</span></div>
         </div>
     </div>
     
     <div className="h-[147px] findchow__overflow-y shadow-findchow-card-shadow py-5 px-2 xl:px-5 bg-findchow-success-500 rounded-[5px] flex gap-2 xl:gap-3 col-span-4">
         <div className="w-[24px] h-[24px] text-white"><IoIcons.IoIosPerson size={24}/></div>
        <div className="flex flex-col gap-1">
            <div className="text-white font-Poppins not-italic font-medium text-sm xl:text-base leading-normal">Active Customers</div>
            <h2 className="text-white text-left font-Poppins not-italic text-2xl xl:text-3xl font-medium leading-normal">101,921</h2>
            <div className="text-left not-italic font-Poppins font-medium text-[10px] xl:text-[11px]  leading-normal"><span className="text-findchow-success-300">+1.2% </span><span className="text-[#F0F0F0]">from yesterday</span></div>
         </div>
     </div>
     <div className="findchow__overflow-y h-[147px] shadow-findchow-card-shadow py-5 px-2 xl:px-5 bg-findchow-success-500 rounded-[5px] flex gap-2 xl:gap-3 col-span-4">
         <div className="w-[24px] h-[24px] text-white"><IoIcons.IoIosPerson size={24}/></div>
        <div className="flex flex-col gap-1">
            <div className="text-white font-Poppins not-italic font-medium text-sm xl:text-base leading-normal">Inactive Customers</div>
            <h2 className="text-white text-left font-Poppins not-italic text-2xl xl:text-3xl font-medium leading-normal">102</h2>
            <div className="text-left not-italic font-Poppins font-medium text-[10px] xl:text-[11px] leading-normal"><span className="text-[#FF5E5E]">-1.2% </span><span className="text-[#F0F0F0]">from yesterday</span></div>
         </div>
     </div>
</div>
  )
}

export default ConsumersAnalytics