import React, {FC} from 'react'
import SearchInput from '../Shared/SearchInput'
import AllPromotions from './AllPromotions';
import { Prop } from '../../utils/interface';



const Promotion:FC<Prop> = ({setPromoDelete}) => {
  return (
    <div className='flex flex-col gap-6'>
        <div className='flex justify-between items-center'>
            <h3 className='text-lg text-findchow-black-500 font-Poppins font-semibold leading-[27px]'>Promotions</h3>
            <SearchInput placeholder='Search super admin & admins'/>
        </div>
        <AllPromotions setPromoDelete={setPromoDelete}/>
    </div>
  )
}

export default Promotion