import React from 'react'
import { Link } from 'react-router-dom';
import { MdOutlineStarPurple500 } from "react-icons/md";
import { BsEnvelope, BsTelephone } from 'react-icons/bs';
import {GrLocation} from "react-icons/gr";
import downloadIcon from "../../../assets/account/material-symbols_download.svg";
import refundMoneyIcon from "../../../assets/account/ph_money-fill-green.svg";
import Basket from './Basket';

const RestaurantAccountDetails = () => {
  return (
    <div className='col-span-6 h-auto font-Poppins flex flex-col gap-4'>
         <div className='text-base text-[#282828] max-h-[240px] bg-white rounded-[5px] findchow__overflow-y divide-y-[2px] divide-[#F4F4F4]'>
             <div className='flex flex-col gap-3 p-4'>
                <div className='flex justify-between items-center'>
                    <Link to="/" className='font-semibold text-[10px] tracking-[0.25px] text-findchow-success-500 bg-findchow-success-500 bg-opacity-[11%] flex items-center justify-center p-[3px_8px] rounded-[300px]'>Restaurant</Link>
                    <Link to="/" className='underline underline-offset-1 font-medium text-[11px] text-findchow-success-500'>View store</Link>
                </div>
                <div className='grid grid-cols-12 items-center justify-between'>
                     <div className='col-span-6 text-base text-[#282828] font-medium leading-[22px]'>
                         <h3>London Bukka Kitchen</h3>
                     </div>
                     <div className='col-span-6 flex items-center gap-2'>
                         <MdOutlineStarPurple500 color='#EFBA00' size={16}/><div className='text-[#8E8E8E] font-Circular font-[450] text-xs'>3.5/5.0 Good (101)</div>
                     </div>
                </div>
                <div className='grid grid-cols-12 items-start justify-between'>
                     <div className='col-span-6 text-base text-[#282828] font-medium flex flex-col gap-1 '>
                         <div className='flex items-center gap-4 text-[#282828]'>
                             <BsEnvelope size={16}/><p className='text-[11px] font-normal'>info@londonbukka.co.uk</p>
                         </div>
                         <div className='flex items-center gap-4 text-[#282828]'>
                             <BsTelephone size={16}/><p className='text-[11px] font-normal'>+447893272327</p>
                         </div>
                     </div>
                     <div className='col-span-6 flex gap-2 font-Poppins text-[#282828] text-[11px] leading-normal'>
                         <GrLocation color='#282828' size={16}/><div className=''>141 Carfin Street, Motherwell, Manchester, ML1 4ET United Kingdom</div>
                     </div>
                </div>
             </div>
             <div className='p-4 flex items-center justify-between font-Poppins text-white'>
                <div className='flex gap-2 items-center'>
                     <div className='mr-1'>
                         <img src={refundMoneyIcon} alt="" height={16} width={16}/>
                     </div>
                     <div className='text-[11px] text-findchow-success-500 font-normal leading-normal'>
                        Remittance Status
                     </div>
                     <div className='flex items-center gap-[3px] text-[#D0A200]'>
                         <div className='bg-[#D0A200] h-[4px] w-[4px] rounded-full'></div>
                         <div className='text-[11px] font-normal'>Pending</div>
                     </div>
                     <div className='text-[11px] text-[#282828] font-normal tracking-[0.25px]'>
                        (£30.00)
                     </div>
                </div>
                <button className='w-[173px] h-[40px] flex items-center gap-[10px] p-[16px] rounded-[5px] bg-findchow-success-500 text-sm'><img src={downloadIcon} height={16} width={16} alt=""/><span>Initiate Payment</span></button>
                
             </div>
         </div>   
        <div className='text-base text-[#282828] max-h-[510px] bg-white rounded-[5px] findchow__overflow-y py-4'>
            <Basket />
        </div>
    </div>
  )
}


export default RestaurantAccountDetails;