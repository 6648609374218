import {Suspense, lazy} from "react"
import {
    createBrowserRouter
  } from "react-router-dom";
import Login from "../pages/Auth/Login";
import ErrorPage from "../components/ErrorPage";
import Dashboard from "../pages/Dashboard/Dashboard";
import Orders from "../pages/Order/Orders";
import Merchants from "../pages/Merchant/Merchants";
import Consumers from "../pages/Consumers/Consumers";
import Account from "../pages/Account/Account";
import Loader from "../components/Loader";
import AllOrders from "../components/ManageOrder/AllOrders";
import DeliveredOrders from "../components/ManageOrder/DeliveredOrders";
import PendingOrders from "../components/ManageOrder/PendingOrders";
import CancelledOrders from "../components/ManageOrder/CancelledOrders";
import OrderDetails from "../pages/Order/OrderDetails";
import MerchantDetails from "../pages/Merchant/MerchantDetails";
import ConsumerDetails from "../pages/Consumers/ConsumerDetails";
import ForgotPasswordPage from "../pages/Auth/ForgotPasswordPage";
import NewPasswordPage from "../pages/Auth/NewPasswordPage";
import PasswordeResetSuccess from "../pages/Auth/PasswordeResetSuccess";
import NewPasswordSuccessPage from "../pages/Auth/NewPasswordSuccessPage";
import AccountDetails from "../pages/Account/AccountDetails";
import Roles from "../pages/Roles/Roles";
import RoleTable from "../components/Role/RoleTable";
import AllRole from "../components/Role/AllRole";
import Permission from "../components/Role/Permission";
import Promotions from "../pages/Promotions/Promotions";
import MerchantOrderHistory from "../components/Merchant/SingleMerchant/MerchantOrderHistory";
import MerchantTransactionsDetails from "../components/Merchant/SingleMerchant/MerchantTransactionsDetails";
import MerchantMenu from "../components/Merchant/SingleMerchant/MerchantMenu";
import MerchantTransactions from "../components/Merchant/SingleMerchant/MerchantTransactions";
import MerchantOrders from "../components/Merchant/SingleMerchant/MerchantOrders";
import MerchantOrderAll from "../components/Merchant/SingleMerchant/MerchantOrderAll";
import MerchantOrderPending from "../components/Merchant/SingleMerchant/MerchantOrderPending";
import MerchantOrderCancelled from "../components/Merchant/SingleMerchant/MerchantOrderCancelled";
import MerchantOrderDelivered from "../components/Merchant/SingleMerchant/MerchantOrderDelivered";
import WaitList from "../pages/WaitList/WaitList";


const ProtectedRoute = lazy(() => import('./ProtectedRoute'));
  const router = createBrowserRouter([
      {
        element: <Login/>,
        path: "/login"
      },
      {
         element: <ForgotPasswordPage />,
         path: "/auth/forgot_password"
      },
      {
         element: <NewPasswordPage />,
         path: "/auth/new_password"
      },
      {
         element: <PasswordeResetSuccess />,
         path: "/auth/reset_success"
      },
      {
         element: <NewPasswordSuccessPage />,
         path: "/auth/new_password_success"
      },
      {
        element: <Suspense fallback={<Loader />}><ProtectedRoute/></Suspense>,
        errorElement: <ErrorPage />,
        children: [
             {
                index: true,
                element: <Dashboard />
             },
             {
                path: "manage-orders",
                element: <Orders />,
                children: [
                    {
                        path: "",
                        element: <AllOrders />
                    },
                    {
                        path: "delivered",
                        element: <DeliveredOrders/>
                    },
                    {
                        path: "pending",
                        element: <PendingOrders/>
                    },
                    {
                        path: "cancelled",
                        element: <CancelledOrders/>
                    }
                ]
               
               
             },
           
             {
                path: "manage-orders/:id",
                element: <OrderDetails />
             },

             {
                path: "merchants",
                element: <Merchants />
             },
             {
               path: "merchants/:id",
               element: <MerchantDetails />,
               children: [
                  {
                      path: "",
                      element: <MerchantTransactionsDetails />
                  },
                  {
                     path: "menu",
                     element: <MerchantMenu />
                  },
                  {
                     path: "transactions",
                     element: <MerchantTransactions />
                  },
                  {
                     path: "orders",
                     element: <MerchantOrders />,
                     children: [
                          {
                             path: "",
                             element: <MerchantOrderAll />
                          },
                          {
                           path: "delivered",
                           element: <MerchantOrderDelivered />
                        },
                        {
                           path: "pending",
                           element: <MerchantOrderPending />
                        },
                        {
                           path: "cancelled",
                           element: <MerchantOrderCancelled />
                        }
                     ]
                  },
              ]
            },
             {
                path: "consumers",
                element: <Consumers />
             },
             {
               path: "consumers/:id",
               element: <ConsumerDetails />
            },
             {
                path: "account",
                element: <Account />
             },
             {
                 path: "account/:id",
                 element: <AccountDetails />
             },
             {
                path: "roles",
                element: <Roles />,
                children: [
                  {
                       path: "",
                       element: <RoleTable />,
                       children: [
                           {
                              path: "",
                              element: <AllRole />
                           },
                           {
                              path: 'permissions',
                              element: <Permission />
                           }
                       ]
                  }
                ]
             },
             {
               path: "promotions", 
               element: <Promotions />
             },
             {
               path: "waitlist", 
               element: <WaitList />
             }
        ]
      }
  ]);


  export default router;