// import deliveryParcelIcon from "../../assets/dashboard/carbon_delivery-parcel.svg"
// import deliveryTransactionIcon from "../../assets/dashboard/icon-park-outline_transaction-order.svg"
import * as FaIcons6 from "react-icons/fa6";
import { useMerchantAnalyticsQuery } from "../../services/MerchanTypeApi";
import { Hourglass } from "react-loader-spinner";
import { handlNewError } from "../../utils/handleNewError";
import { Line } from 'rc-progress';

type Prop = {
    date_filter: string
}
const MerchantsAnalytics = ({date_filter} : Prop) => {
    const {data: analyticData, isLoading, isError, error} = useMerchantAnalyticsQuery(date_filter);
    const isErr = handlNewError(isError, error, "data");
    const data = analyticData?.results;
    const totalMerchants = data?.total_merchants;
    const activeMerchant = data?.active_merchants;
    const inactiveMerchants = data?.inactive_merchants;
    const totalRestaurantsMerchants = data?.total_restaurant_merchants;
    const totalGroceriesMerchants = data?.total_groceries_merchants;
    const totalFoodbowlsMerchants = data?.total_foodbowls_merchants;
    const totalFingerfoodMerchants = data?.total_fingerfood_merchants;
    const totalCakesMerchants = data?.total_cakes_merchants
    const restaurantMerchantsPercentage = data?.restaurant_merchants_percentage;
    const groceriesMerchantsPercentage = data?.groceries_merchants_percentage;
    const foodbowlsMerchantsPercentage = data?.foodbowls_merchants_percentage;
    const fingerfoodMerchantsPercentage = data?.fingerfood_merchants_percentage;
    const cakesMerchantsPercentage = data?.cakes_merchants_percentage;
   
  return (
    <div className='grid grid-cols-8 gap-4'>
        {
            isLoading ?  <div className="hour__glass-wrapper w-[100%] mx-auto col-span-8"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isError ? <p className="text-[#D90000] py-4 text-center text-base col-span-8">{error?.hasOwnProperty("data") ? JSON.parse(isErr)["message"] : JSON.parse(isErr)}</p> : <>
                  <div className="h-[147px] shadow-findchow-card-shadow py-5 px-2 xl:px-5 bg-findchow-success-500 rounded-[5px] flex justify-between items-start gap-2 xl:gap-3 findchow__overflow-y col-span-4">
            <div className="flex gap-2 w-[40%]">
            <div className="w-[24px] h-[24px] text-white"><FaIcons6.FaShop size={24}/></div>
            <div className="flex flex-col gap-1">
                <div className="text-white font-Poppins not-italic font-medium text-sm xl:text-base leading-normal">Total Merchants</div>
                <h2 className="text-white text-left font-Poppins not-italic text-2xl xl:text-3xl font-medium leading-normal">{totalMerchants}</h2>
                <div className="text-left not-italic font-Poppins font-medium text-[10px] xl:text-[11px] leading-normal"><span className="text-findchow-success-300">+1.2% </span><span className="text-[#F0F0F0]">from yesterday</span></div>
            </div>
            </div>
            <div className="w-[55%] flex items-center  flex-col gap-1">
                <div className="grid grid-cols-7 gap-2 w-[100%] items-center">
                    <div className='text-white  text-[10px] xl:text-[11px] col-span-2'>Restaurants</div>
                    <div className='col-span-4 progress-path'>
                       <Line percent={totalRestaurantsMerchants} strokeWidth={4} strokeColor="#FFF" />
                    </div>
                    <div className='text-[10px] text-white col-span-1'>{restaurantMerchantsPercentage}</div>
                </div>
                <div className="grid grid-cols-7 gap-2 w-[100%] items-center">
                    <div className='text-white  text-[10px] xl:text-[11px] col-span-2'>Groceries</div>
                    <div className='col-span-4 progress-path'>
                       <Line percent={totalGroceriesMerchants} strokeWidth={4} strokeColor="#FFF" />
                    </div>
                    <div className='text-[10px] text-white col-span-1'>{groceriesMerchantsPercentage}</div>
                </div>
                <div className="grid grid-cols-7 gap-2 w-[100%] items-center">
                    <div className='text-white  text-[10px] xl:text-[11px] col-span-2'>Food Bowls</div>
                    <div className='col-span-4 progress-path'>
                       <Line percent={totalFoodbowlsMerchants} strokeWidth={4} strokeColor="#FFF" />
                    </div>
                    <div className='text-[10px] text-white col-span-1'>{foodbowlsMerchantsPercentage}</div>
                </div>
                <div className="grid grid-cols-7 gap-2 w-[100%] items-center">
                    <div className='text-white  text-[10px] xl:text-[11px] col-span-2'>Cakes</div>
                    <div className='col-span-4 progress-path'>
                       <Line percent={totalCakesMerchants} strokeWidth={4} strokeColor="#FFF" />
                    </div>
                    <div className='text-[10px] text-white col-span-1'>{cakesMerchantsPercentage}</div>
                </div>
                <div className="grid grid-cols-7 gap-2 w-[100%] items-center">
                    <div className='text-white  text-[10px] xl:text-[11px] col-span-2'>Finger Foods</div>
                    <div className='col-span-4 progress-path'>
                       <Line percent={totalFingerfoodMerchants} strokeWidth={4} strokeColor="#FFF" />
                    </div>
                    <div className='text-[10px] text-white col-span-1'>{fingerfoodMerchantsPercentage}</div>
                </div>
            </div>
        </div>
     <div className="h-[147px] findchow__overflow-y shadow-findchow-card-shadow py-5 px-2 xl:px-5 bg-findchow-success-500 rounded-[5px] flex gap-2 xl:gap-3 col-span-2">
         <div className="w-[24px] h-[24px] text-white"><FaIcons6.FaShop size={24}/></div>
        <div className="flex flex-col gap-1">
            <div className="text-white font-Poppins not-italic font-medium text-sm xl:text-base leading-normal">Active Merchants</div>
            <h2 className="text-white text-left font-Poppins not-italic text-2xl xl:text-3xl font-medium leading-normal">{activeMerchant}</h2>
            <div className="text-left not-italic font-Poppins font-medium text-[10px] xl:text-[11px]  leading-normal"><span className="text-findchow-success-300">+1.2% </span><span className="text-[#F0F0F0]">from yesterday</span></div>
         </div>
     </div>
     <div className="findchow__overflow-y h-[147px] shadow-findchow-card-shadow py-5 px-2 xl:px-5 bg-findchow-success-500 rounded-[5px] flex gap-2 xl:gap-3 col-span-2">
         <div className="w-[24px] h-[24px] text-white"><FaIcons6.FaShop size={24}/></div>
        <div className="flex flex-col gap-1">
            <div className="text-white font-Poppins not-italic font-medium text-sm xl:text-base leading-normal">Inactive Merchants</div>
            <h2 className="text-white text-left font-Poppins not-italic text-2xl xl:text-3xl font-medium leading-normal">{inactiveMerchants}</h2>
            <div className="text-left not-italic font-Poppins font-medium text-[10px] xl:text-[11px] leading-normal"><span className="text-[#FF5E5E]">-1.2% </span><span className="text-[#F0F0F0]">from yesterday</span></div>
         </div>
     </div>
            </>
        }
      
</div>
  )
}

export default MerchantsAnalytics