import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as AiIcons from "react-icons/ai"
type Props = {
    setAssignRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
    setNewRoleModal: React.Dispatch<React.SetStateAction<boolean>>
}
const RoleNavigationTabs = ({setAssignRoleModal, setNewRoleModal} : Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(0);
    const tabs = [{name:"All Roles",id: 1}, {name: "Permissions", id:2}];
    const navigateToPermission = (index : number) => {
         return index === 0 ? navigate("/roles") : navigate("/roles/permissions")
    }
    const {pathname} = location;
    
    useEffect(() => {
      if(pathname?.includes("roles/permissions")){
          setTabIndex(1)
      }else {
          setTabIndex(0)
      }
    }, [pathname])
  return (
    <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-8 pb-2 flex items-center gap-4 border-b-[1px] border-[#E2E2E2]'>
            {
                tabs?.map((tab,index) => (
                    <div className={`w-[70px] text-center cursor-pointer transition-all ease-linear text-xs relative ${tabIndex === index ? "font-medium text-[#005B61] after:h-[4px] after:bg-[#005B61] after:w-[100%] after:absolute after:left-0 after:-bottom-[16px] after:rounded translate-x-0" : "text-[#575757] font-normal relative" }`} key={tab.id} onClick={() => {setTabIndex(index); navigateToPermission(index)}}>{tab.name}</div> 
                ))
            }
           
        </div>
        <div className='col-span-4 flex items-center justify-between'>
             <button className='flex items-center justify-center gap-1 w-[147px] p-[9px_30px_9px_31px] h-[36px] border-findchow-success-500 border-[1px]  rounded-[5px] text-findchow-success-500  font-Poppins font-semibold text-xs' onClick={() => setAssignRoleModal(true)}><AiIcons.AiOutlinePlus />Assign</button>
             <button className='flex items-center justify-center gap-1 w-[147px] p-[9px_30px_9px_31px] h-[36px] border-findchow-success-500 border-[1px] rounded-[5px] text-white bg-findchow-success-500  font-Poppins font-semibold text-xs' onClick={() => setNewRoleModal(true)}><AiIcons.AiOutlinePlus />New Role</button>
        </div>
    </div>
  )
}

export default RoleNavigationTabs