import React, {useState, FC} from 'react';
import ApexCharts from 'react-apexcharts';

type Prop = {
  salesOverview: string[]
}

const Chart:FC<Prop> = ({salesOverview}) => {
  const newData = salesOverview?.map((num) => {
    return Number(num)
  });
    const [options, setOption] = useState({
        chart: {
           id: "findchow-merchant-chart"
        },
        xaxis: {
            categories: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
            
        },
        yaxis: {
          title: {
            text: 'x10 GBP',
            style: {
                  color: "#C5C5C5",
                  fontSize: '11px',
                  fontFamily: 'Poppins',
                  fontWeight: 500,
             }
        },   
       },
       plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '40%',
          endingShape: 'rounded'
        },
      },
        dataLabels: {
          enabled: false
        },
        fill: {
            colors: ['#005B61'],
        },
                  
        title: {
          text: '',
        }
   })
   const [series, setSeries] = useState([{name: "Sales overview", data: newData}])
  return (
    <ApexCharts options={options} series={series} type='bar' height={200} />
  )
}

export default Chart