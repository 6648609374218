import React, {useEffect, useState} from 'react';
import MerchantsAnalytics from '../../components/Merchant/MerchantsAnalytics';
import SharedFilter from '../../components/Shared/SharedFilter';
import MerchantList from '../../components/Merchant/MerchantList';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./merchant.css";
import { FaCaretDown } from 'react-icons/fa';

const Merchants = () => {

  const [sortShared, setSortShared] = useState(false);
  const [selectData,setSelectData] = useState("This month");
  const [itemIndex, setItemIndex] = useState(0);
  const [openDate, setOpenDate] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const date = moment(startDate).format("YYYY/MM/DD");
  const [newDate, setNewDate] = useState(date);
  const [openApprove, setOpenApprove] = useState(false)
  const data  = ["This month", "Last month", newDate];
  const date_filter = selectData
  function onChange(value: Date){
    setStartDate(value)
  }

  const handleOpenApprove = () => {
       setOpenApprove(prev => !prev)
  }
const resetValue = () => {
    setOpenDate(false);
    setStartDate(new Date());
    setNewDate(moment(startDate).format("yyyy/MM/DD"))

}
const applyDate = () => {
  setNewDate(moment(startDate).format("yyyy/MM/DD"));
  setSelectData(moment(startDate).format("yyyy/MM/DD"))
  setOpenDate(false)
}

  return (

    <div className='w-[100%] max-w-[1142px] mx-auto flex flex-col gap-4 relative'>
         <SharedFilter b="[#575757]" w="[167px]" h="[36px]" t="#575757" s="sm" gap="3" iconSize={16} data={data} sortShared={sortShared} setSortShared={setSortShared} selectData={selectData} setSelectData={setSelectData} itemIndex={itemIndex} setItemIndex={setItemIndex} setOpenDate={setOpenDate}/>
         {
            openDate && <div className='absolute w-[250px] min-h-[00px] shadow-md rounded-lg p-2 right-0 top-[40px] z-50 bg-white date-inp flex flex-col justify-center gap-2  mx-auto'>
                <DatePicker selected={startDate} onChange={onChange} dateFormat="yyyy/MM/dd"/>
                <div className='flex justify-center items-center gap-2 font-Poppins text-sm'>
                   <div className='h-[30px] w-[70px] bg-findchow-success-500/[30%] text-findchow-success-500 flex items-center justify-center rounded-md cursor-pointer' onClick={applyDate}>Apply</div>
                   <div className='h-[30px] w-[70px] bg-grey cursor-pointer  flex items-center justify-center rounded-md' onClick={resetValue}>Reset</div>
                </div>
            </div>
         }
         <MerchantsAnalytics date_filter={date_filter}/>
         <div className='flex flex-col gap-2 p-4 bg-white border-[#F5F5F5] shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] rounded-[5px] font-Poppins'>
             <div className='flex items-center justify-between'>
                <div className='text-[#282828] text-base font-medium flex items-center  gap-2'>
                     <div className="flex items-center gap-2" >
                        Pending Approval <span className='text-[#9A9A9A] text-xs font-normal self-start mt-[1px]'>13</span>
                     </div>
                     
                </div>
                <div className='flex items-center gap-2 text-xs text-findchow-success-500 font-normal cursor-pointer' onClick={handleOpenApprove}>
                   <span className={`transition ease-in duration-300`}>{openApprove  ? "Hide all" : "view all"}</span> <FaCaretDown size={16} className={`transition ease-in duration-300 ${openApprove ?  "rotate-180 transform-gpu" : "rotate-0"}`}/>
                </div>
                </div>
                
                <div className={`transition ease-in-out duration-300 font-Poppins text-xs ${openApprove ? "flex flex-col gap-2" : "hidden"}`}>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-9 grid grid-cols-12'>
                            <div className='col-span-3 text-[#9A9A9A]'>Merchant name</div>
                            <div className='col-span-3 text-[#9A9A9A]'>Email address</div>
                            <div className='col-span-2 text-[#9A9A9A]'>Category</div>
                            <div className='col-span-2 text-[#9A9A9A]'>City</div>
                            <div className='col-span-2 text-[#9A9A9A]'>Phone Number</div>
                        </div>
                        <div className='col-span-3'></div>
                     </div>
                    <div className='flex flex-col gap-3'>
                      <div className='grid grid-cols-12 items-center'>
                            <div className='col-span-9 grid grid-cols-12'>
                                <div className='col-span-3 text-[#282828]'>Plymouth Jollof Kitchen</div>
                                <div className='col-span-3 text-[#002FD7] underline'>
                                    <a href='mailto:plymouthjollof@gmail.com'>plymouthjollof@gmail.com</a>
                                </div>
                                <div className='col-span-2 text-[#282828]'>Restaurants</div>
                                <div className='col-span-2 text-[#282828]'>Plymouth</div>
                                <div className='col-span-2 text-[#282828]'>Phone Number</div>
                            </div>
                            <div className='col-span-3 grid grid-cols-12 font-medium'>
                                 <button className='col-span-6 flex items-center justify-center w-[137px] h-[36px] bg-findchow-success-500 text-white rounded text-sm border-none outline-none'>Approve</button>
                                 <button className='col-span-6 flex items-center justify-center text-sm border-none outline-none  w-[137px] h-[36px] text-findchow-success-500'>Reject</button>
                            </div>
                      </div>
                      
                      <div className='grid grid-cols-12 items-center'>
                            <div className='col-span-9 grid grid-cols-12'>
                                <div className='col-span-3 text-[#282828]'>Plymouth Jollof Kitchen</div>
                                <div className='col-span-3 text-[#002FD7] underline'>
                                    <a href='mailto:plymouthjollof@gmail.com'>plymouthjollof@gmail.com</a>
                                </div>
                                <div className='col-span-2  text-[#282828]'>Restaurants</div>
                                <div className='col-span-2 text-[#282828]'>Plymouth</div>
                                <div className='col-span-2 text-[#282828]'>Phone Number</div>
                            </div>
                            <div className='col-span-3 grid grid-cols-12 font-medium'>
                                 <button className='col-span-6 flex items-center justify-center w-[137px] h-[36px] bg-findchow-success-500 text-white rounded text-sm border-none outline-none'>Approve</button>
                                 <button className='col-span-6 flex items-center justify-center text-sm border-none outline-none  w-[137px] h-[36px] text-findchow-success-500'>Reject</button>
                            </div>
                      </div>
                      
                      <div className='grid grid-cols-12 items-center'>
                            <div className='col-span-9 grid grid-cols-12'>
                                <div className='col-span-3 text-[#282828]'>Plymouth Jollof Kitchen</div>
                                <div className='col-span-3 text-[#002FD7] underline'>
                                    <a href='mailto:plymouthjollof@gmail.com'>plymouthjollof@gmail.com</a>
                                </div>
                                <div className='col-span-2 text-[#282828]'>Restaurants</div>
                                <div className='col-span-2 text-[#282828]'>Plymouth</div>
                                <div className='col-span-2 text-[#282828]'>Phone Number</div>
                            </div>
                            <div className='col-span-3 grid grid-cols-12 font-medium'>
                                 <button className='col-span-6 flex items-center justify-center w-[137px] h-[36px] bg-findchow-success-500 text-white rounded text-sm border-none outline-none'>Approve</button>
                                 <button className='col-span-6 flex items-center justify-center text-sm border-none outline-none  w-[137px] h-[36px] text-findchow-success-500'>Reject</button>
                            </div>
                      </div>
                    </div>
                </div>
         </div>
         <MerchantList />
        
    </div>
  )
}

export default Merchants