import {useState} from 'react';
import moment from 'moment';
import Accordion from '../../Shared/Accordion'
import {Link, useParams} from "react-router-dom"
import MerchantOrders from '../../../services/MerchantOrder'
import { useRecentOrdersQuery } from '../../../services/OrderService'
import { handlNewError } from '../../../utils/handleNewError'
import { Hourglass } from 'react-loader-spinner';
const MerchantOrderHistory = () => {
  const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
  const {id} = useParams();
  const {isError, error, data, isFetching, isLoading} = useRecentOrdersQuery({page,limit, restaurantId: id});
  const isErr = handlNewError(isError, error, "data");
  const docs  = data?.results?.docs;
  const hasNextPage = data?.results?.hasNextPage;
  const hasPrevPage = data?.results?.hasPrevPage;
  // const totalDocs = data?.results?.totalDocs;
  // const totalPages = data?.results?.totalPages;
 console.log("data", data)

  const handlePagination = (type: string) => {
      if(hasNextPage && type === "nextPage"){
          setPage(prev => prev + 1)
      }else if(hasPrevPage && type === "prevPage"){
          setPage(prev => prev - 1)
      }
  }
   const [open, setOpen] = useState(null);
   const toggle = (index: any) =>  {
        if(open === index){
          return setOpen(null)
        }

        setOpen(index)
   }

  return (
    <div className=' divide divide-y-2 divide-y-1 divide-[#F5F5F5]'>
         {
             isLoading ?  <div className="hour__glass-wrapper"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isError ? <p className="text-[#D90000] py-4 text-center text-base">{error?.hasOwnProperty("data") ? JSON.parse(isErr)["message"] : JSON.parse(isErr)}</p> : <>
             
             {
              docs && docs?.length > 0 ? <>
                  {
                    docs?.map((data: any, index: number) => (
                      <Accordion key={index} image={data.userId.picture} date={moment(data.createdAt).format("MMM D")} name={data.userId.full_name} open={index === open} toggle={() => toggle(index)} orders={data.items} price={data.grand_total} time={moment(data.createdAt).format("HH:mm")}/>
                  ))
                  }
                </> : <p className='text-center text-lg py-4'>No transaction yet</p>
           
              }
             </>
     
      }
       
    </div>
  )
}

export default MerchantOrderHistory