import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { Page } from "../models/Pages.model";
import { CreateRole, ListRoles } from "../models/Role.model";
import { RootState } from "../store";
import { getValue } from "./DecryptData";
import { getUrl } from "../utils/getUrl";
const values =  getValue("findchowApp");
export const roleService = createApi({
    reducerPath: "roleService",
    baseQuery: fetchBaseQuery({baseUrl: getUrl(process.env.REACT_APP_ENV), prepareHeaders: (headers, { getState } ) => {
        const state = getState() as RootState;
        const token = state?.auth?.token || values.token
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }
}),
    tagTypes: ["RoleService"],
    endpoints: (builder) => ({
        roleList: builder.query<ListRoles, Page>({
            query: ({page,limit}) => ({
                url: `/api/role/all?page=${page}&limit=${limit}`,
                method: "get",   
            }),
            providesTags: ["RoleService"]
        }),
       createRole: builder.mutation({
            query: (body: CreateRole) => ({
                url: "/api/admin/create",
                method: "post",
                body
            }),
            invalidatesTags: ["RoleService"]
       })
    })
})

export const {useRoleListQuery, useCreateRoleMutation} = roleService;