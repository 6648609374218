import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { MerchantTransactions, MerchantType, Merchants, MerchantsAnalytics, MerchantsSearch, SingleMerchant } from './../models/MerchantType.model';
import { Page } from "../models/Pages.model";
import { RootState } from "../store";
import { getValue } from "./DecryptData";
import { getUrl } from "../utils/getUrl";
const values =  getValue("findchowApp");



export const merchantTypeApi = createApi({
    reducerPath: "merchantTypeApi",
    baseQuery: fetchBaseQuery({baseUrl: getUrl(process.env.REACT_APP_ENV),  prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState;
        const token = state?.auth?.token || values.token
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }
}),
    tagTypes: ["MerchantType"],
    endpoints: (builder) => ({
        merchantType: builder.query<MerchantType, any | void>({
            query: () => `/api/merchant/type/all`,    
            providesTags: ["MerchantType"]
        }),
        listMerchants: builder.query<Merchants, Page>({
            query: ({page,limit}) => ({
                url: `/api/merchant/all?page=${page}&limit=${limit}`,
                method: "get"
            }),
            providesTags: ["MerchantType"] 
        }),
        merchantDetails: builder.query<SingleMerchant, string >({
            query: (id) => ({
                url: `/api/merchant/dashboard/merchant/details?id=${id}`,
                method: "get"
            }),
            providesTags: ["MerchantType"] 
        }),
        merchantAnalytics: builder.query<MerchantsAnalytics, string>({
            query: (date_filter) => ({
                url: `/api/merchant/analytics?date_filter=${date_filter}`,
                method: "get"
            }),
            providesTags: ["MerchantType"] 
        }),
        merchantsSearch: builder.query<MerchantsSearch, string>({
            query: (search_term) => ({
                url: `/api/merchant/search?search_term=${search_term}`,
                method: "get"
            }),
            providesTags: ["MerchantType"] 
        }),
        merchantTransactions: builder.query<MerchantTransactions, Page>({
            query: ({page, limit, merchant_id}) => ({
                url: `/api/transaction/merchant/list?page=${page}&limit=${limit}&merchant_id=${merchant_id}`,
                method: "get",   
            }),
            providesTags: ["MerchantType"]
        }),
    })
});
export const {useMerchantTypeQuery, useListMerchantsQuery, useMerchantDetailsQuery, useMerchantAnalyticsQuery, useMerchantsSearchQuery, useMerchantTransactionsQuery} = merchantTypeApi;