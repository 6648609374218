import React from 'react';
import CardIcon from "../../assets/dashboard/bi_credit-card.svg";
import CancelIcon from "../../assets/dashboard/material-symbols_cancel.svg";
import MoneyIcon from "../../assets/dashboard/ph_money-fill.svg";

const TransactionCards = () => {
  return (
    <>
        <div className='col-span-2 flex gap-2 xl:gap-3 h-[129px] shadow-findchow-card-shadow bg-findchow-success-500 rounded-[5px] py-4 px-3 xl:px-4 font-Poppins findchow__overflow-y'>
              <div>
                  <img src={CardIcon} alt=""/>
               </div>
               <div className='flex flex-col gap-1 mt-[2px] xl:mt-0'>
                     <p className='text-white text-sm xl:text-base font-medium '>Transactions</p>
                     <h2 className='text-white text-[20px] xl:text-[30px] font-medium '>111,029</h2>
                     <div className='text-[#F0F0F0] text-[11px] font-medium'><span className='text-[#8ED100]'>+1.2%</span> from last month</div>
               </div>
        </div>
        <div className='col-span-2 flex gap-2 xl:gap-3 h-[129px] shadow-findchow-card-shadow bg-findchow-success-500 rounded-[5px] py-4 px-3 xl:px-4 font-Poppins findchow__overflow-y'>
              <div>
                  <img src={CancelIcon} alt=""/>
               </div>
               <div className='flex flex-col gap-1 mt-[2px] xl:mt-0'>
                     <p className='text-white text-sm xl:text-base font-medium '>Failed Transations</p>
                     <h2 className='text-white text-[20px] xl:text-[30px] font-medium '>102</h2>
                     <div className='text-[#F0F0F0] text-[11px] font-medium'><span className='text-[#FF5E5E]'>+1.2%</span> from last month</div>
               </div>
        </div>
        <div className='col-span-2 flex gap-2 xl:gap-3 h-[129px] shadow-findchow-card-shadow bg-findchow-success-500 rounded-[5px] py-4 px-3 xl:px-4 font-Poppins findchow__overflow-y'>
              <div>
                  <img src={MoneyIcon} alt=""/>
               </div>
               <div className='flex flex-col gap-1 mt-[2px] xl:mt-0'>
                     <p className='text-white text-sm xl:text-base font-medium '>Total Inflow</p>
                     <h2 className='text-white text-[20px] xl:text-[30px] font-medium '>£3,210,089</h2>
                     <div className='text-[#F0F0F0] text-[11px] font-medium'><span className='text-[#8ED100]'>+1.2%</span> from last month</div>
               </div>
        </div>
        <div className='col-span-2 flex gap-2 xl:gap-3 h-[129px] shadow-findchow-card-shadow bg-findchow-success-500 rounded-[5px] py-4 px-3 xl:px-4 font-Poppins findchow__overflow-y'>
              <div>
                  <img src={MoneyIcon} alt=""/>
               </div>
               <div className='flex flex-col gap-1 mt-[2px] xl:mt-0'>
                     <p className='text-white text-sm xl:text-base font-medium '>Total Outflow</p>
                     <h2 className='text-white text-[20px] xl:text-[30px] font-medium '>£2,210,089</h2>
                     <div className='text-[#F0F0F0] text-[11px] font-medium'><span className='text-[#8ED100]'>+1.2%</span> from last month</div>
               </div>
        </div>
    </>
  )
}

export default TransactionCards