import React, {useEffect, useState} from 'react';
import * as FaIcons from "react-icons/fa";
import * as TbIcons from "react-icons/tb"
import AllConsumers from './AllConsumers';
import SearchInput from '../Shared/SearchInput';
import { useSearchParams } from 'react-router-dom';
import { getValue } from '../../services/DecryptData';


const ConsumersList = () => {
  const [exportType, setExportType] = useState("Export as CSV");
  const [selectExportType, setSelectExportType] = useState(false);
  const [search, setSearch] = useSearchParams();
  const values =  getValue("findchowApp");
  
  const data = ["Export as CSV", "Export as PDF", "Export as Text"];

  const search_term = search && search.get("search_term");
  
  const handleSelectedType = (data: string) => {
     setExportType(data);
     setSelectExportType(false)
  }
  const handleSelect = () => {
      setSelectExportType(prev => !prev)
  }
  useEffect(() => {
    if(search_term === null || search_term === ""){
        search.delete("search_term")
        setSearch({})
    }
 }, [search_term, search, setSearch]);
  return (
    <div className='p-4 flex flex-col gap-4 min-h-[200px] bg-white border-[#F5F5F5] shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] rounded-[5px]'>
        <div className='relative'>
            <div className='flex justify-between items-center'>
                <div className='flex items-center gap-7 font-Poppins'>
                    <div className='font-medium text-base text-[#282828]'>Customer List</div>
                    <div className='flex items-center gap-1 cursor-pointer text-[#A8A8A8] font-normal' onClick={handleSelect}><TbIcons.TbFileExport size={14}/>  <span className='text-[12px] underline'>{exportType}</span></div>
                </div>
                <SearchInput placeholder='Search constumers, id, name, etc' search={search} setSearch={setSearch}/>
            </div>
            {selectExportType && <div className='absolute top-[38px] min-h-[20px] p-2 w-[167px] bg-white shadow-2xl rounded-lg flex flex-col gap-1 divide-y divide-solid'>
            {
                data && data.map((data, index) => (
                    <span className='cursor-pointer py-1' key={index} onClick={() => handleSelectedType(data)}>
                       {data}
                    </span>
                ))
            }
            
           </div>
           }
        </div>
        <AllConsumers />
    </div>
  )
}

export default ConsumersList