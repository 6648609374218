import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  RouterProvider,
} from "react-router-dom";
import router from './Routes/Routes';

import './index.css';
import { store } from './store';
import Loader from './components/Loader';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <RouterProvider
           router={router}
           future={{ v7_startTransition: true }}
           fallbackElement={<Loader />}
        />
    </Provider>
  </React.StrictMode>
);
