import React from 'react';
import {Link} from "react-router-dom"
import SearchInput from '../../Shared/SearchInput';

const ConsumerTransactions = () => {
  return (
    <div className='font-Poppins'>
    <div className='flex justify-between items-center py-2'>
        <div className='flex items-center gap-10 font-medium'>
            <div className='text-sm text-[#282828]'>Payment History</div>
            <Link to='/transactions/all' className='text-[#005B61] text-[10px] underline underline-offset-1'>View all</Link>
        </div>
        <div>
            <SearchInput placeholder='Search transaction'/>
        </div>
    </div>
    <section className="max-w-[95%] mx-auto border-0 font-Poppins leading-normal not-italic">
   
        <div className="flex flex-col border-0 ">
            
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 border-0">
                <div className="inline-block min-w-full py-2 align-middle ">
                    <div className="overflow-hidden border-0 ">
                        <table className="min-w-full  divide-y divide-gray-200 ">
                            <thead>
                                <tr>
                                    <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                      Order # 
                                    </th>
                                    <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                       Date
                                    </th>

                                    <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                        Card used
                                    </th>
                                    <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                         Store
                                    </th>
                                    <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                         Category
                                    </th>
                                    <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                        Status
                                    </th>
                                  
                                </tr>
                            </thead>
                            <tbody className=" divide-y divide-gray-200 ">
                                <tr>
                                    <td className="px-4 py-4 whitespace-nowrap">
                                        <Link to={`/merchants/1`} className="underline text-[#0038FD] text-[11px] font-normal cursor-pointer">
                                            12839283
                                        </Link>
                                    </td>
                                   <td className="px-4 py-4 text-[11px] font-normal text-[#282828] whitespace-nowrap truncate min-w-[70px] max-w-[80px]">
                                        <span>2 Mar, <span className='text-[#9C9C9C]'>13:10</span></span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] underline  whitespace-nowrap font-normal">
                                        <span className='text-[#002FD7]'>************4509</span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] font-normal  text-[#282828]  whitespace-nowrap truncate">Dammy’s Kitchen</td>
                                    <td className="px-4 py-4 text-[11px] whitespace-nowrap font-normal text-[#282828]">
                                        Food Bowls
                                    </td>
                                    <td className="px-4 py-4 text-[10px] whitespace-nowrap font-normal text-findchow-success-200">
                                        Successful
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-4  whitespace-nowrap">
                                        <Link to={`/merchants/1`} className="underline text-[#0038FD] text-[11px] font-normal cursor-pointer">
                                            12839283
                                        </Link>
                                    </td>
                                   <td className="px-4 py-4 text-[11px] font-normal text-[#282828] whitespace-nowrap truncate min-w-[70px] max-w-[80px]">
                                        <span>2 Mar, <span className='text-[#9C9C9C]'>13:10</span></span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] underline  whitespace-nowrap font-normal">
                                        <span className='text-[#002FD7]'>************4509</span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] font-normal  text-[#282828]  whitespace-nowrap w-[100px] max-w-[120px] truncate">Dammy’s Kitchen</td>
                                    <td className="px-4 py-4 text-[11px] whitespace-nowrap font-normal text-[#282828]">
                                        Food Bowls
                                    </td>
                                    <td className="px-4 py-4 text-[10px] whitespace-nowrap font-normal text-findchow-success-200">
                                        Successful
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-4  whitespace-nowrap">
                                        <Link to={`/merchants/1`} className="underline text-[#0038FD] text-[11px] font-normal cursor-pointer">
                                            12839283
                                        </Link>
                                    </td>
                                   <td className="px-4 py-4 text-[11px] font-normal text-[#282828] whitespace-nowrap truncate min-w-[70px] max-w-[80px]">
                                        <span>2 Mar, <span className='text-[#9C9C9C]'>13:10</span></span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] underline  whitespace-nowrap font-normal">
                                        <span className='text-[#002FD7]'>************4509</span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] font-normal  text-[#282828]  whitespace-nowrap w-[100px] max-w-[120px] truncate">Dammy’s Kitchen</td>
                                    <td className="px-4 py-4 text-[11px] whitespace-nowrap font-normal text-[#282828]">
                                        Food Bowls
                                    </td>
                                    <td className="px-4 py-4 text-[10px] whitespace-nowrap font-normal text-findchow-success-200">
                                        Successful
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-4  whitespace-nowrap">
                                        <Link to={`/merchants/1`} className="underline text-[#0038FD] text-[11px] font-normal cursor-pointer">
                                            12839283
                                        </Link>
                                    </td>
                                   <td className="px-4 py-4 text-[11px] font-normal text-[#282828] whitespace-nowrap truncate min-w-[70px] max-w-[80px]">
                                        <span>2 Mar, <span className='text-[#9C9C9C]'>13:10</span></span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] underline  whitespace-nowrap font-normal">
                                        <span className='text-[#002FD7]'>************4509</span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] font-normal  text-[#282828]  whitespace-nowrap w-[100px] max-w-[120px] truncate">Dammy’s Kitchen</td>
                                    <td className="px-4 py-4 text-[11px] whitespace-nowrap font-normal text-[#282828]">
                                        Food Bowls
                                    </td>
                                    <td className="px-4 py-4 text-[10px] whitespace-nowrap font-normal text-findchow-success-200">
                                        Successful
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-4  whitespace-nowrap">
                                        <Link to={`/merchants/1`} className="underline text-[#0038FD] text-[11px] font-normal cursor-pointer">
                                            12839283
                                        </Link>
                                    </td>
                                   <td className="px-4 py-4 text-[11px] font-normal text-[#282828] whitespace-nowrap truncate min-w-[70px] max-w-[80px]">
                                        <span>2 Mar, <span className='text-[#9C9C9C]'>13:10</span></span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] underline  whitespace-nowrap font-normal">
                                        <span className='text-[#002FD7]'>************4509</span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] font-normal  text-[#282828]  whitespace-nowrap w-[100px] max-w-[120px] truncate">Dammy’s Kitchen</td>
                                    <td className="px-4 py-4 text-[11px] whitespace-nowrap font-normal text-[#282828]">
                                        Food Bowls
                                    </td>
                                    <td className="px-4 py-4 text-[10px] whitespace-nowrap font-normal text-findchow-success-200">
                                        Successful
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-4  whitespace-nowrap">
                                        <Link to={`/merchants/1`} className="underline text-[#0038FD] text-[11px] font-normal cursor-pointer">
                                            12839283
                                        </Link>
                                    </td>
                                   <td className="px-4 py-4 text-[11px] font-normal text-[#282828] whitespace-nowrap truncate">
                                        <span>2 Mar, <span className='text-[#9C9C9C]'>13:10</span></span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] underline  whitespace-nowrap font-normal">
                                        <span className='text-[#002FD7]'>************4509</span>
                                    </td>
                                    <td className="px-4 py-4 text-[11px] font-normal  text-[#282828]  whitespace-nowrap truncate">Dammy’s Kitchen</td>
                                    <td className="px-4 py-4 text-[11px] whitespace-nowrap font-normal text-[#282828]">
                                        Food Bowls
                                    </td>
                                    <td className="px-4 py-4 text-[10px] whitespace-nowrap font-normal text-red-500">
                                        Failed
                                    </td>
                                </tr>
                            
                            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </section>
</div>
  )
}

export default ConsumerTransactions