import React from 'react';
import placeholderIcon from "../../../assets/account/placeholder.svg"

const Basket = () => {
  return (
    <>
     <div className='px-4 text-base text-[#282828] font-Poppins font-medium'>
         Basket
     </div>
     <div className='flex flex-col gap-2 divide-y-[2px] divide-[#F4F4F4]'>
          <div className='grid grid-cols-12 place-items-start px-4 text-[#9A9A9A] text-xs font-normal font-Poppins pt-4'>
              <div className='col-span-8 self-start'>
                  Item
              </div>
              <div className='col-span-2 place-self-end'>
                  Qty
              </div>
              <div className='col-span-2 place-self-end'>
                   Price
              </div>

          </div>
          <div className='grid grid-cols-12 place-items-start px-4 text-[#9A9A9A] text-xs font-normal font-Poppins py-4'>
              <div className='col-span-8 self-start flex items-center gap-2 font-Poppins font-normal text-[#282828] text-xs'>
                  <div><img src={placeholderIcon} alt="" width={24} height={24}/></div><div className='w-[100%] truncate' title='Jollof Rice Combo w/Turkey & Stew'>Jollof Rice Combo w/Turkey & Stew</div>
              </div>
              <div className='col-span-2 place-self-end'>
                  2
              </div>
              <div className='col-span-2 place-self-end'>
                  £21.24
              </div>

          </div>
          <div className='grid grid-cols-12 place-items-start px-4 text-[#9A9A9A] text-xs font-normal font-Poppins py-4'>
              <div className='col-span-8 self-start flex items-center gap-2 font-Poppins font-normal text-[#282828] text-xs'>
                  <div><img src={placeholderIcon} alt="" width={24} height={24}/></div><div className='w-[100%] truncate' title='Jollof Rice Combo w/Turkey & Stew'>Vegetarian Hot dog & Sauce</div>
              </div>
              <div className='col-span-2 place-self-end'>
                  1
              </div>
              <div className='col-span-2 place-self-end'>
                  £15.24
              </div>

          </div>
          <div className='grid grid-cols-12 w-full justify-between items-center px-4 text-[#282828] text-[11px] font-semibold font-Poppins py-4 gap-3'>
             <>
                <div className='col-span-6'>

                </div>
        
                <div className='col-span-6 flex justify-between items-center w-full'>
                    <div>
                        Subtotal
                    </div>
                    <div className='leading-[0.25px]'>
                         £36.29
                    </div>
                </div>
              </>
              <>
                <div className='col-span-6'>

                </div>
        
                <div className='col-span-6 flex justify-between items-center w-full'>
                    <div>
                        VAT (20%)
                    </div>
                    <div className='leading-[0.25px]'>
                        £1.55
                    </div>
                </div>
              </>
              <>
                <div className='col-span-6'>

                </div>
        
                <div className='col-span-6 flex justify-between items-center w-full'>
                    <div>
                        Delivery fee
                    </div>
                    <div className='leading-[0.25px]'>
                         £1.45
                    </div>
                </div>
              </>
          </div>
          <div className='grid grid-cols-12 place-items-start px-4 text-[#282828] text-[11px] font-semibold font-Poppins py-4'>
              
          <>
                <div className='col-span-6'>

                </div>
        
                <div className='col-span-6 flex justify-between items-center w-full'>
                    <div className='ml-2'>
                         TOTAL
                    </div>
                    <div className='leading-[0.25px]'>
                        £39.29
                    </div>
                </div>
              </>

          </div>
     </div>
    </>
  )
}

export default Basket