import React, {useState} from 'react'
import SharedFilter from '../../components/Shared/SharedFilter'
import ConsumersAnalytics from '../../components/Consumer/ConsumersAnalytics'
import ConsumersList from '../../components/Consumer/ConsumersList';
import moment from 'moment';

const Consumers = () => {
  const [sortShared, setSortShared] = useState(false);
  const [selectData,setSelectData] = useState("This Month");
  const [itemIndex, setItemIndex] = useState(0);
  const [openDate, setOpenDate] = useState(false)
  const date = moment().format("YYYY/MM/DD");
  const [newDate, setNewDate] = useState(date)
  const data = ["This month", "Last month", newDate];
  return (
    <div className='w-[100%] max-w-[1142px] mx-auto flex flex-col gap-4'>
         <SharedFilter b="[#575757]"  w="[167px]" h="[36px]" t="#575757" s="sm" gap="3" iconSize={16} data={data} sortShared={sortShared} setSortShared={setSortShared} selectData={selectData} setSelectData={setSelectData} itemIndex={itemIndex} setItemIndex={setItemIndex} setOpenDate={setOpenDate}/>
         <ConsumersAnalytics />
         <ConsumersList />
    </div>
  )
}

export default Consumers