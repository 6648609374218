import React, {FC} from 'react';
import { Link } from 'react-router-dom';
import itemImg from "../../../assets/dashboard/item-img.svg";
import { BsCheck, BsEnvelope, BsFillStarFill, BsTelephone, BsThreeDotsVertical } from 'react-icons/bs';
import {GrLocation} from "react-icons/gr";
import UserProp from '../../../models/User.model';
import Items from './Items';

type Prop = {
    restaurantId: any
    foodId: any
    _id: string
}

const ItemDetails:FC<UserProp> = ({orderData}) => {
    const items = orderData && orderData.items;
    const restaurantDetails = orderData && orderData.restaurantId
    const merchantCategory = orderData && orderData.merchant_category
    
    const address = restaurantDetails && restaurantDetails.address;
    const restaurantAddress = `${address?.line_1} ${address?.city === undefined ? "" : `, ${address?.city}` }, ${address?.post_code} ${address?.country}`

  return (
    <figure className='col-span-6 flex flex-col gap-4 font-Poppins'>
        {
            items && items.map(({restaurantId, foodId, _id}: Prop) => (
                <div key={_id} className='bg-white flex shadow-findchow-card-shadow min-h-[154px] border-[1px] border-[#F5F5F5] rounded-[5px]'>
                <img src={foodId?.image_url || itemImg} className='w-[189px] h-auto' alt="" width="189" loading='lazy'/>
                <div className='flex justify-between w-[100%] p-4'>
                   <div className='flex flex-col gap-1'>
                      <div className='flex justify-center items-center p-[3px_8px] bg-[#005B611C] rounded-[300px] text-findchow-success-500 text-[10px] max-w-[80px] font-semibold tracking-[0.25px]'>{merchantCategory}</div>
                      <div className='flex w-[100%] gap-8 mt-2 items-start'>
                          <div className='flex flex-col gap-3 w-[40%]'>
                              <h1 className='text-[#282828] text-base font-medium leading-[22px]'>{restaurantDetails.restuarant_name || restaurantId.restuarant_name}</h1>
                              <a href={`mailto:${restaurantDetails.email || restaurantId.email}`} className='flex gap-3 items-center text-[11px] text-[#282828] font-normal'><BsEnvelope size={11}/>{restaurantDetails.email || restaurantId.email}</a>
                              <a href={`tel:${restaurantDetails.phone_number || restaurantId.phone_number}`} className='w-100 text-[#282828] text-[11px] font-normal flex items-center gap-3'><BsTelephone size={11}/>{restaurantDetails.phone_number || restaurantId.phone_number}</a>
                          </div>
                          <div className='w-[55%] flex flex-col gap-3 mt-[4px]'>
                              <div className='flex items-center gap-2 text-[#8E8E8E] text-xs font-[450]'><BsFillStarFill size={16} color='#EFBA00'/> {orderData?.restaurantId?.averageRating}/5.0 Good ({orderData?.restaurantId?.totalRating})</div>
                              <div className='flex  gap-2 items-start text-[#282828] text-[11px] font-normal'>
                                  <GrLocation size={16} color='#000000'/> 
                                  {restaurantAddress}
                              </div>
                          </div>
                      </div>
                   </div>
                   <Link to={`/merchants/${restaurantDetails.id || restaurantId}`} className='w-[70px] text-[11px] text-findchow-success-500 underline font-medium whitespace-nowrap'>View store</Link>
                </div>
          </div>
            ))
        }
       
        <div className='findchow__overflow-y bg-white shadow-findchow-card-shadow h-[278px] border-[1px] border-[#F5F5F5] rounded-[5px]'>
             <div className='py-3 sticky top-0 z-10 bg-white'>
                <div className='flex justify-between items-center px-4'>
                    <div className='w-[60%] text-base text-black font-medium'>ITEMS</div>
                    <div className='w-[40%] flex justify-between items-center text-base text-black font-medium'>
                        <span className='w-[33%] text-center'>QTY</span>
                        <span className='w-[33%] text-center'>PRICE</span>
                        <span className='w-[33%] text-center'>TOTAL</span>
                    </div>
                </div>
                
             </div>
             <Items items={items}/>
        </div>
        <div className='bg-white w-[100%] shadow-findchow-card-shadow h-[134px] border-[1px] border-[#F5F5F5] rounded-[5px] findchow__overflow-y overflow-x-hidden'>
             <div className='sticky top-0 z-10 bg-white'>
                 <h1 className='px-4 pt-2 tex-base font-medium'>
                    Transaction details
                 </h1>   
              </div>
              <section className="max-w-[100%] mx-auto border-0 font-Poppins leading-normal not-italic">
                    <div className="flex flex-col border-0 ">
                        <div className="-mx-4 border-0">
                            <div className="inline-block min-w-full align-middle ps-4">
                                <div className="overflow-x-auto border-0 ">
                                    <table className="min-w-full  divide-y divide-gray-200 ">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="px-4 py-3 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  ">
                                                    Order #   
                                                </th>
                                                <th scope="col" className="px-4 py-3 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  ">
                                                    Date 
                                                </th>

                                                <th scope="col" className="px-4 py-3 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  ">
                                                    Total
                                                </th>

                                                <th scope="col" className="px-4 py-3 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  ">
                                                    Items
                                                </th>

                                                <th scope="col" className="px-4 py-3 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  ">
                                                    Payment
                                                </th>
                                                <th scope="col" className="px-4 py-3 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  ">
                                                    Paid
                                                </th>
                                                <th scope="col" className="px-4 py-3 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  ">
                                                    Status
                                                </th>
                                                <th scope="col" className="px-4 py-3 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  ">
                                                   
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className=" divide-y divide-gray-200">
                                           
                                            <tr>
                                                <td className="px-4 py-3 text-[#282828] text-xs font-normal">
                                                    12839283
                                                </td>
                                                <td className="px-4 py-3 text-xs text-[#282828]  font-normal">03 Mar, 13:01</td>
                                                <td className="px-4 py-4 text-xs  text-[#282828]">
                                                    £21.24
                                                </td>
                                                <td className="px-4 py-3 text-xs text-[#282828]  font-normal">
                                                    2
                                                </td>
                                                <td className="px-4 py-3 text-xs font-normal text-[#282828] ">Stripe</td>
                                                <td className="px-4 py-3 text-xs font-normal text-[#27AE60]">
                                                    <BsCheck size={16}/>
                                                </td>
                                                <td className="px-4 py-3 text-xs font-normal text-[#282828]">
                                                    Open
                                                </td>
                                                <td className="px-4 py-3 text-xs font-normal text-[#282828] cursor-pointer">
                                                    <BsThreeDotsVertical size={16}/>
                                                </td>
                                            </tr>
                                      
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

               </section>
        </div>
    </figure>
  )
}

export default ItemDetails