import {Collapse} from "react-collapse";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import defaultUser from "../../assets/account/ant-design_user-outlined.svg";

type Order = {
  name: string
  foodPrice: string
  protein_cost : any
  extras: any[]
  description: string
  restaurantId: string
  totalCost : number
  foodId: string
  quantity: number
  _id: string
}
type Prop = {
    name: string; 
    image: string;
    date: string;
    orders: Order[] | any[];
    price: number | string;
    time: string;
    open: any;
    toggle: (index: any) => void

}

const Accordion = ({name, image, date, open, orders, time, price, toggle}: Prop) => {

  return (
    <section className='font-Poppins font-normal'>
        
             <div className='grid grid-cols-11 py-2 items-start cursor-pointer' onClick={toggle}>
           
                  <div className='col-span-8 flex gap-2 items-start'>
                    <div className="w-[24px] h-[24px] bg-gray-200 border-1 border-findchow-success-500 rounded-full p-1">
                       <img src={image || defaultUser} alt="restaurant" width={24} height={24}/>
                    </div>
                    <div className="w-[75%] whitespace-nowrap truncate text-xs text-[#282828]">
                        {name}
                    </div>
                  </div>
                 
                  <div className='col-span-2 text-[10px] text-[#575757] text-right'>
                      {date}
                  </div>
                  <div className='col-span-1 ml-2'>
                       {open ? <AiOutlineMinus size={16} color="#E32021"/> : <AiOutlinePlus size={16} color="#005B61"/>}
                  </div>    
             </div>
             
              <Collapse isOpened={open}>
                  <div className="grid grid-cols-11 -mt-[18px] font-Poppins font-normal">
                      <div className="col-span-8 flex gap-2  my-2">
                           <div className="w-[24px]"></div>
                           <div className=" w-[75%]">
                              {
                                    orders.map((data) => (
                                        <div key={data._id} className="whitespace-nowrap truncate text-[10px] text-[#989898] flex flex-col">
                                            <span>{`${data.quantity}x  ${data.name}`}</span> 
                                        </div>
                                    ))
                                }
                           </div>
                           
                      </div>
                      <div className="col-span-2 flex flex-col justify-between text-right mb-2">
                        <div className="text-[8px] text-[#989898]">
                           {time}
                        </div>
                         <div className="text-xs text-right text-[#282828]">£{price}</div>
                      </div>
                      <div className="col-span-1"></div>
                   </div>
              </Collapse>  
                      
    
    </section>
  )
}

export default Accordion