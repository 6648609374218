// import React, {useState} from 'react'

import { Link } from 'react-router-dom'
import ConstumerTransactions from './ConsumerTransactions';
import ConsumerOrderHistory from './ConsumerOrderHistory';
import ConsumerChart from './ConsumerChart';
import OrderCategoryChart from './OrderCategoryChart';


const ConsumerTransactionsDetails = () => {
    // const [sortShared, setSortShared] = useState(false);
    // const [selectData,setSelectData] = useState("This Month");
    // const [itemIndex, setItemIndex] = useState(0)
    // const data = ["This month", "7days ago", "Last month", "3 months ago", "6 months ago", "Last year", "This year"];
    // const handleSelected = (data: string, index: number) => {
    //     setItemIndex(index)
    //     setSortShared(false);
    //     setSelectData(data)
    // }
    // const handleSort = () => {
    //     setSortShared(prev => !prev)
    // }
  return (
    <div className='grid grid-cols-11 gap-4 font-Poppins'>
         <div className='flex flex-col col-span-8 gap-4'>
            <div className='grid grid-cols-9 gap-4'>
                <div className='col-span-3 flex flex-col gap-4'>
                    <div className='h-[232px] max-h-[232px] findchow__overflow-y bg-white shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] border border-1 border-white rounded-[5px] px-2 py-4'>
                         <OrderCategoryChart />
                    </div>
                    
                </div>
                <div className='flex flex-col gap-2 col-span-6 max-h-[232px] min-h-[232px] findchow__overflow-y bg-white shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] border border-1 border-white rounded-[5px]  font-Poppins'>
                      <div className='flex items-center gap-10 px-4 pt-4 sticky top-0 bg-white z-20'>
                          <div className='text-sm text-[#282828] font-medium -mb-4'>Sales Overview</div>
                      </div>
                      <div className='flex max-h-[190px] gap-0 w-[100%]  px-4 items-center'>
                         <div className='w-[100%] merchant__chart-bar'>
                               <ConsumerChart />
                         </div>
                      </div>
                </div>
            </div>
            <div className='bg-white shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] border border-1 border-white rounded-[5px] p-4'>
                 <ConstumerTransactions />
            </div>
         </div>
         <div className='col-span-3 h-auto max-h-[450px] bg-white shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] border border-1 border-white rounded-[5px] font-Poppins'>
            <div className='sticky top-0 bg-white flex justify-between items-center p-4 z-10'>
                <div className='font-Poppins text-[#282828] text-sm font-medium'>Recent Orders</div>
                <Link to="/" className='text-[10px] text-findchow-success-500 font-medium underline underline-offset-1'>View all</Link>
            </div>
            <div className='h-auto max-h-[400px]  findchow__overflow-y px-4 pb-3'>
                 <ConsumerOrderHistory />
            </div>
         </div>
    </div>
  )
}

export default ConsumerTransactionsDetails