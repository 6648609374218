import React from 'react'

const Permission = () => {
  return (
    <div className=' px-4  py-2  font-Poppins'>
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-1'>
            <div className='font-semibold text-base text-[#282828]'>Set permissions</div>
            <p className='text-xs text-[#939393] font-normal'>Modify the access of users in your organisation</p>
        </div>
        <div className='flex items-center gap-4'>
            <label htmlFor='select-role' className='text-[#575757] text-sm font-medium'>Select role: </label>
            <select className='text-sm text-[#575757] font-medium p-[4px_2px_4px_9px] border-[1px] border-[#E2E2E2] rounded-[5px] outline-none cursor-pointer'>
                <option>Super Admin</option>
                <option>Advisor</option>
                <option>CFO</option>
            </select>
        </div>
      </div>
      <div className='flex flex-col gap-2 mt-3 border-[1px] border-[#E2E2E2] max-h-[400px] rounded-[10px] p-4 findchow__overflow-y'> 
          <div className='grid grid-cols-12'>
              <div className='col-span-4 font-normal text-xs  cursor-pointer'>
                  <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                       <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users manage transactions</span>
                  </label>
              </div>
              <div className='col-span-4 font-normal text-xs  cursor-pointer'>
                   <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users view account</span>
                    </label>
              </div>
              <div className='col-span-4 font-normal text-xs  cursor-pointer' >
                    <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users export customer data as csv</span>
                    </label>
                </div> 
          </div> 
          <div className='grid grid-cols-12'>
              <div className='col-span-4 font-normal text-xs  cursor-pointer'>
                  <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                       <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users manage transactions</span>
                  </label>
              </div>
              <div className='col-span-4 font-normal text-xs  cursor-pointer'>
                   <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users view account</span>
                    </label>
              </div>
              <div className='col-span-4 font-normal text-xs  cursor-pointer' >
                    <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users export customer data as csv</span>
                    </label>
                </div> 
          </div> 
          <div className='grid grid-cols-12'>
              <div className='col-span-4 font-normal text-xs  cursor-pointer'>
                  <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                       <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users manage transactions</span>
                  </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                   <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users view account</span>
                    </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer' >
                    <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users export customer data as csv</span>
                    </label>
                </div> 
          </div> 
          <div className='grid grid-cols-12'>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                  <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                       <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users manage transactions</span>
                  </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                   <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users view account</span>
                    </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer' >
                    <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users export customer data as csv</span>
                    </label>
                </div> 
          </div> 
          <div className='grid grid-cols-12'>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                  <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                       <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users manage transactions</span>
                  </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                   <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users view account</span>
                    </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer' >
                    <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users export customer data as csv</span>
                    </label>
                </div> 
          </div> 
          <div className='grid grid-cols-12'>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                  <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                       <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users manage transactions</span>
                  </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                   <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users view account</span>
                    </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer' >
                    <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users export customer data as csv</span>
                    </label>
                </div> 
          </div> 
          <div className='grid grid-cols-12'>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                  <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                       <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users manage transactions</span>
                  </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                   <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users view account</span>
                    </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer' >
                    <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users export customer data as csv</span>
                    </label>
                </div> 
          </div> 
          <div className='grid grid-cols-12'>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                  <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                       <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users manage transactions</span>
                  </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                   <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users view account</span>
                    </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer' >
                    <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users export customer data as csv</span>
                    </label>
                </div> 
          </div> 
          <div className='grid grid-cols-12'>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                  <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                       <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users manage transactions</span>
                  </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                   <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users view account</span>
                    </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer' >
                    <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users export customer data as csv</span>
                    </label>
                </div> 
          </div> 
          <div className='grid grid-cols-12'>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                  <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                       <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users manage transactions</span>
                  </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer'>
                   <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500  cursor-pointer'/><span className='text-[#787878]'>Allow users view account</span>
                    </label>
              </div>
              <div className='col-span-4 font-normal text-xs cursor-pointer' >
                    <label htmlFor='' className='flex items-center gap-2  cursor-pointer'>
                        <input type='checkbox' value="Allow users manage transactions" className='accent-findchow-success-500 cursor-pointer'/><span className='text-[#787878]'>Allow users export customer data as csv</span>
                    </label>
                </div> 
          </div> 
      
      </div>
    </div>
  )
}

export default Permission