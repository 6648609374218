import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { getUrl } from "../utils/getUrl";



type EmailList = {
    email: string
}

export const waitlistApi = createApi({
    reducerPath: "waitlistApi",
    baseQuery: fetchBaseQuery({baseUrl:  getUrl(process.env.REACT_APP_ENV)}),
    tagTypes: ["WaitList"],
    endpoints: (builder) => ({
        listWaitList: builder.query({
            query: ({page, limit}) => ({
                url: `/api/waitlist/list?page=${page}&limit=${limit}`,
                method: "get", 
            }),
            providesTags: ["WaitList"]
        }),
    })
})

export const {useListWaitListQuery} = waitlistApi;