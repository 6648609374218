import React, {useState, useEffect} from 'react'
import { getUrl } from '../utils/getUrl';
import  {io}  from 'socket.io-client';
import { useParams } from 'react-router-dom';

export const useTrackOrders = () => {
    // const [message, setMessage] = useState([])
  
    const [message, setMessage] = useState({
        // status: "",
        // userID: "",
        // reference_code: "",
        // orderItem: [],
        // createdAt: "",
        // note: "",
        status_time_tracker: [],
    });
    const {id} = useParams();
    console.log("params", id, getUrl(process.env.REACT_APP_ENV))
    useEffect(() => {
        const connectionOptions = {
          "force new connection": true,
          reconnectionAttempts: "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
          timeout: 5000, //before connect_error and connect_timeout are emitted.
          transports: ["websocket"],
          autoconnect: true
        };
        const socket = io.connect(
           getUrl(process.env.REACT_APP_ENV),
          connectionOptions
        );
    
        const emitEventEvery5Minutes = setInterval(() => {
          socket.emit("order-update", { id });
        }, 10000);
    
        socket.on("order-details", (data) => {
          console.log("Received data from server:", data);
          // setMessage(previous => ({...previous, data}));
          setMessage({
            ...message,
            // status: data?.status,
            // userID: data?.userId,
            // reference_code: data?.reference_code,
            // orderItem: data?.items,
            // createdAt: data?.createdAt,
            // note: data?.note,
            status_time_tracker: data.status_time_tracker,
          });
        });
    
        console.log(message, getUrl(process.env.REACT_APP_ENV));

    
        return () => {
          clearInterval(emitEventEvery5Minutes);
          socket.disconnect();
        };
      }, [id, message]);
  return message
}

export default useTrackOrders