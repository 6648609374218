import ResetSuccess from "../../components/Auth/Login/ResetSuccess"

const PasswordeResetSuccess = () => {
    return (
      <div className="w-screen h-screen overflow-hidden grid place-content-center auth-bg">
           <ResetSuccess />
      </div>
    )
  }
  
  export default PasswordeResetSuccess