import { useRouteError, useNavigate } from "react-router-dom";
import * as BsIcons from "react-icons/bs"

export default function ErrorPage() {
  const error: string | any = useRouteError();
  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center gap-4">
      <h1 className="text-3xl font-bold">Oops!</h1>
      <p className="text-base">Sorry, an unexpected error has occurred.</p>
      <p className="text-base">
        <i>{error.statusText || error.message}</i>
      </p>
      <div className="border-solid border-2 transition cursor-pointer border-slate-950 p-4 rounded-[2rem] group ring-slate-900/5 shadow-lg  hover:bg-sky-500 hover:border-sky-500">
         <div onClick={() => navigate(-1)} className="flex items-center justify-center gap-1 group-hover:text-white group-hover:gap-2 group-hover:delay-200 duration-300"><BsIcons.BsArrowLeft />Go Back</div>
      </div> 
    </div>
  );
}