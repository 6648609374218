import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import {ApexOptions} from "apexcharts"
import RadialChart from './RadialChart';
import { useAdminMonthlyEarningQuery, useAdminTopSellingQuery } from '../../services/DashboardService';
import { Hourglass } from 'react-loader-spinner';
import { handlNewError } from '../../utils/handleNewError';
import { Results } from '../../models/Dashboard.model';
import { useAppDispatch } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/authReducer';


type Product = {   
  name: string
  percentage: string
  value: number
}

const DashboardAnalytics = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const {data:adminData, isError, error, isLoading} = useAdminMonthlyEarningQuery({})
    const {data:topSelling, isLoading: isTopSellingLoading, isError: isTopSellingError, error: topSellingError} = useAdminTopSellingQuery({})
    const allData: Results | any = adminData && adminData.results;
    const thisMonth: string  = allData && allData.this_month_earnings
    const lastMonth: string  = allData && allData.last_month_earnings;
    const chartResults: string[] = allData && allData.chart_result! 
    const thisMonthPercent = parseFloat(thisMonth);
    const lastMonthPercent = parseFloat(lastMonth);
    const chartData: number[] = chartResults && chartResults.map((data : string) => {
       return parseFloat(data)
    });
    const topSellingProduct: Product | any = topSelling && topSelling.results 

    const errorMessage = handlNewError(isError,error, "data");
    const errorMessage2 = handlNewError(isTopSellingError,topSellingError, "data");
    const checkToken = errorMessage && JSON.parse(errorMessage)["message"];
    const checkCode = errorMessage && JSON.parse(errorMessage)["code"];

    

    const handleLogout = () => {
      dispatch(logout());
      navigate("/login")
 }
    const options: ApexOptions = {
        
            chart: {
              type: 'area',
              height: 300,
              zoom: {
                enabled: false
              },
              
              
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth',
              colors: ["#FAEEB0"],
              width: 0,
              dashArray: 0,
            },
            fill: {
                colors: ["#FAEEB0"],
                type: ["solid"]
            },
            title: {
              text: 'Monthly Earning',
              align: 'left'
            },
           
            xaxis: {
              type: 'category',
              categories: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
              
            },
            yaxis: {
              opposite: false
            },
            legend: {
              horizontalAlign: 'left',
              
            }
          
    }
    useEffect(() => {
       if((checkToken && checkToken.toLowerCase() === "Invalid login token".toLowerCase()) && (checkCode && checkCode === 401)){
          handleLogout()
       }
    }, [checkToken,checkCode])
  return (
    <div className='grid grid-cols-4 gap-4'>
         <div className='col-span-3 h-[327px] bg-white rounded-[5px] p-4 grid grid-cols-4 gap-3'>
               {
                    isLoading ?  <div className="hour__glass-wrapper flex col-span-4 mx-auto justify-center items-center"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isError ? <p className='col-span-4 text-center login__error-msg flex items-center justify-center text-lg font-semibold'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p> : <>
                      <div className='col-span-2'>
                         <Chart options={options} series={[{name: "earning", data: chartData}]} height={300} type='area'/>
                      </div>
                        <div className='grid grid-cols-2 col-span-2 font-Poppins not-italic text-center gap-4 xl:gap-8'>
                          <div className='flex gap-3 flex-col'>
                              <div className='text-[#575757] text-xs font-normal leading-normal text-center'>This month</div>
                              <div className='text-lg text-[#282828] leading-normal font-semibold'>£{thisMonth}</div>
                              <div className='text-xs text-[#575757] font-normal leading-normal'>This is the total amount this month</div>
                              <div>
                                    <RadialChart height={180} color='#005B61' size='70%' series={thisMonthPercent} show={false}/>
                              </div>
                          </div>
                          <div className='flex gap-3 flex-col'>
                              <div className='text-[#575757] text-xs font-normal leading-normal text-center'>Last month</div>
                              <div className='text-lg text-[#282828] leading-normal font-semibold'>£{lastMonth}</div>
                              <div className='text-xs text-[#575757] font-normal leading-normal'>This is the total amount last month</div>
                              <div>
                                <RadialChart height={180} color='#005B61' size='70%' series={lastMonthPercent} show={false}/>
                              </div>
                          </div>
                        </div>
                    </>
               }
           
         </div>
         <div className='findchow__overflow-y col-span-1 h-[327px] bg-white rounded-[5px] xl:px-2 flex flex-col gap-4 font-Poppins not-italic leading-normal'>
             <h1 className='text-[#282828] text-sm xl:text-base font-medium sticky top-0 bg-white z-20 pt-2'>
                Top Selling Categories
             </h1>
             {
                    isTopSellingLoading ?  <div className="hour__glass-wrapper flex col-span-3 mx-auto justify-center items-center"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isTopSellingError ? <p className='text-center login__error-msg flex items-center justify-center text-lg font-semibold'>{topSellingError?.hasOwnProperty("data") ? JSON.parse(errorMessage2)["message"] : JSON.parse(errorMessage2)}</p> : 
                    <>
                        {
                            topSellingProduct && topSellingProduct.length > 0 ? 
                            <div className='flex flex-col justify-between h-[307px] w-[100%]'>
                                  {
                                       topSellingProduct.map(({name, percentage, value} : Product) => {
                                           const splitData = percentage && percentage.split("");
                                           const newData = splitData.slice(0, -1);
                                           let str = "";
                                           for(let i = 0; i < newData.length; i++){
                                               str += newData[i]
                                           }
                                          const data = parseFloat(str) || 0
                                         
                                           return (
                                            <div key={name} className='w-[100%] flex border-b-[2px] border-[#F5F5F5] items-center justify-between gap-[2.5rem] pb-2 px-1'>
                                            <div className='flex flex-col gap-1 min-w-[75px]'>
                                                <div className='text-[#575757] text-xs font-normal'>{name}</div>
                                                <h2 className='text-[#282828] text-sm xl:text-sm font-semibold'>£{value}</h2>
                                            </div>
                                            <div>
                                              <RadialChart height={100} color='#FFE55F' size='50%' series={data} show={true}/>
                                            </div>
                                            </div>
                                           )
                                       }
                                        
                                       )
                                  }
                          
                            </div> : <div className='h-[100%] w-[100%] flex justify-center items-center'>No sold item yet</div>
                        }
                      
                    </>
             }
         </div>
    </div>
  )
}

export default DashboardAnalytics