import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom';
import { useGetMerchantOrdersByStatusQuery } from '../../../services/OrderService';
import { handlNewError } from '../../../utils/handleNewError';
import { Hourglass } from 'react-loader-spinner';
import DetailsTable from './DetailsTable';
import handlePagination from '../../Shared/Pagination';
import PaginationButton from '../../Shared/PaginationButton';


type Prop = {
  search : URLSearchParams
  setSearch: any
}

const MerchantOrderCancelled = () => {
  const {search, setSearch} = useOutletContext<Prop>();
  const search_term = search && search.get("search_term");
  const {id} = useParams();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {data, isLoading, isFetching, isError, error} = useGetMerchantOrdersByStatusQuery({page, limit, restaurantId: id, status: "cancelled"});

  const isErr = handlNewError(isError, error, "data");
  const docs  = data?.results?.docs;
  const hasNextPage = data && data.results && data.results.hasNextPage;
  const hasPrevPage = data && data.results && data.results.hasPrevPage;
  const totalPages = data?.results?.totalPages
  const pageNumber = data?.results?.page || 1;
  const totalDocs = (data && data.results && data.results.totalDocs) || 10;

  useEffect(() => {
    if(search_term === null || search_term === ""){
        search.delete("search_term")
        setSearch({})
    }
 }, [search_term, search, setSearch]);
 useEffect(() => {
     if(search_term){
       setLimit(totalDocs)
     }else {
     
        setLimit(10);
         setPage(pageNumber)
     }
 }, [search_term,totalDocs, pageNumber])
  return (
    <div className="flex flex-col border-0 ">
    {
        isLoading ?  <div className="hour__glass-wrapper"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isError ? <p className="text-[#D90000] py-4 text-center text-base">{error?.hasOwnProperty("data") ? JSON.parse(isErr)["message"] : JSON.parse(isErr)}</p> :
        <>
        {
            docs && docs?.length > 0 ? <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 border-0 horizontal-container">
              <DetailsTable docs={docs}/> 
            {
                totalPages && totalPages === 1 ? null : <PaginationButton isFetching={isFetching} hasPrevPage={hasPrevPage} hasNextPage={hasNextPage} handlePrevPagination={() => handlePagination("prevPage",hasNextPage,hasPrevPage,setPage, page)} handleNextPagination={() => handlePagination("nextPage",hasNextPage,hasPrevPage,setPage, page)} page={page} totalPages={totalPages}/>
        }
        </div> : <p className='text-center text-lg py-4'>No order yet</p>
        }
      </>


     }
    </div>
  )
}

export default MerchantOrderCancelled