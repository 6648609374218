import React, {useState} from 'react';
import Chart from 'react-apexcharts';

const ConsumerChart = () => {
    const [options, setOption] = useState({
        chart: {
           id: "findchow-consumers-chart"
        },
        maintainAspectRatio: false,
        xaxis: {
            categories: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
            
        },
        yaxis: {
            title: {
              text: 'x10 GBP',
              style: {
                    color: "#C5C5C5",
                    fontSize: '11px',
                    fontFamily: 'Poppins',
                    fontWeight: 500,
               }
          },   
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '50%',
              endingShape: 'rounded'
            },
          },
        legend: {
            show: true,
            position: 'top' as 'top',
            horizontalAlign: 'center' as const,
            fontSize: '11px',
            fontFamily: 'Poppins',
            offsetY: -3,
            offsetX: 2,
            markers: {
                width: 8,
                height: 8,
                radius: 2,
                fillColors: ['#27AE60', '#E32021']
            },
        },
        
        fill: {
            colors: ['#27AE60', '#E32021']
        },
                  
        title: {
          text: '',
        }
   })
   const [series, setSeries] = useState([{name: "Completed Orders", data: [10, 10,20,30,40,50, 60, 70, 50, 60,90, 100]}, {name: "Rejected Orders", data: [40, 20,20,30,50,10, 60, 70, 50, 6,3, 30]}])
  return (
    <Chart options={options} series={series} type='bar' height={190} />
  )
}

export default ConsumerChart