import React from 'react'
import "./styles/stepper.css";
import useTrackOrders from './useTrackOrders';
import { useParams } from 'react-router-dom';
const Stepper = () => {
    const {id} = useParams()
    const steps = ["Order Created", "Order Processing", "On Delivery", "Order Delivered"];
    const message = useTrackOrders();
    // console.log("message", message)
    const testData = ["a"]
    const testData1 = testData[0];
    const testData2 = testData[1];
    const testData3 = testData[2];
    const testData4 = testData[3];
  return (
    <ul className='w-[90%] flex relative z-10'>
        {
            steps?.map((step, idx) => (
            
                    
                    <li key={idx} className='flex flex-col w-[100%] gap-1'>
                        
                        <div className={`relative z-10 before:absolute before:content-[""] before:h-[1px] -before:z-10 before:w-[100%]  ${idx === 3 ? "before:bg-transparent" : (testData1  && testData2 && idx === 0) ?  "before:bg-findchow-success-500" :  (testData1 && testData2 && testData3 && idx === 1)  ? "before:bg-findchow-success-500" : (testData1 && testData2 && testData3 && testData4 && idx === 2) ? "before:bg-findchow-success-500" :  "before:bg-[#B9B9B9]"} before:top-2 before:-translate-y-1/2 before:-z-10`}>
                            <div className={`w-[16px] h-[16px] rounded-full ${idx === 0 ? "bg-findchow-success-500" : idx === 1 && testData1 && testData2 ? "bg-findchow-success-500" : idx === 2 && testData1 && testData2 && testData3 ? "bg-findchow-success-500" : idx === 3 && testData1 && testData2 && testData3 && testData4  ? "bg-findchow-success-500" : "bg-[#B9B9B9]"}`}></div>
                        </div>
                        
                    
                        <div className='flex flex-col'>
                            <span className={`text-base leading-[22px] not-italic font-semibold font-Poppins ${(idx === 0) || (idx === 1 && testData2) || (idx === 2 && testData3) || (idx === 3 && testData4) ? "text-findchow-success-500" : "text-[#575757]"}`}>{step}</span>
                            <span className='text-sm text-[#575757] leading-normal font-normal font-Poppins tracking-[0.25px]'>{testData1 && idx === 0 ? <>Thu, 21 Jul 2022<br />11:08 AM</> : testData2 && idx === 1 ? <>Thu, 21 Jul 2022<br />11:08 AM</> : testData3 && idx === 2 ? <>Thu, 21 Jul 2022<br />11:08 AM</> : testData4 && idx === 3 ? <>Thu, 21 Jul 2022<br />11:08 AM</> :  <>
                               --- <br />
                               --
                            </>}</span>
                        </div>
                    </li>
              
            ))
        }
    </ul>
  )
}

export default Stepper