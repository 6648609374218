import React from 'react';
import { Link } from 'react-router-dom';
import * as BsIcons from "react-icons/bs";
import food1 from "../../assets/dashboard/food1.svg"
import food2 from "../../assets/dashboard/food2.svg"
import food3 from "../../assets/dashboard/food3.svg";
import { useAdminLatestOrderQuery } from '../../services/DashboardService';
import { Hourglass } from 'react-loader-spinner';
import { handlNewError } from '../../utils/handleNewError';
import { statusCheck } from '../../utils/statusCheck';

const DashboardRecentOrder = () => {
    const {isError, isLoading, error, data} = useAdminLatestOrderQuery({page: 1, limit: 4});
    const errorMessage = handlNewError(isError,error, "data");
    const results = data && data.results && data.results.docs

  return (
    <div className='h-[499px] bg-white rounded-[5px] p-4 pt-0 flex flex-col gap-4 xl:gap-6 findchow__overflow-y'>
             {
                    isLoading ?  <div className="hour__glass-wrapper flex w-[100%] h-[100%] mx-auto justify-center items-center"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isError ? <p className='text-center login__error-msg flex items-center justify-center text-lg font-semibold'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p> :
                    <>
                        <div className='flex items-center justify-between sticky top-0 bg-white pt-4'>
                            <h3 className='text-base text-[#282828]'>Most Recent Orders</h3>
                            <Link to="/manage-orders" className='text-xs text-[#002FD7] underline font-normal'>View all</Link>
                        </div>
                        <div>
                             {
                                 results && results.length > 0 ? <>
                                   {
                                       results.map(({_id,reference_code, status, items, userId}) => {
                                         const item = items && items[0]
                                      
                                          return (
                                                <div className='bg-white flex justify-between items-center py-5 border-b-[2px] border-[#F4F4F4] font-Poppins font-medium leading-normal not-italic' key={_id}>
                                                <div className='flex items-center gap-2'>
                                                    <div className='border-1 border-gray-100 rounded-full w-[42px] h-[42px] xl:w-[62px] xl:h-[62px] bg-slate-200'>{
                                                        item.restaurantId.image_url !== "" && <img src={item?.restaurantId?.image_url} className='rounded-full w-[100%] h-[100%]' alt={item.restaurantId.restuarant_name}/>
                                                    }</div>
                                                    <div className='flex flex-col gap-1'>
                                                    <h3 className='text-black text-sm'>{item?.name}</h3>
                                                    <div className='text-xs text-[#575757]'>{item?.restaurantId?.restuarant_name}</div>
                                                    <div className='text-xs text-[#E32021] font-normal'>#{reference_code || "-----"}</div>
                                                    </div>
                                                </div>
                                                <div className='flex flex-col gap-1'>
                                                    <h3 className='text-black text-sm  md:max-w-[90px] truncate xl:max-w-[150px]'>{userId?.address?.city}</h3>
                                                    <div className='text-xs text-[#575757]'>{userId?.address?.first_line}</div>
                                                    <div className='text-xs text-[#575757] md:max-w-[90px] truncate xl:max-w-[150px]'>{userId?.address?.post_code}</div>
                                                </div>
                                                <div className='flex gap-2 items-center'>
                                                    <div className='flex flex-col justify-between gap-3'>
                                                        <div className={`${statusCheck(status)} p-[3px_12px] xl:p-[3px_15px] rounded-[300px] tracking-[0.25px] font-semibold text-[9px] xl:text-[10px]`}>{status?.toUpperCase()}</div>
                                                        <div className='flex items-center gap-2 text-sm'><span className='text-[#282828] font-medium tracking-[0.25px] max-w-[50px] truncate'>£{item?.totalCost}</span><span className='text-[#575757] font-normal'>x{item?.quantity}</span></div>
                                                    </div>
                                                    <div className='cursor-pointer'><BsIcons.BsThreeDotsVertical size={24}/></div>
                                                </div>
                                                </div>
                                          )
                                     })
                                   }
                             
                              
                                 </> : <div className='min-h-[400px] text-center flex justify-center items-center w-full h-full'>No order yet!</div>
                             }
                           
                        </div>
                    </>
              }
          </div>
  )
}

export default DashboardRecentOrder