import { FC, useEffect, useRef, useState } from "react"
import Promotion from "../../components/Promotion/Promotion";

interface Prop {

}

const Promotions:FC<Prop> = () => {
  const deletePromoRef = useRef<HTMLDialogElement | null>(null);
  const [promoDelete, setPromoDelete] = useState<boolean>(false)

  useEffect(() => {
    const deletePromo = deletePromoRef && deletePromoRef.current;
    if(promoDelete && deletePromo){  
          deletePromo?.showModal();
        
    }else {
         deletePromo?.close();
         setPromoDelete(false);
         
    }
}, [promoDelete,deletePromoRef]);
useEffect(() => {
  deletePromoRef?.current?.removeAttribute('open');
}, []);
  return (
    <>
    <div className='w-[100%] max-w-[1142px] mx-auto flex flex-col gap-4
     bg-white font-Poppins rounded-[5px] max-h-[772px] p-4'>
          <Promotion setPromoDelete={setPromoDelete}/>
     </div>
        <dialog ref={deletePromoRef} className={promoDelete ? "w-screen h-screen flex justify-center items-center bg-transparent" : "hidden"}>
           <div className="w-[400px] min-h-[200px] p-4 rounded-[10px] bg-white flex flex-col items-center justify-center gap-4">
              <h4>Are you sure you want to delete this promo?</h4>
                <div className="flex items-center justify-center gap-4 text-sm font-Poppins font-medium">
                    <button className="h-[40px] p-[0px_1rem_0px_1rem] rounded-md border-0 bg-findchow-success-500 flex items-center justify-center text-white">Yes</button> <button className="h-[40px] p-[0px_1rem_0px_1rem] rounded-md border-0 bg-findchow-success-500/20 flex items-center justify-center text-findchow-success-500" onClick={() => setPromoDelete(false)}>No</button>
                </div>
           </div>
           
        </dialog>
     </>
  )
}

export default Promotions