
import foodImg from "../assets/foodImg.svg"
const MerchantOrders = [
    {
        name: "Samuel Fakorede",
        image: foodImg,
        orders: ["1x 2L Edika ikong", "1x 1L Eforiro", "2x 4L Village Rice"],
        date: "Mar 3",
        time: "13:03",
        price: "£29.35"
    },
    {
        name: "Osita Chinedu",
        image: foodImg,
        orders: ["1x 2L Edika ikong", "1x 1L Eforiro", "2x 4L Village Rice"],
        date: "Mar 3",
        time: "13:03",
        price: "£29.35"
    },
    {
        name: "Haruna Hussein",
        image: foodImg,
        orders: ["1x 2L Edika ikong", "1x 1L Eforiro", "2x 4L Village Rice"],
        date: "Mar 3",
        time: "13:03",
        price: "£29.35"
    },
    {
        name: "Adekunle Ciroma",
        image: foodImg,
        orders: ["1x 2L Edika ikong", "1x 1L Eforiro", "2x 4L Village Rice"],
        date: "Mar 3",
        time: "13:03",
        price: "£29.35"
    },
    {
        name: "Victoria Ohaegbelele",
        image: foodImg,
        orders: ["1x 2L Edika ikong", "1x 1L Eforiro", "2x 4L Village Rice"],
        date: "Mar 3",
        time: "13:03",
        price: "£29.35"
    },
    {
        name: "Favour Okoye",
        image: foodImg,
        orders: ["1x 2L Edika ikong", "1x 1L Eforiro", "2x 4L Village Rice"],
        date: "Mar 3",
        time: "13:03",
        price: "£29.35"
    },
    {
        name: "Woleola Akinyemi",
        image: foodImg,
        orders: ["1x 2L Edika ikong", "1x 1L Eforiro", "2x 4L Village Rice"],
        date: "Mar 3",
        time: "13:03",
        price: "£29.35"
    },
    {
        name: "Dammy’s Kitchen",
        image: foodImg,
        orders: ["1x 2L Edika ikong", "1x 1L Eforiro", "2x 4L Village Rice"],
        date: "Mar 3",
        time: "13:03",
        price: "£29.35"
    },
    {
        name: "Dammy’s Kitchen",
        image: foodImg,
        orders: ["1x 2L Edika ikong", "1x 1L Eforiro", "2x 4L Village Rice"],
        date: "Mar 3",
        time: "13:03",
        price: "£29.35"
    }
];
export default MerchantOrders

