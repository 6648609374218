import DashboardAnalytics from "../../components/Dashboard/DashboardAnalytics"
import DashboardData from "../../components/Dashboard/DashboardData"
import OrderOverview from "../../components/Dashboard/OrderOverview"


const Dashboard = () => {
  return (
    <div className='w-[100%] max-w-[1142px] mx-auto flex flex-col gap-4'>
        <DashboardData />
        <DashboardAnalytics />
        <OrderOverview />
    </div>
  )
}

export default Dashboard