import React from 'react'
import SearchInput from '../Shared/SearchInput'

const RoleHeader = () => {
  return (
    <div className='flex items-center justify-between font-Poppins'>
         <div className='flex items-center gap-2'><span className='text-[#282828] text-lg font-semibold'>Roles</span><span className='h-[21px] p-[7px_6px] inline-flex justify-center items-center bg-[#005B61] font-normal text-[10px] text-white rounded-[100px]'>12</span></div>
         <div><SearchInput  placeholder='Search super admin & admins' /></div>
    </div>
  )
}

export default RoleHeader