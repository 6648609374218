import React, {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Hourglass } from  'react-loader-spinner';
import findchowLogo from "../../../assets/authpage/authpage-logo.svg";
import { useForgotPasswordMutation } from '../../../services/AuthService';
import { handlNewError } from '../../../utils/handleNewError';
import { handleSuccess } from '../../../utils/handleSucess';

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [err, setErr] = useState("");
  const [forgotPassword, {isSuccess, data, isLoading, isError, error}] = useForgotPasswordMutation();
  const navigate = useNavigate();
  const errorMessage = handlNewError(isError,error, "data");
  const successMessage = handleSuccess(isSuccess, data, "message")
  const handleForgotPassword = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if(email){
         await forgotPassword({email, user_type: "admin"})
    }else {
      setErr("Please Provide all details")
   }
  }
  const handleChange = async(e: React.ChangeEvent<HTMLInputElement>)=> {
      setEmail(e.target.value);
      setErr("")
  }
 useEffect(() => {
     if(isSuccess && successMessage.length > 0){
        navigate("/auth/reset_success", {state: {message: successMessage}})
        
     }
 }, [isSuccess, navigate, successMessage]);
 
  return (
    <div className='flex flex-col items-center gap-2 h-[100%] w-[100%] min-w-[405px] max-w-[405px] m-auto min-h-[540px] max-h-[604px] border-[2px] border-solid border-white bg-white bg-opacity-40 rounded-[15px] shadow-auth-shadow p-4 font-Poppins'>
        <div className='w-[136.663px] h-[43px] my-3'><img src={findchowLogo} alt="logo" height={42} width={136.663}/></div>
        <p className='text-center text-lg text-[#FFE55F] my-1'>Order management portal</p>
        <form className='flex flex-col items-center gap-6 mt-8'>
           <div className='flex flex-col items-center text-center gap-3'>
               <h2 className='font-medium text-white text-2xl'>Reset Password</h2>
               <p className='w-[280px] leading-6 text-sm text-[#C9C9C9] font-normal'>Enter your email address to get a reset password sent to you </p>
           </div>
         
           <div className='flex flex-col gap-2'>
              <input type="email"  placeholder='Email address' className='w-[292px] h-[48px] mx-auto py-2 px-3 outline-none border-[1px] border-white text-white text-sm font-normal placeholder-white bg-white/[0.44] rounded-[5px]' value={email} onChange={handleChange}/>
              {isError && <p className='text-center text-sm login__error-msg'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>}
              {err && <p className='text-center text-sm login__error-msg'>{err}</p>}
              <button type='submit' className='w-[292px] h-[48px] mx-auto border-[1px] border-[#00555A] bg-[#00555A] rounded-[5px] text-white text-sm font-normal transition ease-in-out delay-150 hover:scale-[1.02] hover:bg-white hover:border-white hover:text-[#00555A] duration-300 ' onClick={handleForgotPassword} disabled={isLoading}>{isLoading ? <div className="hour__glass-wrapper"><Hourglass visible={true} height="25" width="25" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#FFFFFF', '#72a1ed']}/></div>  : "Reset Password"}</button>
           </div>
        </form>
        <Link to="/login" className='font-normal text-sm text-white my-12 transition ease-in-out delay-150 hover:scale-[1.02] hover:text-[#00555A] duration-300'>Sign In</Link>
    </div>
  )
  }
export default ForgotPassword