import React, { FC } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';


type Props = {
    isFetching: boolean | any
    hasPrevPage: boolean | any
    hasNextPage: boolean | any
    handlePrevPagination: () => void
    handleNextPagination: () => void
    page: number | any
    totalPages: number | any
}

const PaginationButton:FC<Props> = ({isFetching,hasPrevPage,hasNextPage, handlePrevPagination, handleNextPagination, page, totalPages}) => {
  return (
    <div className="flex items-center justify-center font-Poppins font-normal text-sm gap-2 py-2">
        <button disabled={isFetching || !hasPrevPage} onClick={handlePrevPagination}  className={`w-[30px] h-[30px] rounded-full flex justify-center items-center  ${hasPrevPage ? "bg-findchow-success-500 text-white" : "bg-findchow-success-500/[11%] text-findchow-success-500/20"}`}><MdChevronLeft size={20}/></button><button className="flex justify-center items-center text-findchow-success-500 font-medium text-sm">{page}/{totalPages}</button><button disabled={isFetching || !hasNextPage} onClick={handleNextPagination} className={`w-[30px] h-[30px] rounded-full flex justify-center items-center ${hasNextPage ? "bg-findchow-success-500 text-white" : "bg-findchow-success-500/[11%] text-findchow-success-500/20"}`}><MdChevronRight size={20}/></button>
    </div>
  )
}

export default PaginationButton