import React, {useState, useRef, useEffect} from 'react';
import { Hourglass } from  'react-loader-spinner'
import findchowLogo from "../../../assets/authpage/authpage-logo.svg";
import { useResetPasswordMutation } from '../../../services/AuthService';
import { handlNewError } from '../../../utils/handleNewError';
import { handleSuccess } from '../../../utils/handleSucess';
import { useNavigate } from 'react-router-dom';

let currentOtpIndex : number = 0;
type Data = {
    password: string,
    confirmPassword: string,
    user_type: string
}
const NewPassword = () => {
  const navigate = useNavigate()
  const [resetPassword, {isLoading, isSuccess, isError, error, data: newData}] = useResetPasswordMutation()
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [err, setErr] = useState("")
  const [activeOtpIndex, setActiveOtpIndex] = useState<number>(0);
  const [data, setData] = useState<Data>({password: "", confirmPassword: "", user_type: "admin"})
  const inputRef = useRef<HTMLInputElement>(null);
  const newOtp = otp.length === 6 && otp.join("");
  const errorMessage = handlNewError(isError,error, "data");
  const successMessage = handleSuccess(isSuccess, newData, "message")
  const handleOtpChange = ({target}: React.ChangeEvent<HTMLInputElement>) : void => {
       const {value} = target;
       const newOtp: string[] = [...otp]
       newOtp[currentOtpIndex] = value.substring(value.length - 1)
       setOtp(newOtp);
       if (!value) setActiveOtpIndex(currentOtpIndex - 1)
       else setActiveOtpIndex(currentOtpIndex + 1)
  }
  const handleOnKeyDown = ({key}: React.KeyboardEvent<HTMLInputElement>, index: number) : void => {
        currentOtpIndex = index
        if (key === "Backspace") setActiveOtpIndex(currentOtpIndex - 1)
  };
const handleChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
      const {value, name} = target;
      setData({...data, [name] : value});
      setErr("")
}
const {password, confirmPassword, user_type} = data;
const handleResetPassord = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  if(password !== confirmPassword){
      setErr("Password did not match")
  }else if(user_type && password && newOtp){
       await resetPassword({user_type, password, otp: newOtp})
  }else {
     setErr("Please Provide all details")
  }
}
 useEffect(() => {
      inputRef.current?.focus()
 }, [activeOtpIndex]);
 useEffect(() => {
  if(isSuccess && successMessage.length > 0){
     navigate("/auth/new_password_success", {state: {message: successMessage}})
     
  }
}, [isSuccess, successMessage, navigate]);
  return (
    <div className='flex flex-col items-center gap-2 h-[100%] w-[100%] min-w-[405px] max-w-[405px] m-auto min-h-[540px] max-h-[604px] border-[2px] border-solid border-white bg-white bg-opacity-40 rounded-[15px] shadow-auth-shadow p-4 font-Poppins'>
        <div className='w-[136.663px] h-[43px] my-3'><img src={findchowLogo} alt="logo" height={42} width={136.663}/></div>
        <p className='text-center text-lg text-[#FFE55F] mt-1'>Order management portal</p>
        <form className='flex flex-col items-center gap-6 mt-8' onSubmit={handleResetPassord}>
            <div className='flex flex-col items-center text-center gap-2'>
               <h2 className='font-medium text-white text-2xl'>Set Password</h2>
               <p className='w-[280px] leading-6 text-sm text-[#C9C9C9] font-normal'>Welcome! Set a password you can always log in with</p>
            </div>
           <div className='flex flex-col gap-2'>
               <div className='flex w-[292px] gap-1 mx-auto justify-center'>
                  {
                      otp.map((_,index) => {
                          return (
                              <React.Fragment key={index}>
                                  <input type="number" ref={index === activeOtpIndex ? inputRef : null} className='w-[2.82rem] h-[2.82rem] flex rounded border-[1px] bg-transparent outline-none text-center font-semibold text-xl text-white border-white focus:border-[#00555A] focus:text-[#00555A] transition spin-button-none' onChange={handleOtpChange} onKeyDown={(e) => handleOnKeyDown(e, index)} value={otp[index]}/>
                              </React.Fragment>
                          )
                      })
                  }
                </div>
                <input type="password" placeholder='Password' className='w-[292px] h-[48px] py-2 px-3 outline-none border-[1px] border-white text-white text-sm font-normal placeholder-white bg-white/[0.44] rounded-[5px] focus:border-[#00555A] focus:text-[#00555A] focus:bg-transparent transition' name="password" value={password} onChange={handleChange}/>
                <input type="password" placeholder='Confirm Password' className='w-[292px] h-[48px] py-2 px-3 outline-none border-[1px] border-white text-white text-sm font-normal placeholder-white bg-white/[0.44] rounded-[5px] focus:border-[#00555A] focus:text-[#00555A] focus:bg-transparent transition' name="confirmPassword" value={confirmPassword} onChange={handleChange}/>
                {isError && <p className='text-center text-sm login__error-msg w-[292px]'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p>}
              {err && <p className='w-[292px] text-center text-sm login__error-msg'>{err}</p>}
              <button type='submit' className='w-[292px] h-[48px] border-[1px] border-[#00555A] bg-[#00555A] rounded-[5px] text-white text-sm font-normal transition ease-in-out delay-150 hover:scale-[1.02] hover:bg-white hover:border-white hover:text-[#00555A] duration-300 '>{isLoading ? <div className="hour__glass-wrapper"><Hourglass visible={true} height="25" width="25" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#FFFFFF', '#72a1ed']}/></div> : "Set Password"}</button>
           </div>
        </form>
    </div>
  )
  }
export default NewPassword