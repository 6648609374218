import React from 'react';
import { Link } from 'react-router-dom';
import * as BsIcons from "react-icons/bs";
import food1 from "../../assets/dashboard/food1.svg"
import food2 from "../../assets/dashboard/food2.svg"
import food3 from "../../assets/dashboard/food3.svg";
import { useGetOrdersByStatusQuery } from '../../services/OrderService';
import { handlNewError } from '../../utils/handleNewError';
import { Hourglass } from 'react-loader-spinner';


const DashboardPendingOrder = () => {
    const {isLoading, isError, error, data} = useGetOrdersByStatusQuery({page: 1, limit : 4, status: "pending"});
    const results = data && data.results && data.results.docs
    const errorMessage = handlNewError(isError,error, "data");
  return (
    <div className='h-[499px] bg-white rounded-[5px] p-4 pt-0 flex flex-col gap-4 xl:g-6  findchow__overflow-y'>
           {
                    isLoading ?  <div className="hour__glass-wrapper flex w-[100%] h-[100%] mx-auto justify-center items-center"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isError ? <p className='text-center login__error-msg flex items-center justify-center text-lg font-semibold'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p> :
                    <>
                        <div className='flex items-center justify-between sticky top-0 bg-white pt-4'>
                            <h3 className='text-base text-[#282828]'>Pending Orders</h3>
                            <Link to="/manage-orders/pending" className='text-xs text-[#002FD7] underline font-normal'>View all</Link>
                        </div>
                        {
                            results && results.length > 0 ? <>
                                <div>
                                    <div className='flex justify-between items-center py-5 border-b-[2px] border-[#F4F4F4] font-Poppins font-medium leading-normal not-italic'>
                                        <div className='flex items-center gap-2'>
                                            <div className='border-1 border-gray-100 rounded-full w-[42px] h-[42px] xl:w-[62px] xl:h-[62px]'><img src={food1} alt="/" className='rounded-full w-[100%] h-[100%]'/></div>
                                            <div className='flex flex-col gap-1'>
                                            <h3 className='text-black text-sm'>Jollof Rice Com...</h3>
                                            <div className='text-xs text-[#575757]'>Dammy’s Kitchen</div>
                                            <div className='text-xs text-[#E32021] font-normal'>#0010235</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <h3 className='text-black text-sm'>Anthony Smart</h3>
                                            <div className='text-xs text-[#575757]'>Carfin Street</div>
                                            <div className='text-xs text-[#575757]'>ML1 4ET</div>
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <div className='flex flex-col justify-between gap-3'>
                                                <div className='text-[#D0A200] p-[3px_12px] xl:p-[3px_15px] bg-[#FFF1BE] rounded-[300px] tracking-[0.25px] font-semibold text-[9px] xl:text-[10px]'>PENDING</div>
                                                <div className='flex items-center gap-2 text-sm'><span className='text-[#282828] font-medium tracking-[0.25px] '>£21.24</span><span className='text-[#575757] font-normal'>x3</span></div>
                                            </div>
                                            <div className='cursor-pointer'><BsIcons.BsThreeDotsVertical size={24}/></div>
                                        </div>
                                    </div>
                                    <div className='flex justify-between items-center py-5 border-b-[2px] border-[#F4F4F4] font-Poppins font-medium leading-normal not-italic'>
                                        <div className='flex items-center gap-2'>
                                            <div className='border-1 border-gray-100 rounded-full w-[42px] h-[42px] xl:w-[62px] xl:h-[62px]'><img src={food2} alt="/" className='rounded-full w-[100%] h-[100%]'/></div>
                                            <div className='flex flex-col gap-1'>
                                            <h3 className='text-black text-sm'>Steak Burger an...</h3>
                                            <div className='text-xs text-[#575757]'>Hugs & Fingers</div>
                                            <div className='text-xs text-[#E32021] font-normal'>#00110293</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <h3 className='text-black text-sm'>Anthony Smart</h3>
                                            <div className='text-xs text-[#575757]'>Carfin Street</div>
                                            <div className='text-xs text-[#575757]'>ML1 4ET</div>
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <div className='flex flex-col justify-between gap-3'>
                                                <div className='text-[#D0A200] p-[3px_12px] xl:p-[3px_15px] bg-[#FFF1BE] rounded-[300px] tracking-[0.25px] font-semibold text-[9px] xl:text-[10px]'>PENDING</div>
                                                <div className='flex items-center gap-2 text-sm'><span className='text-[#282828] font-medium tracking-[0.25px] '>£21.24</span><span className='text-[#575757] font-normal'>x3</span></div>
                                            </div>
                                            <div className='cursor-pointer'><BsIcons.BsThreeDotsVertical size={24}/></div>
                                        </div>
                                    </div>
                                    <div className='flex justify-between items-center py-5 border-b-[2px] border-[#F4F4F4] font-Poppins font-medium leading-normal not-italic'>
                                        <div className='flex items-center gap-2'>
                                            <div className='border-1 border-gray-100 rounded-full  w-[42px] h-[42px] xl:w-[62px] xl:h-[62px]'><img src={food3} alt="/" className='rounded-full w-[100%] h-[100%]'/></div>
                                            <div className='flex flex-col gap-1'>
                                            <h3 className='text-black text-sm'>Jollof Rice Com...</h3>
                                            <div className='text-xs text-[#575757]'>Dammy’s Kitchen</div>
                                            <div className='text-xs text-[#E32021] font-normal'>#0010235</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <h3 className='text-black text-sm'>Anthony Smart</h3>
                                            <div className='text-xs text-[#575757]'>Carfin Street</div>
                                            <div className='text-xs text-[#575757]'>ML1 4ET</div>
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <div className='flex flex-col justify-between gap-3'>
                                                <div className='text-[#D0A200] p-[3px_12px] xl:p-[3px_15px] bg-[#FFF1BE] rounded-[300px] tracking-[0.25px] font-semibold text-[9px] xl:text-[10px]'>PENDING</div>
                                                <div className='flex items-center gap-2 text-sm'><span className='text-[#282828] font-medium tracking-[0.25px] '>£21.24</span><span className='text-[#575757] font-normal'>x3</span></div>
                                            </div>
                                            <div className='cursor-pointer'><BsIcons.BsThreeDotsVertical size={24}/></div>
                                        </div>
                                    </div>
                                    <div className='flex justify-between items-center pt-5 border-[#F4F4F4] font-Poppins font-medium leading-normal not-italic'>
                                        <div className='flex items-center gap-2'>
                                            <div className='border-1 border-gray-100 rounded-full w-[42px] h-[42px] xl:w-[62px] xl:h-[62px]'><img src={food1} alt="/" className='rounded-full w-[100%] h-[100%]'/></div>
                                            <div className='flex flex-col gap-1'>
                                            <h3 className='text-black text-sm'>Jollof Rice Com...</h3>
                                            <div className='text-xs text-[#575757]'>Dammy’s Kitchen</div>
                                            <div className='text-xs text-[#E32021] font-normal'>#0010235</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <h3 className='text-black text-sm'>Anthony Smart</h3>
                                            <div className='text-xs text-[#575757]'>Carfin Street</div>
                                            <div className='text-xs text-[#575757]'>ML1 4ET</div>
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <div className='flex flex-col justify-between gap-3'>
                                                <div className='text-[#D0A200] p-[3px_15px] xl:p-[3px_15px] bg-[#FFF1BE] rounded-[300px] tracking-[0.25px] font-semibold text-[9px] xl:text-[10px]'>PENDING</div>
                                                <div className='flex items-center gap-2 text-sm'><span className='text-[#282828] font-medium tracking-[0.25px] '>£21.24</span><span className='text-[#575757] font-normal'>x3</span></div>
                                            </div>
                                            <div className='cursor-pointer'><BsIcons.BsThreeDotsVertical size={24}/></div>
                                        </div>
                                    </div>
                                </div>
                            </> : <div className='text-center flex justify-center items-center w-full h-full'>No order yet!</div>
                        }
                    </>
            } 
    </div>
  )
}

export default DashboardPendingOrder