import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { Page } from "../models/Pages.model";
import { RootState } from "../store";
import { getValue } from "./DecryptData";
import { RecentOrders } from "../models/Order.model";
import { DashboardEarning, TopSelling } from "../models/Dashboard.model";
import { getUrl } from "../utils/getUrl";
const values =  getValue("findchowApp");
export const dashboardService = createApi({
    reducerPath: "dashboardService",
    baseQuery: fetchBaseQuery({baseUrl: getUrl(process.env.REACT_APP_ENV), prepareHeaders: (headers, { getState } ) => {
        const state = getState() as RootState;
        const token = state?.auth?.token || values.token
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }
}),
    tagTypes: ["DashboardService"],
    endpoints: (builder) => ({
        adminLatestOrder: builder.query<RecentOrders, Page>({
            query: ({page,limit}) => ({
                url: `/api/order/admin/latest?page=${page}&limit=${limit}`,
                method: "get",   
            }),
            providesTags: ["DashboardService"]
        }),
        adminMonthlyEarning: builder.query<DashboardEarning, any | void>({
            query: () => ({
                url: `/api/order/dashboard/monthly/earnings`,
                method: "get",   
            }),
            providesTags: ["DashboardService"]
        }),
        adminTopSelling: builder.query<TopSelling, any | void>({
            query: () => ({
                url: `/api/order/top_selling`,
                method: "get",   
            }),
            providesTags: ["DashboardService"]
        })
    })
})

export const {useAdminLatestOrderQuery, useAdminMonthlyEarningQuery, useAdminTopSellingQuery} = dashboardService;