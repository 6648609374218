import React from 'react'
import CustomerDetails from '../../ManageOrder/OrderDetails/CustomerDetails';
import DeliveryDetails from './DeliveryDetails';
import { useParams } from 'react-router-dom';

const AccountDeliveryDetails = () => {
  const {id} = useParams()
  // const {isError, error, data, isFetching} = useGetOrderQuery(id || "");
  return (
    
    <div className='col-span-3 h-auto flex flex-col gap-4 text-base font-Poppins'>
         <div className='text-[#282828]  max-h-[200px] bg-white rounded-[5px] findchow__overflow-y py-4 '>
             <DeliveryDetails />
         </div>   
        <div className='bg-white col-span-3 shadow-findchow-card-shadow h-[448px] border-[1px] border-[#F5F5F5] rounded-[5px] flex gap-2  font-Poppins'>
            <CustomerDetails />
        </div>
    </div>
  )
}


export default AccountDeliveryDetails;