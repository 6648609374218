import { configureStore } from "@reduxjs/toolkit";
import { merchantTypeApi } from "./services/MerchanTypeApi";
import authReducer from "./services/authReducer";
import { authService } from "./services/AuthService";
import { roleService } from "./services/roleService";
import { orderService } from "./services/OrderService";
import { dashboardService } from "./services/DashboardService";
import { waitlistApi } from "./services/Waitlist";




export const store = configureStore({
     reducer: {
         [merchantTypeApi.reducerPath] : merchantTypeApi.reducer,
         [authService.reducerPath] : authService.reducer,
         [roleService.reducerPath] : roleService.reducer,
         [orderService.reducerPath] : orderService.reducer,
         [dashboardService.reducerPath]: dashboardService.reducer,
         [waitlistApi.reducerPath]: waitlistApi.reducer,
         auth: authReducer
     },
     middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(merchantTypeApi.middleware, authService.middleware, roleService.middleware, orderService.middleware, dashboardService.middleware, waitlistApi.middleware)
})

export type RootState = ReturnType<typeof store.getState> 
export type AppDispatch = typeof store.dispatch