import CryptoJS from "crypto-js";


export const getValue = (key : string, defaultValue = {}) => {
    try {
      // read value from local storage
      const item = localStorage.getItem(key);
      let decrypt = item && CryptoJS.AES.decrypt(item, process.env.REACT_APP_SECRETKEY);
      let decryptedValue = decrypt && decrypt.toString(CryptoJS.enc.Utf8);
        return item && decryptedValue ?  JSON.parse(decryptedValue) : defaultValue;

    } catch (error) {
      return defaultValue;
    }
  }
  
  export const setValue = (key : string, value: any) => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.log(error);
    }
  }
  
