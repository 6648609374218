import React from 'react'
import { FaCaretDown } from 'react-icons/fa'

const MerchantMenu = () => {
  return (
    <div className='min-h-[200px] bg-white border-[#F5F5F5] shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] rounded-[5px] w-[100%] max-w-[1142px]'> 
        <div className='grid grid-cols-12 divide-x-[1px] divide-[#F1F1F1] h-full min-h-[200px] font-Poppins'>
            <nav className="col-span-3 flex flex-col">
                 <header className='py-6 px-4 text-[#262641] text-sm font-medium flex items-center gap-2'>
                     Categories <span className='text-xs text-[#9797AA]'>9</span>
                 </header>
                 <section></section>
            </nav>
            <section className='col-span-9 p-4'>
                <header className='flex items-center justify-between  font-medium'>
                    <div className='text-sm text-[#262641] flex gap-2 items-center'>Products <span className='text-xs text-[#9797AA]'>18</span></div>
                    <button className='w-[136px] h-[36px] rounded-[18px] border-[1px] border-[#575757] border-solid flex items-center justify-center gap-2 text-sm !font-normal text-[#575757]'>
                         Bulk Action  <FaCaretDown size={13}/>
                    </button>
                </header>

            </section>
        </div>
     </div>
  )
}

export default MerchantMenu