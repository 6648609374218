import React, {useState} from 'react';
import Chart from 'react-apexcharts';
import {ApexOptions} from "apexcharts"
interface Props {
    height: number,
    color: string,
    series: number,
    size: string,
    show: boolean
}
const RadialChart = ({height, color, series, size, show}: Props) => {
    const progressOptions: ApexOptions =  {
        chart: {
          type: 'radialBar',
        },
        stroke: {
            lineCap: "round",
            
        },
        fill: {
            colors: [color],
            type: ["solid"]
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: size,
            },
            dataLabels: {
             
                name: {
                  show: false,
                
                },
                value: {
                  show: show,
                  fontSize: "10px",
                  offsetY: 5
                }
              },
              
          },
         
        }
      }
    const [progressChart, setProgressChart] = useState(
        {
          
            series: [series],
            options: progressOptions,
            
          
          }
        
    )
  return (
      <div>
         <Chart  options={progressChart.options} series={progressChart.series} height={height} type="radialBar"/>
      </div>
  )
  
}

export default RadialChart