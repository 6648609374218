import React, {useEffect, useState} from 'react';
import {useLocation, Outlet,Link, useParams, useSearchParams} from "react-router-dom";
import SearchInput from '../../../components/Shared/SearchInput';
import { useGetMerchantOrdersQuery, useGetMerchantOrdersByStatusQuery } from '../../../services/OrderService';
import { handlNewError } from '../../../utils/handleNewError';
import { useMerchantsSearchQuery } from '../../../services/MerchanTypeApi';


const MerchantOrders = () => {
    const [status, setStatus] = useState({status: ["All","Delivered", "Pending", "Cancelled"]});
    const [activeStatus, setActiveStatus] = useState(0);
    const [search, setSearch] = useSearchParams();
    const {id} = useParams()
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const {isLoading, isError, isFetching, error, data} = useGetMerchantOrdersQuery({page, limit, restaurantId: id});
    const {data : deliveredData} = useGetMerchantOrdersByStatusQuery({page : 1, limit : 10, restaurantId: id, status: "delivered"});
    const {data : pendingData} = useGetMerchantOrdersByStatusQuery({page : 1, limit : 10, restaurantId: id, status: "pending"});
    const {data : cancelledData} = useGetMerchantOrdersByStatusQuery({page : 1, limit : 10, restaurantId: id, status: "cancelled"});

    const search_term = search && search.get("search_term");
    const {data: searchData} = useMerchantsSearchQuery(search_term !== null ? search_term : "");
    const merchantSearchData = searchData && searchData.results;


    console.log("data", data)

    const totalDocs = (data && data.results && data.results.totalDocs) || 10;
    const allOrdersCount = data && data.results && data.results.totalDocs
    const deliveredCount = deliveredData && deliveredData.results && deliveredData.results.totalDocs;
    const pendingCount = pendingData && pendingData.results && pendingData.results.totalDocs;
    const cancelledCount = cancelledData && cancelledData.results && cancelledData.results.totalDocs
    const isErr = handlNewError(isError, error, "data");
    const docs  = data?.results?.docs;
    const hasNextPage = data?.results?.hasNextPage;
    const hasPrevPage = data?.results?.hasPrevPage;
    const totalPages = data?.results?.totalPages
    const pageNumber = data?.results?.page || 1;
  
    const handleActiveStatus = (idx: number) => {
         setActiveStatus(idx);
    }

    useEffect(() => {
        if(search_term === null || search_term === ""){
            search.delete("search_term")
            setSearch({})
        }
     }, [search_term, search, setSearch]);
     useEffect(() => {
         if(search_term){
           setLimit(totalDocs)
         }else {
         
            setLimit(10);
             setPage(pageNumber)
         }
     }, [search_term,totalDocs, pageNumber])

  return (
    <div className='min-h-[200px] bg-white border-[#F5F5F5] shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] rounded-[5px] w-[100%] max-w-[1142px] p-4'>  
            <div className="flex justify-between items-center py-2">
                    <div className="flex items-center gap-6">
                        <span className="text-lg font-Poppins font-semibold text-findchow-success-500">Orders</span>
                    {
                                status && status.status.map((status,idx) => (
                                    <Link to={`${status === "All" ? "" : `${status.toLowerCase()}`}`} key={idx} className={`flex gap-1 items-center text-center cursor-pointer font-Poppins h-[24px] not-italic leading-normal font-normal ${idx === activeStatus ? "bg-[#005B611C]  px-2 rounded-[12px]": ""}`} onClick={() => {handleActiveStatus(idx)}}>
                                        <div className={`text-[12px] ${idx === activeStatus ? "text-[#005B61] font-semibold": "text-[#575757]"}`}>{status}</div><div className={`text-[8px] mt-[2px] ${idx === 0 ? "text-[#005B61]" : idx === 1 ? "text-[#27AE60]" : idx === 2 ? "text-[#C7A700]" : idx === 3 ? "text-[#C70C00]" : ""}`}>{idx === 0 ? allOrdersCount || 0 : idx === 1 ? deliveredCount || 0 : idx === 2 ? pendingCount || 0 : idx === 3 ? cancelledCount || 0 : null}</div>
                                    </Link>
                                ))
                            }
                    </div>
                    <div>
                        <SearchInput placeholder="Search orders, city, merchant, etc" search={search} setSearch={setSearch}/>
                    </div>
            </div>

            <section className=" mx-auto border-0 font-Poppins leading-normal not-italic max-w-[95%]">
                <Outlet context={{isLoading, hasNextPage, hasPrevPage, docs, isErr, setPage, isFetching, totalPages, isError, error, page, search, setSearch}}/>  
            </section>
    </div>
  )
}

export default MerchantOrders