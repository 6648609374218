import React, {FC} from 'react';
import restaurantImg from "../../../assets/dashboard/rest-img.png"
type Prop = {
    items: any[]
}
const Items:FC<Prop> = ({items}) => {  
  
  return (
    <div className='py-3 border-t-[1.5px] border-[#F5F5F5]  divide-y'>
        {items?.map(({_id, foodPrice,quantity, totalCost, foodId, name}) => (
               <div className='flex justify-between items-center px-4 py-2' key={_id}>
               <div className='w-[60%] text-base text-black font-medium flex items-center gap-6'>
                   <img src={foodId.image_url || restaurantImg} alt="" className='w-[75px] h-[75px] rounded-full' loading='lazy'/>
                   <div className='max-w-[80%]'>
                       <span className='text-xs text-[#E32021] font-normal'>BEST DEALS</span>
                       <p className='w-[100%] truncate text-base text-black leading-[22px] font-medium' title="Jollof Rice Combo w/Turkey & Stew">{name}</p>
                   </div>
               </div>
               <div className='w-[40%] flex justify-between items-center text-sm text-[#282828] font-medium leading-normal'>
                   <span className='w-[33%] text-center'>{quantity}</span>
                   <span className='w-[33%] text-center'>£{foodPrice}</span>
                   <span className='w-[33%] text-center'>£{totalCost}</span>
               </div>
           </div> 
        ))}
      
    </div>
  )
}

export default Items