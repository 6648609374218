import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom"
import SearchInput from '../../Shared/SearchInput';
import { useMerchantTransactionsQuery } from '../../../services/MerchanTypeApi';
import { handlNewError } from '../../../utils/handleNewError';
import { Hourglass } from 'react-loader-spinner';
import moment from 'moment';
import handlePagination from '../../Shared/Pagination';
import PaginationButton from '../../Shared/PaginationButton';
const Transactions = () => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const {id} = useParams();
    const {isError, error, data, isFetching, isLoading} = useMerchantTransactionsQuery({page,limit, merchant_id: id});
    const isErr = handlNewError(isError, error, "data");
    const docs  = data?.results?.docs;
    const hasNextPage = data?.results?.hasNextPage;
    const hasPrevPage = data?.results?.hasPrevPage;
    // const totalDocs = data?.results?.totalDocs;
    const totalPages = data?.results?.totalPages;



    console.log(error, isError)


  return (
    <div className='font-Poppins'>
    <div className='flex justify-between items-center py-2'>
        <div className='flex items-center gap-10 font-medium'>
            <div className='text-sm text-[#282828]'>Remittance History</div>
            <Link to='/account' className='text-[#005B61] text-[10px] underline underline-offset-1'>View all</Link>
        </div>
        <div>
            <SearchInput placeholder='Search transaction'/>
        </div>
    </div>
    <section className="max-w-[95%] mx-auto border-0 font-Poppins leading-normal not-italic">
   
        <div className="flex flex-col border-0 ">
            {
             isLoading ?  <div className="hour__glass-wrapper"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isError ? <p className="text-[#D90000] py-4 text-center text-base">{error?.hasOwnProperty("data") ? JSON.parse(isErr)["message"] : JSON.parse(isErr)}</p> : <>
                    {
                        docs && docs.length > 0 ? 
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 border-0">
                            <div className="inline-block min-w-full py-2 align-middle ">
                                <div className="overflow-hidden border-0 ">
                                    <table className="min-w-full  divide-y divide-gray-200 ">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                                Transaction # 
                                                </th>
                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                                Date
                                                </th>
                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                                    Store
                                                </th>
                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                                    Category
                                                </th>
                                                <th scope="col" className="px-4 py-3.5 text-xs font-normal text-left rtl:text-right text-[#9A9A9A]  whitespace-nowrap">
                                                    Status
                                                </th>
                                            
                                            </tr>
                                        </thead>
                                        <tbody className=" divide-y divide-gray-200  ">
                                            {
                                                docs && docs.map(({_id, createdAt, remittance_status,merchant: {restuarant_name}}) => (
                                                    <tr key={_id}>
                                                        <td className="px-4 py-4 whitespace-nowrap" title={_id}>
                                                            <Link to={`/account/${_id}`} className="underline text-[#0038FD] text-[10px] font-normal cursor-pointer">
                                                                {_id}
                                                            </Link>
                                                        </td>
                                                    <td className="px-4 py-4 text-[10px] font-normal text-[#282828] whitespace-nowrap truncate min-w-[70px] max-w-[100px]" title={`${moment(createdAt).format("DD MMM")}, ${moment(createdAt).format("HH:mm")}`}>
                                                            <span>{moment(createdAt).format("DD MMM")}, <span className='text-[#9C9C9C]'>{moment(createdAt).format("HH:mm")}</span></span>
                                                        </td>
                                                       
                                                      
                                                        <td className="px-4 py-4 text-[10px] font-normal  text-[#282828] whitespace-nowrap w-[100px] max-w-[120px] truncate">{restuarant_name}</td>
                                                        <td className="px-4 py-4 text-[10px] whitespace-nowrap font-normal text-[#282828]">
                                                            Food Bowls
                                                        </td>
                                                        <td className={`px-4 py-4 text-[9px] whitespace-nowrap font-normal ${remittance_status === "closed" ? "text-[#E32021]" : "text-findchow-success-200"}`}>
                                                            {remittance_status}
                                                        </td>
                                                    </tr>
                                                    ))
                                            }
                                    
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {
                                    totalPages && totalPages === 1 ? null : <PaginationButton isFetching={isFetching} hasPrevPage={hasPrevPage} hasNextPage={hasNextPage} handlePrevPagination={() => handlePagination("prevPage",hasNextPage,hasPrevPage,setPage, page)} handleNextPagination={() => handlePagination("nextPage",hasNextPage,hasPrevPage,setPage, page)} page={page} totalPages={totalPages}/>
                            }
                        </div> : <p className='text-center text-lg py-4'>No transaction yet</p>
                     }
              </>  
            }
        </div>

    </section>
</div>
  )
}

export default Transactions