import NewPassword from "../../components/Auth/Login/NewPassword"

const NewPasswordPage = () => {
    return (
      <div className="w-screen h-screen overflow-hidden grid place-content-center auth-bg">
           <NewPassword />
      </div>
    )
  }
  
  export default NewPasswordPage