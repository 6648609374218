import React from 'react';
import * as FaIcons from "react-icons/fa";
type InputProps = {
    sortShared: boolean;
    selectData: string;
    data: string[];
    itemIndex: number;
    handleSelected : (data: string, index: number) => void;
    handleSort: () => void
}
const InputFilter = ({sortShared, selectData, data, itemIndex, handleSelected, handleSort} : InputProps) => {
  return (
    <div className='flex justify-end relative'>
        <div className={`flex items-center justify-between cursor-pointer w-[106px] h-auto border border-b-[#C5C5C5] border-t-0 border-r-0 border-l-0 font-Poppins font-normal leading-normal not-italic text-[11px] text-[#C5C5C5] p-1`} onClick={handleSort}>
            <span>{selectData}</span><FaIcons.FaCaretDown size={16}/>
        </div>
        {
            sortShared && <div className={`fixed top-[150px] min-h-[50px] max-h-[400px] findchow__overflow-y p-2 w-[167px] bg-white shadow-lg rounded-lg flex flex-col gap-1 divide-y divide-solid z-20`}>
            {
                data && data.map((data, index) => (
                    <span className={`cursor-pointer py-1 ${itemIndex === index  ? "text-[#0038FD]" : ""}`} key={index} onClick={() => handleSelected(data, index)}>
                    {data}
                    </span>
                ))
            }
            
        </div>
        }
    </div>
  )
}

export default InputFilter