import React from 'react'
import findchowSuccessLogo from "../../../assets/authpage/checkbox-marked-circle-outline.svg";
import { Link } from 'react-router-dom';
const NewPasswordSuccess = () => {
  return (
    <div className='flex flex-col items-center justify-between h-[100%] w-[100%] min-w-[405px] max-w-[405px] m-auto min-h-[540px] max-h-[604px] border-[2px] border-solid border-white bg-white bg-opacity-40 rounded-[15px] shadow-auth-shadow px-4 py-[4rem] font-Poppins'>
            <img src={findchowSuccessLogo} alt="" height={149} width={149}/>
            <div className='flex flex-col items-center gap-2 text-center'>
                <h2 className='text-white text-2xl font-medium'>Successful</h2>
                <p className='w-[275px] text-[#C9C9C9] text-sm font-normal'>
                    You have now securely setup a password to get into your account, you can now freely sign in to the dashboard.
                </p>
            </div>
            <Link to="/login" className='w-[292px] h-[48px] flex items-center justify-center border-[1px] border-[#00555A] bg-[#00555A] rounded-[5px] text-white font-normal text-sm transition ease-in-out delay-150 hover:scale-[1.02] hover:bg-white hover:border-white hover:text-[#00555A] duration-300 '>Sign in</Link>
    </div>
  )
  }
export default NewPasswordSuccess