import React from 'react'
import AllWaitList from '../../components/WaitList/AllWaitList'

const WaitList = () => {
  return (
    <div>
        <AllWaitList />
    </div>
  )
}

export default WaitList