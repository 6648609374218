import {useState} from 'react'
import SearchInput from '../Shared/SearchInput'
import { useSearchParams } from 'react-router-dom';
import exportImg from "../../assets/waitlist/clarity_export-line.svg"
import { useListWaitListQuery } from '../../services/Waitlist';
import { Hourglass } from 'react-loader-spinner';
import { handlNewError } from '../../utils/handleNewError';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { getNameFromEmail } from '../../utils/getNameFromEmail';
import PaginationButton from '../Shared/PaginationButton';
import handlePagination from '../Shared/Pagination';


type Prop = {
     email: string
     createdAt: string
     _id: string
}

const AllWaitList = () => {
  const [search, setSearch] = useSearchParams();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const {isError, error, isLoading, data, isFetching} = useListWaitListQuery({page, limit});
  const isErr = handlNewError(isError, error, "data");
  const newData = data && data.results && data.results.docs;
  const downloadDate = moment().format("DD/MM/YYYY LT")
  const hasNextPage = data?.results?.hasNextPage;
  const hasPrevPage = data?.results?.hasPrevPage;
  // const totalDocs = data?.results?.totalDocs;
  const totalPages = data?.results?.totalPages;

  const exportData = newData && newData.map((data : any) => {
       return  {
           name: getNameFromEmail(data.email),
           email: data.email
       }
  });

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },

  ];

  return (
    <div className='p-4 flex flex-col gap-4 min-h-[200px] bg-white border-[#F5F5F5] shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] rounded-[5px] relative'>
         <div className='relative flex justify-between items-center'>
              <div className='font-semibold font-Poppins text-lg text-[#282828]'>Waitlist</div>
              <div className='flex items-center justify-center space-x-12'>
                  <SearchInput placeholder='Search waitlist' setSearch={setSearch}/>
                  <CSVLink data={exportData || ""} headers={headers} filename={`findchow-waitlist-${downloadDate}.csv`}>
                        <button className='font-medium font-Poppins text-sm flex items-center justify-center gap-2 w-[188px] h-[40px] rounded-[5px] text-findchow-success-500 bg-findchow-success-500/[11%]'><img src={exportImg} sizes='16' alt=""/>Export as CSV</button>
                  </CSVLink>
              </div>
         </div>
         {
                isLoading ?  <div className="hour__glass-wrapper"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isError ? <p className="text-[#D90000] py-4 text-center text-base">{error?.hasOwnProperty("data") ? JSON.parse(isErr)["message"] : JSON.parse(isErr)}</p> :
                <div className="border border-[#E2E2E2] min-h-[150px] max-w-[1091px] rounded-[10px] overflow-hidden ">
                     {
                        newData && newData.length > 0 ? <div className="min-w-full  divide-[#F4F4F4] divide-x-[1px]">
                        <header className='sticky top-0 h-[43px] w-full bg-[#E2E2E2] rounded-t-[10px] grid grid-cols-12 z-10 divide-x-[1px] divide-[#F4F4F4]'>
                                <div className='col-span-6 px-4 flex justify-between items-center text-xs text-[#9A9A9A] font-Poppins font-normal'>
                                  <div>Email address</div>
                                  <div className='justify-self-start  w-[120px]'>
                                      Entry time
                                  </div>
                                </div>
                                <div className='col-span-6 px-4 flex justify-between items-center text-xs text-[#9A9A9A] font-Poppins font-normal'>
                                  <div>Email address</div>
                                  <div className='justify-self-start w-[130px]'>
                                      Entry time
                                  </div>
                                </div>
                          </header>
                          <div className='grid grid-cols-12 divide-y-[1px] divide-x-[1px] divide-[#F4F4F4] font-Poppins font-normal text-xs'>
                            {
                              newData && newData?.slice(0, newData.length/2).map(({_id,email, createdAt}: Prop) => (
                                      
                                <div className="col-span-6 flex justify-between items-center px-4 py-2" key={_id}>
                                  <div className='text-[#282828]'>{email}</div>
                                  <div className='justify-self-start w-[130px] text-[#9E9E9E]'>{`${moment(createdAt).format("DD/MM/YYYY")},  ${moment(createdAt).format("HH:MM:ss")}`}</div>
                                 </div>
                               
                              ))
                            }
                             {
                            
                              newData && newData?.slice(newData.length/2, newData.length).map(({_id,email, createdAt}: Prop) => {
                                return (<div className="col-span-6 flex justify-between items-center px-4 py-2" key={_id}>
                                  <>
                                    <div className='text-[#282828]'>{email}</div>
                                    <div className='justify-self-start w-[130px] text-[#9E9E9E]'>{`${moment(createdAt).format("DD/MM/YYYY")},  ${moment(createdAt).format("HH:MM:ss")}`}</div>
                                </>
                                
                                 </div>)
                        
                                })
                             }
                                <div className='col-span-12'>
                                </div>
                          </div>
                          {
                            totalPages && totalPages === 1 ? null :  
                            <PaginationButton isFetching={isFetching} hasPrevPage={hasPrevPage} hasNextPage={hasNextPage} handlePrevPagination={() => handlePagination("prevPage",hasNextPage,hasPrevPage,setPage, page)} handleNextPagination={() => handlePagination("nextPage",hasNextPage,hasPrevPage,setPage, page)} page={page} totalPages={totalPages}/>
                          }
                       
                      </div> : <p className='text-center text-lg py-4'>No Waitlist yet</p>
                     }
                      
                </div>
         }
    </div>
  )
}

export default AllWaitList