import {useState, useEffect} from 'react';
import * as TbIcons from "react-icons/tb"
import AllMerchants from './AllMerchants';
import SearchInput from '../Shared/SearchInput';
import { useAppDispatch } from '../../hooks';
import { useListMerchantsQuery } from '../../services/MerchanTypeApi';
import { handlNewError } from '../../utils/handleNewError';
import { Hourglass } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';
import { useMerchantsSearchQuery } from "../../services/MerchanTypeApi";


const MerchantList = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10)
  const {isLoading, isError, error, data,isFetching} = useListMerchantsQuery({page, limit});
  const [exportType, setExportType] = useState("Export as CSV");
  const [selectExportType, setSelectExportType] = useState(false);
  const errorMessage = handlNewError(isError,error, "data");
  const exportData = ["Export as CSV", "Export as PDF", "Export as Text"];
  const merchantData = data && data.results && data.results.docs;  
  const totalDocs = data?.results?.totalDocs || 10
  const pageNumber = data?.results?.page || 1

  const [search, setSearch] = useSearchParams();
  const search_term = search && search.get("search_term");
  const {data: searchData} = useMerchantsSearchQuery(search_term !== null ? search_term : "");
  const merchantSearchData = searchData && searchData.results

  const handleSelectedType = (exportData: string) => {
     setExportType(exportData);
     setSelectExportType(false)
  }
  const handleSelect = () => {
      setSelectExportType(prev => !prev)
  }
  useEffect(() => {
     if(search_term === null || search_term === ""){
         search.delete("search_term")
         setSearch({})
     }
  }, [search_term, search, setSearch]);
  useEffect(() => {
      if(search_term){
        setLimit(totalDocs)
      }else {
      
         setLimit(10);
          setPage(pageNumber)
      }
  }, [search_term,totalDocs, pageNumber])
  return (
    <div className='p-4 flex flex-col gap-4 min-h-[200px] bg-white border-[#F5F5F5] shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] rounded-[5px]'>
        <div className='relative'>
            <div className='flex justify-between items-center'>
                <div className='flex items-center gap-7 font-Poppins'>
                    <div className='font-medium text-base text-[#282828]'>Merchant List</div>
                    <div className='flex items-center gap-1 cursor-pointer text-[#A8A8A8] font-normal' onClick={handleSelect}><TbIcons.TbFileExport size={14}/>  <span className='text-[12px] underline'>{exportType}</span></div>
                </div>
               <SearchInput placeholder='Search merchant, id, name, etc' search={search} setSearch={setSearch}/>
            </div>
            {selectExportType && <div className='absolute top-[38px] min-h-[20px] p-2 w-[167px] bg-white shadow-2xl rounded-lg flex flex-col gap-1 divide-y divide-solid z-20'>
            {
                exportData && exportData.map((exportData, index) => (
                    <span className='cursor-pointer py-1' key={index} onClick={() => handleSelectedType(exportData)}>
                       {exportData}
                    </span>
                ))
            }
            
           </div>
           }
        </div>
        {
             isLoading ?  <div className="hour__glass-wrapper"><Hourglass visible={true} height="40" width="40" ariaLabel="hourglass-loading" wrapperStyle={{}} wrapperClass="" colors={['#005B61', '#000000']}/></div> : isError ? <p className='text-center login__error-msg flex items-center justify-center text-lg font-semibold'>{error?.hasOwnProperty("data") ? JSON.parse(errorMessage)["message"] : JSON.parse(errorMessage)}</p> : <AllMerchants merchantData={merchantData} setPage={setPage} setLimit={setLimit} data={data} merchantSearchData={merchantSearchData} search_term={search_term} isFetching={isFetching}/>
        }
    </div>
  )
}

export default MerchantList