import React, {useState} from 'react';
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import { useRoleListQuery } from '../../services/roleService';
import { useAdminListQuery } from '../../services/AuthService';

const AllRole = () => {
      const [page, setPage] = useState(1)
      const {data, isError, error, isLoading, isFetching} = useAdminListQuery({page, limit: 10});
  return (
    <div>
        <div className='flex flex-col gap-1 py-2 px-4 font-Poppins'>
            <div className='font-semibold text-base text-[#282828]'>Set roles</div>
            <p className='text-xs text-[#939393] font-normal'>Modify the access of users in your organisation</p>
        </div>
        <div className='flex flex-col mt-3'>
             <div className='grid grid-cols-12 items-center h-[36px] border-[1px] border-[#E2E2E2] bg-[#EEE] px-4 py-2'>
                 
                  <div className='text-sm text-[#282828] col-span-4 font-medium'>User</div>
                  <div className='text-sm text-[#282828] col-span-3 font-medium'>Email address</div>
                  <div className='text-sm text-[#282828] col-span-2 font-medium'>Position</div>
                  <div className='text-sm text-[#282828] col-span-2 font-medium'>Role</div>
                  <div className='text-sm text-[#282828] col-span-1 font-medium'></div>
             </div>
             <div className='grid grid-cols-12 items-center divide-y font-normal max-h-[400px] findchow__overflow-y'>
                  <div className='col-span-12 grid grid-cols-12 px-4 py-2 items-center'>
                    <div className='text-sm text-[#575757] col-span-4 flex items-center gap-2'><span className='w-[24px] h-[24px] p-[8px_4px] font-medium text-[11px] text-[#005B61] bg-[#CBFCFF] flex items-center justify-center rounded-full'>RA</span><span className='text-sm text-[#575757]'>Robin Alayeluwa</span></div>
                    <div className='text-sm text-[#575757] col-span-3'>r.alayeluwa@yahoo.com</div>
                    <div className='text-sm text-[#575757] col-span-2'>CFO</div>
                    <div className='text-sm text-[#575757] col-span-2'>
                          <select className='text-sm text-[#575757] font-medium p-[4px_2px_4px_9px] border-[1px] border-[#E2E2E2] rounded-[5px] outline-none cursor-pointer'>
                              <option>S.Admin</option>
                              <option>Advisor</option>
                              <option>CFO</option>
                         </select>
                    </div>
                    <div className='col-span-1 flex justify-end cursor-pointer'>
                          <FaIcons.FaRegTrashAlt className='text-[#E32021]' size={16}/>
                    </div>
                  </div>
                  <div className='col-span-12 grid grid-cols-12 px-4 py-2 items-center'>
                    <div className='text-sm text-[#575757] col-span-4 flex items-center gap-2'><span className='w-[24px] h-[24px] p-[8px_4px] font-medium text-[11px] text-[#005B61] bg-[#CBFCFF] flex items-center justify-center rounded-full'>RA</span><span>Robin Alayeluwa</span></div>
                    <div className='text-sm text-[#575757] col-span-3'>r.alayeluwa@yahoo.com</div>
                    <div className='text-sm text-[#575757] col-span-2'>Advisor</div>
                    <div className='text-sm text-[#575757] col-span-2'>
                          <select className='text-sm text-[#575757] font-medium p-[4px_2px_4px_9px] border-[1px] border-[#E2E2E2] rounded-[5px] outline-none cursor-pointer'>
                              <option>S.Admin</option>
                              <option>Advisor</option>
                              <option>CFO</option>
                         </select>
                    </div>
                    <div className='col-span-1 flex justify-end cursor-pointer'>
                          <FaIcons.FaRegTrashAlt className='text-[#E32021]' size={16}/>
                    </div>
                  </div>
                  <div className='col-span-12 grid grid-cols-12 px-4 py-2 items-center'>
                    <div className='text-sm text-[#575757] col-span-4 flex items-center gap-2'><span className='w-[24px] h-[24px] p-[8px_4px] font-medium text-[11px] text-[#005B61] bg-[#CBFCFF] flex items-center justify-center rounded-full'>RA</span><span>Robin Alayeluwa</span></div>
                    <div className='text-sm text-[#575757] col-span-3'>r.alayeluwa@yahoo.com</div>
                    <div className='text-sm text-[#575757] col-span-2'>Advisor</div>
                    <div className='text-sm text-[#575757] col-span-2'>
                          <select className='text-sm text-[#575757] font-medium p-[4px_2px_4px_9px] border-[1px] border-[#E2E2E2] rounded-[5px] outline-none cursor-pointer'>
                              <option>S.Admin</option>
                              <option>Advisor</option>
                              <option>CFO</option>
                         </select>
                    </div>
                    <div className='col-span-1 flex justify-end cursor-pointer'>
                          <FaIcons.FaRegTrashAlt className='text-[#E32021]' size={16}/>
                    </div>
                  </div>
                  <div className='col-span-12 grid grid-cols-12 px-4 py-2 items-center'>
                    <div className='text-sm text-[#575757] col-span-4 flex items-center gap-2'><span className='w-[24px] h-[24px] p-[8px_4px] font-medium text-[11px] text-[#005B61] bg-[#CBFCFF] flex items-center justify-center rounded-full'>RA</span><span>Robin Alayeluwa</span></div>
                    <div className='text-sm text-[#575757] col-span-3'>r.alayeluwa@yahoo.com</div>
                    <div className='text-sm text-[#575757] col-span-2'>Advisor</div>
                    <div className='text-sm text-[#575757] col-span-2'>
                          <select className='text-sm text-[#575757] font-medium p-[4px_2px_4px_9px] border-[1px] border-[#E2E2E2] rounded-[5px] outline-none cursor-pointer'>
                              <option>S.Admin</option>
                              <option>Advisor</option>
                              <option>CFO</option>
                         </select>
                    </div>
                    <div className='col-span-1 flex justify-end cursor-pointer'>
                          <FaIcons.FaRegTrashAlt className='text-[#E32021]' size={16}/>
                    </div>
                  </div>
                  <div className='col-span-12 grid grid-cols-12 px-4 py-2 items-center'>
                    <div className='text-sm text-[#575757] col-span-4 flex items-center gap-2'><span className='w-[24px] h-[24px] p-[8px_4px] font-medium text-[11px] text-[#005B61] bg-[#CBFCFF] flex items-center justify-center rounded-full'>RA</span><span>Robin Alayeluwa</span></div>
                    <div className='text-sm text-[#575757] col-span-3'>r.alayeluwa@yahoo.com</div>
                    <div className='text-sm text-[#575757] col-span-2'>Advisor</div>
                    <div className='text-sm text-[#575757] col-span-2'>
                          <select className='text-sm text-[#575757] font-medium p-[4px_2px_4px_9px] border-[1px] border-[#E2E2E2] rounded-[5px] outline-none cursor-pointer'>
                              <option>S.Admin</option>
                              <option>Advisor</option>
                              <option>CFO</option>
                         </select>
                    </div>
                    <div className='col-span-1 flex justify-end cursor-pointer'>
                          <FaIcons.FaRegTrashAlt className='text-[#E32021]' size={16}/>
                    </div>
                  </div>
                  <div className='col-span-12 grid grid-cols-12 px-4 py-2 items-center'>
                    <div className='text-sm text-[#575757] col-span-4 flex items-center gap-2'><span className='w-[24px] h-[24px] p-[8px_4px] font-medium text-[11px] text-[#005B61] bg-[#CBFCFF] flex items-center justify-center rounded-full'>RA</span><span>Robin Alayeluwa</span></div>
                    <div className='text-sm text-[#575757] col-span-3'>r.alayeluwa@yahoo.com</div>
                    <div className='text-sm text-[#575757] col-span-2'>Advisor</div>
                    <div className='text-sm text-[#575757] col-span-2'>
                          <select className='text-sm text-[#575757] font-medium p-[4px_2px_4px_9px] border-[1px] border-[#E2E2E2] rounded-[5px] outline-none cursor-pointer'>
                              <option>S.Admin</option>
                              <option>Advisor</option>
                              <option>CFO</option>
                         </select>
                    </div>
                    <div className='col-span-1 flex justify-end cursor-pointer'>
                          <FaIcons.FaRegTrashAlt className='text-[#E32021]' size={16}/>
                    </div>
                  </div>
                  <div className='col-span-12 grid grid-cols-12 px-4 py-2 items-center'>
                    <div className='text-sm text-[#575757] col-span-4 flex items-center gap-2'><span className='w-[24px] h-[24px] p-[8px_4px] font-medium text-[11px] text-[#005B61] bg-[#CBFCFF] flex items-center justify-center rounded-full'>RA</span><span>Robin Alayeluwa</span></div>
                    <div className='text-sm text-[#575757] col-span-3'>r.alayeluwa@yahoo.com</div>
                    <div className='text-sm text-[#575757] col-span-2'>Advisor</div>
                    <div className='text-sm text-[#575757] col-span-2'>
                         <select className='text-sm text-[#575757] font-medium p-[4px_2px_4px_9px] border-[1px] border-[#E2E2E2] rounded-[5px] outline-none cursor-pointer'>
                              <option>S.Admin</option>
                              <option>Advisor</option>
                              <option>CFO</option>
                         </select>
                    </div>
                    <div className='col-span-1 flex justify-end cursor-pointer'>
                          <FaIcons.FaRegTrashAlt className='text-[#E32021]' size={16}/>
                    </div>
                  </div>
                  <div className='col-span-12 grid grid-cols-12 px-4 py-2 items-center'>
                    <div className='text-sm text-[#575757] col-span-4 flex items-center gap-2'><span className='w-[24px] h-[24px] p-[8px_4px] font-medium text-[11px] text-[#005B61] bg-[#CBFCFF] flex items-center justify-center rounded-full'>RA</span><span>Robin Alayeluwa</span></div>
                    <div className='text-sm text-[#575757] col-span-3'>r.alayeluwa@yahoo.com</div>
                    <div className='text-sm text-[#575757] col-span-2'>Advisor</div>
                    <div className='text-sm text-[#575757] col-span-2'>
                          <select className='text-sm text-[#575757] font-medium p-[4px_2px_4px_9px] border-[1px] border-[#E2E2E2] rounded-[5px] outline-none cursor-pointer'>
                              <option>S.Admin</option>
                              <option>Advisor</option>
                              <option>CFO</option>
                         </select>
                    </div>
                    <div className='col-span-1 flex justify-end cursor-pointer'>
                          <FaIcons.FaRegTrashAlt className='text-[#E32021]' size={16}/>
                    </div>
                  </div>
                  <div className='col-span-12 grid grid-cols-12 px-4 py-2 items-center'>
                    <div className='text-sm text-[#575757] col-span-4 flex items-center gap-2'><span className='w-[24px] h-[24px] p-[8px_4px] font-medium text-[11px] text-[#005B61] bg-[#CBFCFF] flex items-center justify-center rounded-full'>RA</span><span>Robin Alayeluwa</span></div>
                    <div className='text-sm text-[#575757] col-span-3'>r.alayeluwa@yahoo.com</div>
                    <div className='text-sm text-[#575757] col-span-2'>Advisor</div>
                    <div className='text-sm text-[#575757] col-span-2'>
                          <select className='text-sm text-[#575757] font-medium p-[4px_2px_4px_9px] border-[1px] border-[#E2E2E2] rounded-[5px] outline-none cursor-pointer'>
                              <option>S.Admin</option>
                              <option>Advisor</option>
                              <option>CFO</option>
                         </select>
                    </div>
                    <div className='col-span-1 flex justify-end cursor-pointer'>
                          <FaIcons.FaRegTrashAlt className='text-[#E32021]' size={16}/>
                    </div>
                  </div>
                  <div className='col-span-12 grid grid-cols-12 px-4 py-2 items-center'>
                    <div className='text-sm text-[#575757] col-span-4 flex items-center gap-2'><span className='w-[24px] h-[24px] p-[8px_4px] font-medium text-[11px] text-[#005B61] bg-[#CBFCFF] flex items-center justify-center rounded-full'>RA</span><span>Robin Alayeluwa</span></div>
                    <div className='text-sm text-[#575757] col-span-3'>r.alayeluwa@yahoo.com</div>
                    <div className='text-sm text-[#575757] col-span-2'>Advisor</div>
                    <div className='text-sm text-[#575757] col-span-2'>
                          <select className='text-sm text-[#575757] font-medium p-[4px_2px_4px_9px] border-[1px] border-[#E2E2E2] rounded-[5px] outline-none cursor-pointer'>
                              <option>S.Admin</option>
                              <option>Advisor</option>
                              <option>CFO</option>
                         </select>
                    </div>
                    <div className='col-span-1 flex justify-end cursor-pointer'>
                          <FaIcons.FaRegTrashAlt className='text-[#E32021]' size={16}/>
                    </div>
                  </div>
             </div>
             <div className='flex items-center justify-center border-t-[1px] gap-3 border-t-[#E2E2E2]  p-4'>
                 <button className='bg-[#F5F5F5] w-[24px] h-[24px] p-[4px] rounded-full text-[#575757]'>
                       <BsIcons.BsChevronLeft size={16}/>
                 </button>
                 <span className='text-[#575757] text-sm font-normal tracking-[0.5px]'>1/2</span>
                 <button className='bg-[#F5F5F5] w-[24px] h-[24px] p-[4px] rounded-full text-[#575757]'>
                       <BsIcons.BsChevronRight size={16}/>
                 </button>
            </div>
        </div>
    </div>
  )
}

export default AllRole