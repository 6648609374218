import {useState} from 'react'
import Accordion from '../../Shared/Accordion'
import MerchantOrders from '../../../services/MerchantOrder'
const ConsumerOrderHistory = () => {
   const [open, setOpen] = useState(null);
   const toggle = (index: any) =>  {
        if(open === index){
          return setOpen(null)
        }

        setOpen(index)
   }
  return (
    <div className=' divide divide-y-2 divide-y-1 divide-[#F5F5F5]'>
        {
            MerchantOrders.map((data, index) => (
                <Accordion key={index + "" + data.time.toString()} image={data.image} date={data.date} name={data.name} open={index === open} toggle={() => toggle(index)} orders={data.orders} price={data.price} time={data.time}/>
            ))
        }
       
       
    </div>
  )
}

export default ConsumerOrderHistory