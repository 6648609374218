import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { GetOrder, ListOrders, OrderAnalytics, RecentOrders } from "../models/Order.model";
import { Page } from "../models/Pages.model";
import { RootState } from "../store";
import { getValue } from "./DecryptData";
import { getUrl } from "../utils/getUrl";
const values =  getValue("findchowApp");
export const orderService = createApi({
    reducerPath: "orderService",
    baseQuery: fetchBaseQuery({baseUrl: getUrl(process.env.REACT_APP_ENV), prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState;
        const token = state?.auth?.token || values.token
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
    
        return headers
      }
}),
    tagTypes: ["OrderService"],
    endpoints: (builder) => ({
        orderList: builder.query<ListOrders, Page>({
            query: ({page,limit}) => ({
                url: `/api/order/all?page=${page}&limit=${limit}`,
                method: "get",   
            }),
            providesTags: ["OrderService"]
        }),
        getOrder: builder.query<GetOrder, string>({
            query: (id) => ({
                url: `/api/order/details?orderId=${id}`,
                method: "get",   
            }),
            providesTags: ["OrderService"]
        }),
        getOrdersByStatus: builder.query<ListOrders, Page>({
            query: ({page, limit, status}) => ({
                url: `/api/order/admin/order_by_status?page=${page}&limit=${limit}&status=${status}`,
                method: "get",   
            }),
            providesTags: ["OrderService"]
        }),
        getMerchantOrdersByStatus: builder.query<ListOrders, Page>({
            query: ({page, limit, status,restaurantId}) => ({
                url: `/api/order/list/status?page=${page}&limit=${limit}&restaurantId=${restaurantId}&status=${status}`,
                method: "get",   
            }),
            providesTags: ["OrderService"]
        }),
        orderAnalytics: builder.query<OrderAnalytics, any>({
            query: ({date_filter}) => ({
                url: `/api/order/admin/overview?date_filter=${date_filter}`,
                method: "get"
            }),
            providesTags: ["OrderService"] 
        }),
        recentOrders: builder.query<RecentOrders, Page>({
            query: ({page,limit,restaurantId}) => ({
                url: `/api/order/new/list?page=${page}&limit=${limit}&restaurantId=${restaurantId}`,
                method: "get"
            }),
            providesTags: ["OrderService"] 
        }),
        getMerchantOrders: builder.query<ListOrders, Page>({
            query: ({page, limit, restaurantId}) => ({
                url: `/api/order/restaurant/orders?page=${page}&limit=${limit}&restaurantId=${restaurantId}`,
                method: "get",   
            }),
            providesTags: ["OrderService"]
        }),
    })
})

export const {useOrderListQuery, useGetOrderQuery, useOrderAnalyticsQuery, useGetOrdersByStatusQuery, useRecentOrdersQuery, useGetMerchantOrdersQuery, useGetMerchantOrdersByStatusQuery} = orderService;