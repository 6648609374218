import React, {useState, FC} from 'react'
import Transactions from './Transactions'
import ProcessedTransactions from './ProcessedTransactions'
import TotalEarning from './TotalEarning'
import { Link, useOutletContext } from 'react-router-dom'
import MerchantOrderHistory from './MerchantOrderHistory'
import InputFilter from '../../Shared/InputFilter'
import Chart from './Chart'
import { merchantDataInfo } from '../../../models/MerchantType.model'

const MerchantTransactionsDetails= () => {
    const {data} = useOutletContext<merchantDataInfo> ()
    const [sortShared, setSortShared] = useState(false);
    const [selectData,setSelectData] = useState("This Month");
    const [itemIndex, setItemIndex] = useState(0)
    const dataMonth = ["This month", "7days ago", "Last month", "3 months ago", "6 months ago", "Last year", "This year"];
    const processedTransactions = data?.results?.totalProcessedTransactons;
    const totalTransactions = data?.results?.totalTransactions
    const salesOverview = data?.results?.sales_overview
  
    const handleSelected = (data: string, index: number) => {
        setItemIndex(index)
        setSortShared(false);
        setSelectData(data)
    }
    const handleSort = () => {
        setSortShared(prev => !prev)
    }
  return (
    <div className='grid grid-cols-11 gap-4'>
         <div className='flex flex-col col-span-8 gap-4'>
            <div className='grid grid-cols-9 gap-4'>
                <div className='col-span-3 flex flex-col gap-4'>
                    <div className='h-[125px] max-h-[125px] findchow__overflow-y bg-white shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] border border-1 border-white rounded-[5px] p-4'>
                        <ProcessedTransactions processedTransactions={processedTransactions}/>
                    </div>
                    <div className='h-[125px] max-h-[125px] findchow__overflow-y bg-white shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] border border-1 border-white rounded-[5px] p-4'>
                        <TotalEarning totalTransactions={totalTransactions}/>
                    </div>
                </div>
                <div className='flex flex-col gap-2 col-span-6 max-h-[266px] min-h-[266px] findchow__overflow-y bg-white shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] border border-1 border-white rounded-[5px]  font-Poppins'>
                      <div className='flex items-center justify-between px-4 pt-4 sticky top-0 bg-white z-20'>
                            <div className='text-sm text-[#282828] font-medium'>Sales Overview</div>
                           <InputFilter sortShared={sortShared} selectData={selectData} itemIndex={itemIndex} data={dataMonth} handleSelected={handleSelected} handleSort={handleSort}/>
                      </div>
                      <div className='flex max-h-[calc(262px-32px)] gap-0 w-[100%]  px-4 items-center'>
    
                         <div className='w-[100%] merchant__chart-bar'>
                              <Chart salesOverview={salesOverview}/>
                         </div>
                      </div>
                </div>
            </div>
            <div className='bg-white shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] border border-1 border-white rounded-[5px] p-4'>
               <Transactions />
            </div>
         </div>
         <div className='col-span-3 h-auto max-h-[450px] bg-white shadow-[0px_0px_10px_0px_rgba(0, 0, 0, 0.05)] border border-1 border-white rounded-[5px] font-Poppins'>
            <div className='sticky top-0 bg-white flex justify-between items-center p-4 z-10'>
                <div className='font-Poppins text-[#282828] text-sm font-medium'>Recent Orders</div>
                <Link to="/" className='text-[10px] text-findchow-success-500 font-medium underline underline-offset-1'>View all</Link>
            </div>
            <div className='h-auto max-h-[400px]  findchow__overflow-y px-4 pb-3'>
                <MerchantOrderHistory />
            </div>
         </div>
    </div>
  )
}

export default MerchantTransactionsDetails